import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Icon, Input, Table } from "semantic-ui-react";
import ProductService from "services/Product";
import Datetime from "react-datetime";
import store from "store";

const defaultOptions = {
  DISCOUNT: { id: "discount", name: "Discount" },
  PENALTY: { id: "penalty", name: "Penalty" },
};

function RevenueOpportunityInvoices({
  revenueOpportunity,
  formData,
  updateFormData,
  readOnly,
}) {
  const { timeZone } = store.get("userAuth");
  const [invoices, setInvoices] = useState(revenueOpportunity?.invoices || []);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    prepareProductOptions();
    fixDefaultProductIds();
  }, []);

  const fixDefaultProductIds = () => {
    const newInvoices = invoices.map(invoice => {
      if (invoice.is_discount) {
        invoice.product_id = defaultOptions.DISCOUNT.id;
      } else if (invoice.is_penalty) {
        invoice.product_id = defaultOptions.PENALTY.id;
      }
      return invoice;
    });

    setInvoices(newInvoices);
  };

  const prepareProductOptions = async () => {
    const products = await ProductService.getForFilters();

    const options = [...Object.values(defaultOptions), ...products].map(
      (option, index) => ({
        id: index,
        text: option.name,
        value: option.id,
      })
    );

    setProductOptions(options);
  };

  const updateInvoices = invoices => {
    setInvoices(invoices);
    const totalValue = getTotalValue(invoices);
    updateFormData({ value: totalValue, invoices: invoices });
  };

  const handleAddRow = () => {
    updateInvoices([...invoices, {}]);
  };

  const handleRemoveInvoice = index => {
    const invoicesCopy = [...invoices];
    invoicesCopy.splice(index, 1);

    updateInvoices(invoicesCopy);
  };

  const handleChange = (index, { name, value }) => {
    if (!name) {
      return;
    }

    const invoicesCopy = [...invoices];

    if (name === "license_start_date") {
      invoicesCopy[index][name] = value || null;
    } else {
      invoicesCopy[index][name] = value?.replace(/[^\d\.]/g, "") || "";
    }

    updateInvoices(invoicesCopy);
  };

  const handleChangeDecimal = (index, data) => {
    let value = data.value;
    value = parseFloat(value);
    if (!isNaN(value)) {
      value = value.toFixed(2);
    }
    handleChange(index, { ...data, value });
  };

  const handleChangeDate = (index, moment) => {
    let value;
    if (typeof moment === "string") {
      value = moment;
    } else {
      value = moment.format("YYYY-MM-DD");
    }
    handleChange(index, {
      name: "license_start_date",
      value,
    });
  };

  const handleNumericInput = (index, data) => {
    let value = data.value;
    if (!isNaN(value) || value === "") {
      // Only call handleChange when value is a number or empty string
      handleChange(index, data);
    }
  };

  const isDefaultOptions = value => {
    return isDiscountOption(value) || isPenaltyOption(value);
  };

  const isDiscountOption = value => {
    return (
      typeof value === "string" &&
      value.toUpperCase() === defaultOptions.DISCOUNT.id.toUpperCase()
    );
  };

  const isPenaltyOption = value => {
    return (
      typeof value === "string" &&
      value.toUpperCase() === defaultOptions.PENALTY.id.toUpperCase()
    );
  };

  const handleProductChange = async (index, { value }) => {
    const invoicesCopy = [...invoices];
    let newAmount = 0;

    if (!isDefaultOptions(value)) {
      const product = await ProductService.get(value);

      newAmount = parseFloat(product.list_price).toFixed(2);
    }

    invoicesCopy[index]["amount"] = parseFloat(newAmount).toFixed(2);
    invoicesCopy[index]["product_id"] = value;
    invoicesCopy[index]["is_discount"] = isDiscountOption(value);
    invoicesCopy[index]["is_penalty"] = isPenaltyOption(value);
    updateInvoices(invoicesCopy);
  };

  const getRowAmount = invoice => {
    if (!invoice.product_id || !invoice.amount) return 0;

    const amount = invoice.amount && parseFloat(invoice.amount);
    const term = (invoice.term && parseInt(invoice.term)) || 0;
    const quantity = (invoice.quantity && parseInt(invoice.quantity)) || 0;

    let result = amount;

    if (!isDefaultOptions(invoice.product_id)) {
      result = result * term * quantity;
    }

    if (isDiscountOption(invoice.product_id)) {
      return result * -1;
    }

    return result;
  };

  const getTotalValue = (invoicesToSum = invoices) => {
    return invoicesToSum.reduce((sum, invoice) => {
      sum += getRowAmount(invoice);
      return sum;
    }, 0);
  };

  const buildPrice = value => {
    if (value < 0) {
      return `-$${Math.abs(value).toFixed(2)}`;
    }
    return `$${value.toFixed(2)}`;
  };

  return (
    <React.Fragment>
      <h3>Invoice</h3>
      <Divider />
      <Table size="small" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Term</Table.HeaderCell>
            <Table.HeaderCell>Quantity</Table.HeaderCell>
            <Table.HeaderCell>License Start</Table.HeaderCell>
            <Table.HeaderCell>Row Amount</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {invoices &&
            invoices.map((invoice, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell>
                    {readOnly ? (
                      <b>
                        {
                          productOptions.find(product => {
                            return product.value === invoice.product_id;
                          })?.text
                        }
                      </b>
                    ) : (
                      <Form.Select
                        inline={false}
                        required
                        clearable
                        placeholder="Pick a Product"
                        name="product"
                        value={invoice.product_id}
                        onChange={(_, data) => {
                          handleProductChange(index, data);
                        }}
                        options={productOptions}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {readOnly ? (
                      <b>{invoice.amount}</b>
                    ) : (
                      <Input
                        required
                        placeholder="Amount"
                        name="amount"
                        value={invoice.amount}
                        disabled={!isDefaultOptions(invoice.product_id)}
                        onChange={(_, data) => {
                          handleNumericInput(index, data);
                        }}
                        onBlur={event => {
                          handleChangeDecimal(index, {
                            name: event.target.name,
                            value: event.target.value,
                          });
                        }}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {readOnly ? (
                      <b>{invoice.term}</b>
                    ) : (
                      !isDefaultOptions(invoice.product_id) && (
                        <Input
                          required
                          placeholder="Term"
                          name="term"
                          value={invoice.term}
                          onChange={(_, data) => {
                            handleChange(index, data);
                          }}
                        />
                      )
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {readOnly ? (
                      <b>{invoice.quantity}</b>
                    ) : (
                      !isDefaultOptions(invoice.product_id) && (
                        <Input
                          required
                          placeholder="Quantity"
                          name="quantity"
                          value={invoice.quantity}
                          onChange={(_, data) => {
                            handleChange(index, data);
                          }}
                        />
                      )
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {readOnly ? (
                      <b>{invoice.license_start_date}</b>
                    ) : (
                      !isDefaultOptions(invoice.product_id) && (
                        <Datetime
                          name="license_start_date"
                          value={invoice.license_start_date}
                          closeOnSelect={true}
                          onChange={moment => handleChangeDate(index, moment)}
                          displayTimeZone={timeZone}
                          renderInput={props => (
                            <Input icon="large calendar outline" {...props} />
                          )}
                          timeFormat={false}
                          dateFormat={"YYYY-MM-DD"}
                        />
                      )
                    )}
                  </Table.Cell>
                  <Table.Cell>{buildPrice(getRowAmount(invoice))}</Table.Cell>
                  {!readOnly && (
                    <Table.Cell className="actions">
                      <div>
                        <Icon
                          style={{ color: "#fe0202" }}
                          link
                          name="cancel"
                          onClick={() => handleRemoveInvoice(index)}
                          title="Cancel Edit"
                        />
                      </div>
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          {!readOnly && (
            <Table.Row>
              <Table.Cell>
                <Button
                  basic
                  icon="plus"
                  type="button"
                  size="tiny"
                  onClick={handleAddRow}
                  content="Add Row"
                />
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell colSpan="4">
              {readOnly ? (
                <>
                  <h4>Comments</h4>
                  <b>{formData?.comments}</b>
                </>
              ) : (
                <Form.TextArea
                  inline
                  name="comments"
                  label="Comments"
                  value={formData?.comments}
                  onChange={(_, { value }) => {
                    updateFormData({ comments: value });
                  }}
                />
              )}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <b>Total Value</b>
              <h3 style={{ margin: "10px" }}>{buildPrice(getTotalValue())}</h3>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </React.Fragment>
  );
}

export default RevenueOpportunityInvoices;
