import React, { Component } from "react";
import { Checkbox, Form, List } from "semantic-ui-react";
import RuvixxForm from "../RuvixxForm";
import CampaignTargetCallService from "../../services/CampaignTargetCall";
import CampaignService from "../../services/Campaign";
import TagList from "../TagList";
import TagDropdown from "../TagDropdown";
import TagService from "../../services/Tag";
import ContactService from "services/Contact";
import UserService from "../../services/User";

const MODEL_TYPES = {
  entities: "Entity",
  contacts: "Contact",
  cases: "Case",
  campaignTargetCalls: "CampaignTargetCall",
};

const CALL_DIRECTION_INBOUND = "INBOUND";

class EditCallForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      call: this.props.call,
      callId: this.props.call.id,
      selectedContact: this.props.call.contact_id,
      campaignId: this.props.call.campaign_id,
      entityId: this.props.call.entity_id,
      dispositionId: this.props.call.disposition_id,
      statusVerified: this.props.call.status_verified || false,
      isInboundCall: this.props.call.call_direction === CALL_DIRECTION_INBOUND,
      selectedCampaign: this.props.call.campaign_id || null,
      selectedCaller: this.props.call.agent_id || null,
      tags: [],
      showDisabledTags: {},
      contactOptions: [],
    };

    this.onSuccess = this.onSuccess.bind(this);
  }

  handleChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  toggle = (e, data) => this.setState({ [data.name]: data.checked });

  handleSubmit = async e => {
    const {
      callId,
      dispositionId,
      statusVerified,
      selectedCampaign,
      selectedCaller,
      selectedContact,
    } = this.state;

    const call = await CampaignTargetCallService.editCall({
      callId: callId,
      dispositionId: dispositionId,
      statusVerified: statusVerified,
      campaignId: selectedCampaign,
      contactId: selectedContact,
      creatorUserId: selectedCaller,
    });

    this.setState({ call });
  };

  onSuccess() {
    this.props.handleClose();
    this.props.onSuccess(this.state.call);
  }

  renderTagItem = (item, index) => {
    const { title, tags, modelType, modelName, model, value } = item;
    const { showDisabledTags } = this.state;
    if (!model) {
      return null;
    }

    let filteredTags = tags;
    let filteredModelTags = value;
    if (!showDisabledTags[modelType]) {
      filteredTags = tags.filter(({ enabled }) => enabled === true);
      filteredModelTags = value.filter(({ enabled }) => enabled === true);
    }

    return (
      <List.Item key={index}>
        <List.Header style={{ marginBottom: "10px" }}>
          <div
            className="tags"
            style={{ display: "flex", alignItems: "center" }}
          >
            {title || "Tags"}
            <TagDropdown
              key={`sidebar-tag-dropdown-${modelType}-${model.id}`}
              tags={filteredTags}
              modelType={modelType}
              rows={[model]}
              checkedArr={[model.id]}
              onSuccess={this.handleTagUpdate}
              direction="right"
              isWithinModal={true}
            />
          </div>
        </List.Header>
        <List.Content>
          <TagList
            key={`sidebar-tags-${modelType}-${model.id}`}
            tags={filteredModelTags}
            modelType={MODEL_TYPES[modelName]}
            modelId={model.id}
            onUpdate={this.handleTagUpdate}
          />
          <Checkbox
            style={{ margin: "5px 0" }}
            label="Show disabled tags"
            checked={showDisabledTags[modelType]}
            onChange={(_, { checked }) =>
              this.changeShowDisabledTags(checked, item)
            }
          />
        </List.Content>
      </List.Item>
    );
  };

  changeShowDisabledTags = async (checked, item) => {
    const { showDisabledTags } = this.state;

    const newShowDisabledTags = {
      ...showDisabledTags,
      [item.modelType]: checked,
    };

    this.setState({
      showDisabledTags: newShowDisabledTags,
    });
  };

  async fetchTags() {
    const tags = await TagService.getTags({ model: "CampaignTargetCall" });
    this.setState({ tags });
  }

  async fetchCampaigns() {
    const campaigns = await CampaignService.getCampaignsForFilters();
    this.setState({ campaigns });
  }

  fetchUsers = async () => {
    const rawUsers = await UserService.getUsers();
    const users = rawUsers.map(user => ({
      id: user.id,
      name: user.full_name,
      email: user.email,
    }));

    this.setState({ users }, this.updateFilterOptions);
  };

  componentDidMount() {
    this.fetchTags();
    this.fetchCampaigns();
    this.fetchUsers();
    this.updateContactOptions();
  }

  handleTagUpdate = async () => {
    const updatedCall = await CampaignTargetCallService.getCall(
      this.state.callId
    );
    this.setState({
      call: updatedCall,
    });
  };

  updateContactOptions = async searchQuery => {
    const filteredContacts = await ContactService.getContactsForFilters({
      phone_number: this.props.call.info.from,
      search_query: searchQuery,
    });

    const contactOptions = filteredContacts.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));
    this.setState({
      contactOptions,
    });
  };

  checkForm = () => {
    if (this.state.isInboundCall) {
      return (
        this.state.dispositionId &&
        this.state.selectedContact &&
        this.state.selectedCampaign &&
        this.state.selectedCaller
      );
    } else {
      return !!this.state.callId && !!this.state.dispositionId;
    }
  };

  render() {
    const {
      dispositionId,
      statusVerified,
      tags,
      call,
      isInboundCall,
      campaigns,
      users,
    } = this.state;

    return (
      <RuvixxForm
        ready={this.checkForm()}
        onSubmit={this.handleSubmit}
        onSuccess={this.onSuccess}
      >
        <Form.Select
          required
          label="Disposition"
          name="dispositionId"
          onChange={this.handleChange}
          selection
          search
          options={this.props.callStatuses}
          value={dispositionId}
        />
        {isInboundCall && (
          <Form.Select
            required
            label="Contact"
            placeholder="Select contact or type to search"
            search
            options={this.state.contactOptions}
            name="selectedContact"
            value={this.state.selectedContact}
            onChange={this.handleChange}
          />
        )}
        {isInboundCall && campaigns && users && (
          <>
            <Form.Select
              required
              label="Campaign"
              name="selectedCampaign"
              onChange={this.handleChange}
              selection
              search
              options={campaigns.map(campaign => ({
                key: campaign.id,
                text: campaign.name,
                value: campaign.id,
              }))}
              value={this.state.selectedCampaign}
            />
            <Form.Select
              required
              label="Agent"
              name="selectedCaller"
              onChange={this.handleChange}
              selection
              search
              options={users.map(user => ({
                key: user.id,
                text: user.name,
                value: user.id,
              }))}
              value={this.state.selectedCaller}
            />
          </>
        )}
        <Form.Checkbox
          required
          toggle
          name="statusVerified"
          checked={statusVerified}
          onChange={this.toggle}
          label={this.state.statusVerified ? "Verified" : "Un-Verified"}
        />
        {tags.length > 0 &&
          call.tags &&
          this.renderTagItem(
            {
              title: "Tags",
              tags: tags,
              modelName: "campaignTargetCalls",
              modelType: "CampaignTargetCall",
              model: call,
              value: call.tags,
            },
            0
          )}
      </RuvixxForm>
    );
  }
}

export default EditCallForm;
