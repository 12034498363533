import React, { useCallback, useEffect, useState } from "react";
import { Form, Checkbox, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";

import RuvixxForm from "components/RuvixxForm";
import VoicemailService from "services/Voicemails";
import AttachmentService from "services/Attachment";
import FileInput from "components/FileInput";
import useStore from "hooks/useStore";

function VoicemailForm(props) {
  const [formData, setFormData] = useState({
    name: "",
    files: [],
    is_shared: false,
  });
  const [attachmentName, setAttachmentName] = useState("");
  const [creatorUserId, setCreatorUserId] = useState();
  const userAuth = useStore("userAuth");

  const fetchVoicemail = useCallback(async voicemailId => {
    const voicemail = await VoicemailService.getVoicemail(voicemailId);
    const { id, name, is_shared, attachment, creator_user_id } = voicemail;
    setAttachmentName(attachment.file_name);
    setFormData({ id, name, is_shared });
    setCreatorUserId(creator_user_id);
  }, []);

  useEffect(() => {
    if (props.voicemailId) {
      fetchVoicemail(props.voicemailId);
    }
  }, [fetchVoicemail, props.voicemailId]);

  const handleChange = (_, data) => {
    const { name, type } = data;
    const value = type === "checkbox" ? data.checked : data.value;
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const handleFileChange = files => {
    const formDataCopy = { ...formData };
    let fileArr = [];
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        fileArr.push(files[key]);
      }
    }
    formDataCopy.files = fileArr;
    setFormData(formDataCopy);
    setAttachmentName(fileArr?.[0]?.name);
  };

  const handleSubmit = async e => {
    const { id, name, is_shared, files } = formData;
    let data = {
      name,
      is_shared,
    };

    if (files) {
      /*
       NOTE: Voicemail uploads are publicly available for now because of issue
         with the way the url is encoded if query params are present. To avoid
         using query params we are using only the key to fetch the voicemail mp3,
         but that requires the file to be public.
     */
      const attachments = await Promise.all(
        files.map(f => AttachmentService.uploadFile(f))
      );
      data = {
        ...data,
        attachments,
      };
    }

    try {
      if (id) {
        await VoicemailService.editVoicemail(id, data);
      } else {
        await VoicemailService.createVoicemail(data);
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  const shouldDisableIsShared = creatorUserId && creatorUserId !== userAuth.id;

  const IsSharedToggle = (
    <Checkbox
      toggle
      name="is_shared"
      checked={formData.is_shared}
      onChange={handleChange}
      disabled={shouldDisableIsShared}
    />
  );

  return (
    <RuvixxForm
      ready={!!formData.name && !!attachmentName}
      onSubmit={handleSubmit}
      onSuccess={props.onSuccess}
    >
      <Form.Input
        inline
        required
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <Form.Field inline required>
        <label>File</label>
        <FileInput
          label="File"
          accept=".mp3"
          value={attachmentName}
          onChange={handleFileChange}
        />
      </Form.Field>
      <Form.Field inline>
        <label>Shared</label>
        {shouldDisableIsShared ? (
          <Popup
            position="bottom left"
            content="You can only set this toggle for Voicemails that you have created."
            trigger={IsSharedToggle}
          />
        ) : (
          IsSharedToggle
        )}
      </Form.Field>
    </RuvixxForm>
  );
}

VoicemailForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default VoicemailForm;
