import React from "react";
import BaseTable from "../../components/BaseTable";
import AuthService from "../../services/Auth";
import CallQueueService from "../../services/CallQueue";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Button, Icon } from "semantic-ui-react";
import setPageTitle from "../../helpers/title";
import { dialSessionNameFormatted } from "../../helpers/sessionHelpers";

class AgentDashboardTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CallQueueService.getAgentDashboardTable;

    this.state = {
      ...this.state,
      enableSearch: true,
      className: "agent_dashboard",
      tableName: "agent_dashboard",
      noDataText: "No Calls",
    };
  }

  async componentDidMount() {
    setPageTitle("Agent Dashboard");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
    }
  }

  startCallMonitor = (campaignId, campaignTargetCallId) => {
    window.open(
      `/dialer-monitor/${campaignId}/${campaignTargetCallId}`,
      "_blank",
      "location=yes,height=750,width=1800,scrollbars=yes,status=yes"
    );
  };

  setColumns = () => {
    const columns = [
      {
        Header: "Agent",
        headerClassName: "padded",
        className: "padded",
        accessor: "agent_name",
      },
      {
        Header: "Contact",
        accessor: "contact_name",
      },
      {
        Header: "Session",
        accessor: "dial_session_name",
        Cell: ({ value }) => dialSessionNameFormatted(value),
      },
      {
        Header: "# Calls Made",
        accessor: "calls_made",
        maxWidth: 100,
      },
      {
        Header: "# Live Calls",
        accessor: "live_calls",
        maxWidth: 100,
      },
      {
        Header: "Phone Number",
        accessor: "number_called",
        maxWidth: 150,
        Cell: ({ value: number_called }) =>
          formatPhoneNumberIntl(number_called),
      },
      {
        Header: "",
        sortable: false,
        headerClassName: "non-sortable",
        maxWidth: 200,
        Cell: ({ original: { campaign_id, campaign_target_call_id } }) => (
          <Button
            compact
            color="blue"
            onClick={() =>
              this.startCallMonitor(campaign_id, campaign_target_call_id)
            }
          >
            Monitor Call
            <Icon name="phone" style={{ margin: "0 0 0 7px" }} />
          </Button>
        ),
      },
    ];
    this.initTableSettings(columns);
  };
}

export default AgentDashboardTable;
