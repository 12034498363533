import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Dropdown,
  Grid,
  Icon,
  Label,
  List,
  Modal,
  Pagination,
  Popup,
  Tab,
} from "semantic-ui-react";
import moment from "moment-timezone";

import {
  compareCreatedAt,
  formatDate,
  getFormEvents,
  objToArr,
  typeformTable,
} from "../helpers";
import { parseWixForm } from "../wixForms/helpers";
import Filter, { FilterButtons } from "../modals/Filter";
import EmailTemplateService from "../../services/EmailTemplate";
import ContactService from "../../services/Contact";
import UserService from "../../services/User";
import FormResponse from "../../services/FormResponse";
import EmailUrlService from "../../services/EmailUrls";
import CustomDropdown from "../CustomDropdown";
import _GenerateUrlModal from "../modals/GenerateUrlModal";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../hocs/withRoleCheck";

import "./../../styles/tab_view.scss";
import "./../../styles/audit_log.scss";
import "./ResponsesView.scoped.scss";

const EditFormResponseModal = withRoleCheck(_GenerateUrlModal, [
  ACL_RELATIONSHIPS.submittedFormResponse.read,
]);

const getMostRecentResponse = response_events => {
  let responses = response_events.sort((a, b) => {
    return (
      moment(b["form_response"]["submitted_at"]) -
      moment(a["form_response"]["submitted_at"])
    );
  });
  let response = responses[0];
  return response;
};

const EditedLabel = ({ response }) => {
  const [username, setUsername] = useState(null);
  const editedAt = formatDate(response["form_response"]["submitted_at"]);
  const editorId = response["form_response"]["editor_id"];

  const fetchEditor = useCallback(async () => {
    const users = await UserService.getUsers();
    const user = users.find(user => user["id"] === editorId);
    setUsername(user.full_name);
  }, [editorId]);

  useEffect(() => {
    fetchEditor();
  }, [fetchEditor, response]);

  return (
    <Popup
      content={`Edited on ${editedAt} by ${username}`}
      trigger={
        <Label
          size="mini"
          style={{ backgroundColor: "#fb8072" }}
          content="Edited"
          className="ctag edited-label"
        />
      }
    />
  );
};

const ResponseItem = memo(({ response, displayRecipient, submitted }) => {
  const {
    id,
    email_url,
    email_form,
    template,
    contact,
    template_name,
    created_at,
    info,
    contact_name,
    contact_email,
    campaign,
  } = response;
  const response_events = getFormEvents(info);
  const Buttons = response_events
    ? () => (
        <div>
          <Modal
            trigger={<Button basic size="tiny" content="View Response" />}
            closeIcon
            size="fullscreen"
          >
            <Modal.Header>Response</Modal.Header>
            <Modal.Content>
              {parseWixForm(
                getMostRecentResponse(response_events),
                id,
                email_url.language
              )}
            </Modal.Content>
          </Modal>
          <EditFormResponseModal
            contactId={contact.id}
            campaignId={campaign.id}
            campaignTargetFormResponseId={id}
            emailUrlId={email_url.id}
            trigger={<Button basic size="tiny" content="Edit Response" />}
            isEdit={true}
          />
        </div>
      )
    : () => {
        if (response.form_urls && response.form_urls.length > 1) {
          return (
            <CustomDropdown icon="ellipsis horizontal">
              <Dropdown.Menu direction="left">
                {response.form_urls.map(form_url => (
                  <Dropdown.Item
                    content={"Submit " + form_url.name}
                    onClick={e => window.open(form_url.url)}
                  />
                ))}
              </Dropdown.Menu>
            </CustomDropdown>
          );
        } else if (response.form_urls && response.form_urls.length == 1) {
          return (
            <Button
              basic
              size="tiny"
              content={"Submit " + response.form_urls[0].name}
              onClick={e => window.open(response.form_urls[0].url)}
            />
          );
        } else {
          return null;
        }
      };

  function getHeader() {
    let header, meta;
    const templateName =
      template && template.name ? template.name : template_name;
    meta = `Sent via: ${templateName || "dial session"}`;
    if (email_url && email_url.name) {
      header = email_url.name;
    } else if (email_form && email_form.name) {
      header = email_form.name;
    } else {
      header = templateName;
      meta = null;
    }
    const hasMultipleEvents = response_events && response_events.length > 1;
    return [
      <Card.Header>
        {hasMultipleEvents && (
          <EditedLabel response={getMostRecentResponse(response_events)} />
        )}
        <Icon name="file" />
        {header}
      </Card.Header>,
      <Card.Meta>{meta}</Card.Meta>,
    ];
  }

  return (
    <Card fluid>
      <Card.Content>
        {getHeader()}
        {response_events && (
          <Card.Description>
            Submitted by <strong>{contact.name}</strong> ({contact.email})
          </Card.Description>
        )}
      </Card.Content>
      <Card.Content extra>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {submitted ? (
            <span>Submitted on {formatDate(created_at)}</span>
          ) : (
            <span>
              Sent{" "}
              {(displayRecipient && !response_events && (
                <>
                  to <strong>{contact_name}</strong> ({contact_email})
                </>
              )) ||
                ""}{" "}
              on {formatDate(created_at)}
            </span>
          )}
          <Buttons />
        </div>
      </Card.Content>
    </Card>
  );
});

function ResponsesList({
  responses,
  displayRecipient,
  fetchEmailedForms,
  modelType,
  filters,
  loaded,
  submitted,
}) {
  return (
    <List>
      <List.Item>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              {responses.data.length === 0 ? (
                <div className="no-results">
                  {loaded ? (
                    <p>
                      Nothing to show for this {modelType}.
                      {Object.keys(filters).length > 0 &&
                        " Try adjusting the filters."}
                    </p>
                  ) : (
                    <Icon loading name="circle notched" size="big" />
                  )}
                </div>
              ) : (
                <List>
                  <List.Item>
                    <Pagination
                      activePage={responses.page}
                      totalPages={responses.pages}
                      disabled={responses.pages === 0}
                      onPageChange={(_, { activePage }) => {
                        fetchEmailedForms(activePage);
                      }}
                    />
                  </List.Item>
                  {responses.data.map((response, index) => (
                    <List.Item key={index}>
                      <ResponseItem
                        key={index}
                        response={response}
                        displayRecipient={displayRecipient}
                        submitted={submitted}
                      />
                    </List.Item>
                  ))}
                </List>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </List.Item>
    </List>
  );
}

function ResponsesView({
  modelType,
  modelId,
  displayRecipient,
  fetchEmailedForms,
  itemsPerPage = 5,
}) {
  const [activeResponses, setActiveResponses] = useState({
    data: [],
    page: 1,
    pages: 1,
  });

  const [submittedResponses, setSubmittedResponses] = useState({
    data: [],
    page: 1,
    pages: 1,
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const [options, setOptions] = useState([]);
  const [checked, setChecked] = useState({});
  const [filters, setFilters] = useState({});
  const [loaded, setLoaded] = useState(false);

  const queryActiveResponses = useCallback(
    async activePage => {
      const filterParams = {
        has_form_events: "false",
        page: activePage,
        per_page: itemsPerPage,
        sort_by: "created_at",
        sort_dir: "desc",
        template_id: filters["Email Template"],
        email_url_id: filters["Form"],
        contact_id: modelType === "Contact" ? modelId : filters["Contact"],
      };
      const activeResponses = await fetchEmailedForms(filterParams);

      setActiveResponses(activeResponses);
    },
    [fetchEmailedForms, itemsPerPage, filters]
  );

  const querySubmittedResponses = useCallback(
    async activePage => {
      const filterParams = {
        page: activePage,
        per_page: itemsPerPage,
        sort_by: "created_at",
        sort_dir: "desc",
        template_id: filters["Email Template"],
        email_url_id: filters["Form"],
        contact_id: modelType === "Contact" ? modelId : filters["Contact"],
        entity_id: modelType === "Entity" ? modelId : filters["Entity"],
      };
      const submittedResponses = await FormResponse.getFormResponses(
        filterParams
      );

      setSubmittedResponses(submittedResponses);
      if (Object.keys(filters).length === 0) {
        setActiveIndex(submittedResponses.data.length > 0 ? 0 : 1);
      }
      setLoaded(true);
    },
    [fetchEmailedForms, itemsPerPage, filters]
  );

  const fetchFilterOptions = useCallback(async () => {
    const templates = (
      await EmailTemplateService.getEmailTemplatesForFilters()
    ).map(({ id, name }) => ({ id, name }));

    let forms = await EmailUrlService.getForms();
    forms.sort(compareCreatedAt);
    forms = forms.map(forms => ({
      id: forms.id,
      name: forms.name,
    }));

    let options = [
      { title: "Email Template", data: templates },
      { title: "Form", data: forms },
    ];

    if (modelType === "Entity") {
      const contacts = (
        await ContactService.getContactsForFilters({ entity_ids: modelId })
      ).map(({ id, name }) => ({ id, name }));
      options = [...options, { title: "Contact", data: contacts }];
    }
    setOptions(options);
    setLoaded(true);
  }, [modelType, modelId]);

  useEffect(() => {
    (async () => {
      if (activeIndex === 0) {
        await querySubmittedResponses(1);
      } else if (activeIndex === 1) {
        await queryActiveResponses(1);
      }
      await fetchFilterOptions();
    })();
  }, [activeIndex, filters]);

  const handleTabChange = (_, { activeIndex }) => {
    setActiveIndex(activeIndex);
    setLoaded(false);
  };

  function applyFilter(newChecked = null) {
    const chk = newChecked || checked;
    const filters = options.reduce(
      (accum, { title }) => ({ ...accum, [title]: objToArr(chk[title]) }),
      {}
    );
    newChecked && setChecked(newChecked);
    setFilters(filters);
  }

  function clearFilter(filter) {
    let newChecked = {};
    if (filter !== "Clear All") {
      newChecked = Object.keys(checked).reduce(
        (prev, cur) => ({
          ...prev,
          ...(cur !== filter && { [cur]: checked[cur] }),
        }),
        {}
      );
    }
    applyFilter(newChecked);
  }

  const panes = [
    {
      menuItem: "Submitted",
      render: () => (
        <Tab.Pane>
          <Container>
            <ResponsesList
              responses={submittedResponses}
              displayRecipient={displayRecipient}
              fetchEmailedForms={querySubmittedResponses}
              modelType={modelType}
              filters={filters}
              loaded={loaded}
              submitted={true}
              key="submitted-responses"
            />
          </Container>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Incomplete",
      render: () => (
        <Tab.Pane>
          <Container>
            <ResponsesList
              responses={activeResponses}
              displayRecipient={displayRecipient}
              fetchEmailedForms={queryActiveResponses}
              modelType={modelType}
              filters={filters}
              loaded={loaded}
              submitted={false}
              key="active-responses"
            />
          </Container>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <List className="responsesView tabView">
      <List.Item>
        <div className="filterButtons">
          <FilterButtons
            filters={filters}
            checked={checked}
            clearFilter={clearFilter}
          />
        </div>
      </List.Item>
      <List.Item className="filterButtonParent">
        <div className="filterButton">
          Filter
          <Filter
            options={options}
            checked={checked}
            updateChecked={setChecked}
            applyFilter={applyFilter}
            trigger={<Button basic size="tiny" icon="filter" />}
          />
        </div>
        <Tab
          panes={panes}
          className="responses-tabs"
          activeIndex={activeIndex}
          onTabChange={handleTabChange}
        />
      </List.Item>
    </List>
  );
}

export default memo(ResponsesView);
