import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useLocation, useParams } from "react-router-dom";
import { useUserStatus } from "components/UserStatus/UserStatusContext";
import { USER_STATUSES } from "constants/Constants";
import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  Icon,
  Label,
  Menu,
  Popup,
} from "semantic-ui-react";
import store from "store";
import { Device } from "twilio-client";

import ACL_RELATIONSHIPS from "acl-relationships";
import CopyCCEmail from "components/CopyCCEmail";
import withRoleCheck from "components/hocs/withRoleCheck";
import PhoneDetails from "components/PhoneDetails";
import RichTextEditor from "components/RichEditor/RichTextEditor";
import Sidebar from "components/Sidebar";
import AuditLog from "components/views/AuditLog";
import NotesView from "components/views/NotesView";
import ResponsesView from "components/views/ResponsesView";
import CONFIG from "Config";
import {
  CALL_DISPOSITION_IDS,
  CALL_DISPOSITION_TYPES,
  DEFAULT_CAMPAIGN_ID,
  generateTabs,
} from "constants/Constants";
import {
  CALL_RECORDING_ACTIONS,
  CALL_RECORDING_LAW,
  DIALER_STATES,
} from "constants/Dialer";
import { noTag } from "constants/Tag";
import { isContactCallable, isContactEmailable } from "helpers/contacts";
import { getSession, removeSession, setSession } from "helpers/sessionHelpers";
import setPageTitle from "helpers/title";
import useTags from "hooks/useTags";
import ActivityService from "services/Activity";
import { ApiSetting } from "services/ApiSettings";
import AuthService from "services/Auth";
import CallDispositionService from "services/CallDispositions";
import CallQueueService from "services/CallQueue";
import CampaignTargetCallService from "services/CampaignTargetCall";
import ContactService from "services/Contact";
import CampaignService from "services/Campaign";
import DialSessionService from "services/DialSessions";
import TagService from "services/Tag";
import TwilioService from "services/twilio";
import { SendEmailModal as _SendEmailModal } from "../campaign/components/Modals";

import { EditContactModal } from "../entity/components/Modals";

import DialerFooter, { WarningModal } from "./components/DialerFooter";
import DialerHeader from "./components/DialerHeader";

import PreventClosingModal from "./components/PreventClosingModal";
import RecordingConsentModal from "./components/RecordingConsentModal";
import ScheduledCallModal from "./components/ScheduledCallModal";
import { EPOCH_TIMESTAMP } from "constants/Constants";
import { tableTimestamp } from "components/helpers";
import CustomDropdown from "components/CustomDropdown";
import AssignCampaignModal from "./components/AssignCampaignModal";
import AssignContactModal from "./components/AssignContactModal";
import AddContactModal from "routes/entity/components/AddContactModal";
import RuvixxDate from "components/RuvixxDate";

import "./PowerDialer.scoped.scss";
import EntityService from "services/Entity";

const EditContactButton = withRoleCheck(Dropdown.Item, [
  ACL_RELATIONSHIPS.entityContact.edit,
  ACL_RELATIONSHIPS.entityForFilters.read,
]);
const AddContactButton = withRoleCheck(Dropdown.Item, [
  ACL_RELATIONSHIPS.entityContact.create,
  ACL_RELATIONSHIPS.entityForFilters.read,
]);
const SendEmailModal = withRoleCheck(_SendEmailModal, [
  ACL_RELATIONSHIPS.emailTemplates.read,
  ACL_RELATIONSHIPS.contactEmail.create,
]);

const tabs = generateTabs(
  "script",
  "survey",
  "activityLog",
  "notes",
  "submittedForms"
);

const DialButton = ({
  paused,
  callNumber,
  deviceStatus,
  makeCall,
  hangup,
  callAttempted,
  dispositionId,
  grantedConsent,
  isRecordingEnabled,
  isTwoPartyConsent,
  toggleConsentModal,
}) => {
  let buttonText = "Not Ready";
  let buttonDisabled = true;
  let onClick = () => void 0;
  if (paused) {
    buttonText = "Paused";
  } else if (callNumber) {
    switch (deviceStatus) {
      case DIALER_STATES.OFFLINE:
        buttonText = "Offline";
        break;
      case DIALER_STATES.READY:
        buttonText = "Dial";
        buttonDisabled = false;
        onClick = makeCall;
        break;
      case DIALER_STATES.CONNECTED:
        buttonDisabled = false;
        buttonText = "Hang Up";
        onClick = hangup;
        break;
      case DIALER_STATES.INCOMING:
        buttonText = "Incoming";
        break;
      default:
    }
  }
  const displayPopup =
    callAttempted && !dispositionId && deviceStatus === DIALER_STATES.READY;
  if (displayPopup) {
    onClick = () => void 0;
  }

  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(buttonDisabled);

  const showConsent =
    grantedConsent == null &&
    isTwoPartyConsent &&
    deviceStatus === DIALER_STATES.CONNECTED &&
    isRecordingEnabled;

  const { status } = useUserStatus();

  useEffect(() => {
    if (status === USER_STATUSES.ON_CALL.key && buttonText === "Dial") {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(buttonDisabled);
    }
  }, [status]);

  return (
    <>
      <Popup
        content={
          displayPopup &&
          "A call disposition must be set before next call attempt"
        }
        on="hover"
        onOpen={() => {
          displayPopup && setPopupIsOpen(true);
        }}
        onClose={() => {
          setPopupIsOpen(false);
        }}
        open={popupIsOpen}
        trigger={
          <Button
            content={buttonText}
            disabled={isButtonDisabled}
            onClick={() => {
              if (showConsent) {
                const hanging = true;
                toggleConsentModal(hanging);
              } else {
                onClick();
              }
            }}
          />
        }
      />
    </>
  );
};

const CallRecordingControls = ({
  recordingStatus,
  handleSetRecordingStatus,
  deviceStatus,
}) => {
  const isRecording = recordingStatus === CALL_RECORDING_ACTIONS.START;
  const isConnected = deviceStatus === DIALER_STATES.CONNECTED;
  const recordingDeleted = recordingStatus === CALL_RECORDING_ACTIONS.DELETE;

  const handleToggleRecording = () => {
    handleSetRecordingStatus(
      isRecording ? CALL_RECORDING_ACTIONS.PAUSE : CALL_RECORDING_ACTIONS.START
    );
  };
  const handleStopRecording = () => {
    handleSetRecordingStatus(CALL_RECORDING_ACTIONS.DELETE);
  };

  return (
    <div>
      <Button.Group basic className="recording">
        <Popup
          on="hover"
          content={`${isRecording ? "Pause" : "Resume"} recording`}
          trigger={
            <Button
              icon
              className="recording-indicator"
              onClick={handleToggleRecording}
              disabled={recordingDeleted || !isConnected}
            >
              {" "}
              <Icon
                name="circle"
                color="red"
                className={`${isRecording && isConnected && "blink"}`}
              />
            </Button>
          }
        />
        <Popup
          on="hover"
          content="End and delete recording. Recording cannot be re-started nor recovered after deleting."
          trigger={
            <Button
              icon
              onClick={handleStopRecording}
              disabled={!recordingStatus || recordingDeleted || !isConnected}
            >
              <Icon name="stop" color="black" />
            </Button>
          }
        />
      </Button.Group>
    </div>
  );
};

const DialerInfo = ({
  autoDial,
  paused,
  deviceStatus,
  callNumber,
  makeCall,
  hangup,
  contact = { enabled_phone_numbers: [] },
  setCallNumber,
  callAttempted,
  dispositionId,
  recordingStatus,
  handleSetRecordingStatus,
  isRecordingEnabled,
  isTwoPartyConsent,
  setConsentModal,
  grantedConsent,
  callerIdPhoneNumberId,
  setCallerIdPhoneNumberId,
  callerIdPhoneNumbers,
  numCalls,
  device,
  callInfo,
}) => {
  const [contactPhoneNumbers, setContactPhoneNumbers] = useState([]);
  const [modalOpenWarning, setModalOpenWarning] = useState(false);

  const handleModalClose = () => {
    setModalOpenWarning(false);
  };

  const handleButtonClick = () => {
    if (numCalls >= 5) {
      setModalOpenWarning(true);
    } else {
      makeCall();
    }
  };

  useEffect(() => {
    if (
      contact.enabled_phone_numbers !== undefined &&
      !isEqual(contactPhoneNumbers, contact.enabled_phone_numbers)
    ) {
      setContactPhoneNumbers(contact.enabled_phone_numbers);
    }
  }, [contact]);

  const getTextForNumberSelector = phone => {
    const formattedNumber = formatPhoneNumberIntl(phone.number);
    // TODO: this will be used in a future update which displays both counts
    // const contactTimesCalled = phone.call_counts?.contact_count || 0;
    const totalTimesCalled = phone.call_counts?.total_count || 0;
    if (totalTimesCalled === 0) {
      return formattedNumber;
    }
    return (
      <>
        {formattedNumber} &nbsp;
        <span className="times-called">({totalTimesCalled})</span>
      </>
    );
  };

  return (
    <div className="dialer-top">
      <div className="dialer-numbers">
        <div className="dialer-number">
          {callInfo.isIncomingCall ? (
            <>
              <p>In a call with </p>
              <Button
                as="div"
                labelPosition="left"
                className="dial"
                disabled={deviceStatus !== DIALER_STATES.CONNECTED}
              >
                <Label basic size="large">
                  {callInfo.phone_number}
                </Label>
                <Button
                  className="dial"
                  disabled={deviceStatus !== DIALER_STATES.CONNECTED}
                  onClick={() => {
                    // debugger
                    device.disconnectAll();
                    CampaignTargetCallService.hungup(callInfo.call_id);
                  }}
                >
                  Hang up
                </Button>
              </Button>
            </>
          ) : (
            <>
              <p>Calling To</p>
              <Popup
                content="None of the phone number types enabled for the session are available for this contact"
                disabled={contactPhoneNumbers.length > 0}
                trigger={
                  <Button as="div" labelPosition="left" className="dial">
                    {contactPhoneNumbers.length > 0 ? (
                      <Dropdown
                        className="dialer-number-selector"
                        disabled={autoDial}
                        value={callNumber}
                        options={contactPhoneNumbers.map(phone => ({
                          key: phone.number,
                          text: getTextForNumberSelector(phone),
                          value: phone.number,
                          disabled: !phone.enabled,
                        }))}
                        onChange={(e, { value }) => {
                          setCallNumber(value);
                        }}
                      />
                    ) : (
                      <Label
                        as="a"
                        basic
                        content={callNumber || "unavailable"}
                      />
                    )}

                    {autoDial &&
                    deviceStatus !== DIALER_STATES.CONNECTED ? null : (
                      <>
                        <DialButton
                          autoDial={autoDial}
                          callNumber={callNumber}
                          deviceStatus={deviceStatus}
                          makeCall={handleButtonClick}
                          hangup={hangup}
                          paused={paused}
                          callAttempted={callAttempted}
                          dispositionId={dispositionId}
                          isRecordingEnabled={isRecordingEnabled}
                          grantedConsent={grantedConsent}
                          isTwoPartyConsent={isTwoPartyConsent}
                          toggleConsentModal={setConsentModal}
                          handleModalClose={handleModalClose}
                        />
                        <WarningModal
                          modalOpenWarning={modalOpenWarning}
                          handleModalClose={handleModalClose}
                          numCalls={numCalls}
                          handleClickNext={makeCall}
                        />
                      </>
                    )}
                  </Button>
                }
              />
            </>
          )}
        </div>
        <div className="dialer-number">
          <p>{callInfo.isIncomingCall ? "Inbound Line" : "Caller ID"}</p>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
            }}
          >
            <Dropdown
              disabled={callInfo.isIncomingCall}
              scrolling
              className="dialer-number-selector"
              style={{ lineHeight: "1em" }}
              name="userPhoneNumberId"
              value={callerIdPhoneNumberId}
              options={callerIdPhoneNumbers}
              onChange={(e, { value }) => {
                setCallerIdPhoneNumberId(value);
              }}
            />
          </div>
        </div>
        {isRecordingEnabled && (
          <div className="dialer-number">
            <p>Call recording</p>
            <CallRecordingControls
              recordingStatus={recordingStatus}
              handleSetRecordingStatus={handleSetRecordingStatus}
              deviceStatus={deviceStatus}
            />
          </div>
        )}
        {isRecordingEnabled &&
          grantedConsent == null &&
          callAttempted &&
          isTwoPartyConsent &&
          deviceStatus === DIALER_STATES.CONNECTED && (
            <div className="required-consent">
              <Button
                className={"blink"}
                onClick={() => setConsentModal(false)}
                compact
                color={"red"}
              >
                Consent Required
              </Button>
            </div>
          )}
        {isRecordingEnabled && grantedConsent !== null && (
          <div className="required-consent">
            <Button compact color={grantedConsent ? "green" : "red"}>
              {grantedConsent ? "Consent Granted" : "Not Granted Consent"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

function PowerDialer(props) {
  const [token, setToken] = useState(null);
  const [device, setDevice] = useState(null);
  const [connection, setConnection] = useState(null);
  const [call, setCall] = useState(null);

  const [callSid, setCallSid] = useState("");

  const [dialerInfo, setDialerInfo] = useState({});
  const [contact, setContact] = useState({});
  const [deviceStatus, setDeviceStatus] = useState("");
  const [transferCallData, setTransferCallData] = useState({});
  const [callerId, setCallerId] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [counters, setCounters] = useState({
    contactsCalled: { pending: 0, confirmed: 0 },
    liveCalls: { pending: 0, confirmed: 0 },
    voiceMails: { pending: 0, confirmed: 0 },
  });
  const [paused, setPaused] = useState(false);
  const [activeTab, setActiveTab] = useState("survey");
  const [focusOnNewNote, setFocusOnNewNote] = useState(false);
  const [auditLogId, setAuditLogId] = useState(null);
  const [dispositions, setDispositions] = useState([]);
  const [dispositionId, setDispositionId] = useState(null);
  const [dispositionComment, setDispositionComment] = useState("");
  const [callAttempted, setCallAttempted] = useState(null);
  const [calleeSid, setCalleeSid] = useState(null);
  const [callbackDate, setCallbackDate] = useState("");
  const [assignToSelf, setAssignToSelf] = useState(false);
  const [scheduledModal, setScheduledModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [nextContactExists, setNextContactExists] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState(null);
  const [recordingLegalStatus, setRecordingLegalStatus] = useState(null);
  const [openConsentModal, setOpenConsentModal] = useState(false);
  const [grantedConsent, setGrantedConsent] = useState(null);
  const [hanging, setHanging] = useState(false);
  const [callerIdPhoneNumbers, setCallerIdPhoneNumbers] = useState([]);
  const [callerIdPhoneNumberId, setCallerIdPhoneNumberId] = useState(null);
  const [callerIdPhoneNumberType, setCallerIdPhoneNumberType] = useState(null);
  const [phoneNumberMap, setPhoneNumberMap] = useState({});
  const [preventClosingModal, setPreventClosingModal] = useState(false);
  const [dialSessionSummary, setDialSessionSummary] = useState({});
  const [callTags, setCallTags] = useState([]);
  const textEditor = useRef();
  const pendingConnection = useRef();

  const [callNumber, setCallNumber] = useState("");
  const [autoDial, setAutoDial] = useState(false);
  const [dialAllNumbers, setDialAllNumbers] = useState(false);
  const [dialedNumbers, setDialedNumbers] = useState([]);
  const [nextCallCountdown, setNextCallCountdown] = useState(null);
  const [isDispositionSet, setIsDispositionSet] = useState(null);
  const countdownApi = useRef();
  const [lastCalledContactId, setLastCalledContactId] = useState(null);
  const [haveNotes, setHaveNotes] = useState(false);
  const [updateAuditLogFlag, setUpdateAuditLogFlag] = useState(1);
  const [callInfo, setCallInfo] = useState({});

  const assignCampaignRef = useRef();
  const assignContactRef = useRef();
  const addContactRef = useRef();
  const editContactRef = useRef();
  const [campaignId, setCampaignId] = useState();
  const [contactId, setContactId] = useState();
  const [entity, setEntity] = useState({});
  const [contactsWithSamePhone, setContactsWithSamePhone] = useState([]);

  const [refreshSidebarCounter, setRefreshSidebarCounter] = useState(0);

  const contactTags = useTags("Contact");
  const entityTags = useTags("Entity");

  const params = useParams();
  const location = useLocation();

  const identity = `client:${store.get("userAuth").email}`;

  const addContactToQueue = useCallback(
    async (contactInfo, dialNext) => {
      const dialSessionId = params.id;
      const contactId = contactInfo.id;
      CallQueueService.addContact(dialSessionId, contactId, dialNext);
      setNextContactExists(true);
    },
    [params.id]
  );

  const refreshSidebar = () => {
    setRefreshSidebarCounter(refreshSidebarCounter + 1);
  };

  const getDialSessionSummary = async id => {
    const sessionSummary = await DialSessionService.getSessionSummary(id);
    setDialSessionSummary(sessionSummary);
  };

  const fetchCallInfo = useCallback(async callSid => {
    if (callSid) {
      const call = await CampaignTargetCallService.getCallInfo(callSid);
      if (call?.caller_id) {
        setCallerId(call.caller_id);
        setCall(call);
      }
    }
  }, []);

  const hangup = useCallback(() => {
    if (device) {
      device.disconnectAll();
    }
    CampaignTargetCallService.hungup(callSid);
  }, [callSid]);

  const updateStatus = useCallback(
    async redial => {
      if (!callSid) return;
      const disposition = dispositions.find(
        disposition => disposition.id === dispositionId
      );
      if (
        dispositionId === CALL_DISPOSITION_IDS.CALLBACK ||
        (disposition && disposition.type === CALL_DISPOSITION_TYPES.CONNECTED)
      ) {
        incrementCounter("liveCalls");
      } else if (
        disposition &&
        disposition.type === CALL_DISPOSITION_TYPES.VOICEMAIL
      ) {
        incrementCounter("voiceMails");
      }
      if (!isDispositionSet) {
        TwilioService.addCallDisposition(
          callSid,
          dispositionId,
          dispositionComment,
          callbackDate,
          redial,
          callNumber,
          grantedConsent,
          assignToSelf
        );
        setIsDispositionSet(true);
      }
      if (deviceStatus === DIALER_STATES.CONNECTED) hangup();
      setCallSid(null);
      setCalleeSid(null);
      setDispositionId(null);
      setIsDispositionSet(null);
      setDispositionComment(null);
      setCallbackDate("");
    },
    [
      callSid,
      deviceStatus,
      dispositionComment,
      dispositionId,
      dispositions,
      hangup,
      callbackDate,
    ]
  );

  const onUnload = async () => {
    const data = store.get("currentCallData");
    const userAuth = store.get("userAuth");
    const url =
      ApiSetting.DEFAULT_HOST +
      ApiSetting.DEFAULT_BASE_PATH +
      "/twilio/disposition";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: userAuth.jwt,
      },
      body: JSON.stringify(data),
    });
    store.remove("currentCallData");
    removeSession();
  };

  async function handleCloseSession() {
    let sessionEnded = true;
    if (callAttempted && !dispositionId) {
      setPreventClosingModal(true);
      sessionEnded = false;
    } else {
      updateStatus();
      removeSession();
    }

    if (!sessionEnded) {
      return;
    }

    setTimeout(() => window.close(), 2000);
  }

  const makeCall = () => {
    if (!contact || ["calling", "connected"].includes(deviceStatus)) {
      return;
    }
    if (callAttempted && !dispositionId) {
      return;
    }
    if (autoDial && dialedNumbers.includes(callNumber)) {
      return;
    }
    setHanging(false);
    setGrantedConsent(null);
    setDeviceStatus("calling");
    incrementCounter("contactsCalled");
    if (AuthService.isLoggedIn()) {
      if (callAttempted && !!dispositionId) {
        updateStatus(true);
      }
      const [numberType, numberId] = callerIdPhoneNumberId.split("-");
      const payload = {
        agent: store.get("userAuth").email,
        To: callNumber,
        From: identity,
        Caller: identity,
        Direction: "outbound",
        contact_id: contact.id,
        entity_id: dialerInfo.entity.id,
        dial_session_id: dialerInfo.dial_session.id,
        campaign_id: dialerInfo.dial_session.campaign_id,
        call_queue_id: dialerInfo.id,
        enable_recording:
          recordingLegalStatus !== CALL_RECORDING_LAW.NO_RECORDING,
        caller_id_number_id: numberId,
        caller_id_number_type: numberType,
      };
      dialedNumbers.push(callNumber);
      setDialedNumbers(dialedNumbers);
      setConnection(device.connect(payload));
      setCallAttempted(true);
      setLastCalledContactId(contact.id);
      // Some time is required before a recording is activated and available
      setTimeout(() => handleSetRecordingLegalStatus(), 5000);
    }
  };

  const handleLeaveVoicemail = async () => {
    if (deviceStatus !== DIALER_STATES.CONNECTED) {
      return;
    }
    try {
      await TwilioService.leaveVoicemail(callSid, {
        voicemail_id: dialerInfo.dial_session.voicemail_id,
      });
    } catch (e) {
      console.log("Could not leave voicemail", e);
      if (deviceStatus === DIALER_STATES.CONNECTED) {
        hangup();
      }
    }
  };

  const handleSetRecordingLegalStatus = (retry = false) => {
    if (dialerInfo.contact) {
      let phoneNumber = dialerInfo.contact.phone_numbers.find(
        pn => pn.number == callNumber
      );
      if (!phoneNumber) {
        if (!retry) {
          setTimeout(() => handleSetRecordingLegalStatus(true), 5000);
        }
        return;
      }
      setRecordingLegalStatus(phoneNumber.recording_law);
      setRecordingStatus(
        phoneNumber.recording_law == CALL_RECORDING_LAW.NO_RECORDING
          ? CALL_RECORDING_ACTIONS.DELETE
          : CALL_RECORDING_ACTIONS.START
      );
      if (phoneNumber.recording_law === CALL_RECORDING_LAW.SINGLE_PARTY) {
        setGrantedConsent(true);
      }
    }
  };
  const handleUpdateRecordingLegalStatus = value => {
    setRecordingLegalStatus(value);
  };

  const incrementCounter = (key, value = 1) => {
    setCounters(counters => ({
      ...counters,
      [key]: {
        ...counters[key],
        pending: counters[key].pending + value,
      },
    }));
  };

  const confirmCounters = () => {
    setCounters(counters =>
      Object.keys(counters).reduce(
        (prev, cur) => ({
          ...prev,
          [cur]: {
            pending: 0,
            confirmed: counters[cur].confirmed + counters[cur].pending,
          },
        }),
        {}
      )
    );
  };

  const handleNext = async () => {
    if (
      isRecordingEnabled &&
      grantedConsent == null &&
      recordingLegalStatus === CALL_RECORDING_LAW.TWO_PARTY
    ) {
      return toggleConsentModal();
    }

    try {
      setSubmitting(true);
      if (dispositionId) {
        updateStatus();
      }
      confirmCounters();
      if (dispositionId) {
        setCallAttempted(null);
        setDispositionId(null);
        setIsDispositionSet(null);
        setDialedNumbers([]);
        setCallNumber("");
        setCall(null);
      }
      await fetchNextInQueue();
      const dialSessionId = params.id;
      let nextContactExists = await CallQueueService.nextContactExists(
        dialSessionId
      );
      setNextContactExists(nextContactExists);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSkip = async () => {
    const data = {
      dial_session_id: dialerInfo.dial_session.id,
      campaign_id: dialerInfo.dial_session.campaign_id,
      contact_id: dialerInfo.contact.id,
      entity_id: dialerInfo.entity.id,
    };
    try {
      await CallQueueService.skipContact(data);
      handleNext();
    } catch (e) {}
  };

  const filterContactNumberTypes = dialerInfo => {
    const phoneNumberTypes = Object.values(
      dialerInfo.dial_session.info.phone_number_types || {}
    );
    let phoneNumbers = dialerInfo.phone_numbers_with_times_called;

    if (phoneNumberTypes.length > 0) {
      phoneNumbers = [
        ...phoneNumbers
          .filter(pn => phoneNumberTypes.includes(pn.type))
          .sort(
            (a, b) =>
              phoneNumberTypes.indexOf(a.type) -
              phoneNumberTypes.indexOf(b.type)
          ),
      ];
    }
    return phoneNumbers;
  };

  const fetchNextInQueue = async () => {
    let newDialerInfo = {};

    newDialerInfo = await CallQueueService.getNextContact(params.id);

    if (
      !newDialerInfo.id &&
      !(newDialerInfo.contact && newDialerInfo.contact.id)
    ) {
      // TODO: END OF SESSION MESSAGING
      handleCloseSession();
    } else {
      newDialerInfo.contact.enabled_phone_numbers =
        filterContactNumberTypes(newDialerInfo);
      const phone_numbers = newDialerInfo.contact.enabled_phone_numbers;
      const callback_time = newDialerInfo.callback_time;
      let phone_number = (phone_numbers || []).find(
        ({ is_primary, enabled }) => is_primary && enabled
      );
      if (!phone_number && phone_numbers && phone_numbers.length > 0) {
        phone_number = phone_numbers.find(({ enabled }) => enabled);
      }

      // Add "empty" tag if none exist
      if (newDialerInfo.contact.tags.length === 0) {
        newDialerInfo.contact.tags = [noTag];
      }
      if (newDialerInfo.entity.tags.length === 0) {
        newDialerInfo.entity.tags = [noTag];
      }

      if (phone_number) setCallNumber(phone_number.number);

      if (callback_time) {
        openScheduledCallModal();
      }
      setSession(getSession());
      setDialerInfo(newDialerInfo);
      setContact(newDialerInfo.contact);
      setGrantedConsent(null);
      setHanging(false);
      setAutoDial(!!newDialerInfo.dial_session.auto_dial);
      setDialAllNumbers(!!newDialerInfo.dial_session.dial_all_contact_numbers);
    }
  };

  const openScheduledCallModal = () => {
    setScheduledModal(true);
  };

  const fetchContactsWithSamePhone = async () => {
    const result = await ContactService.getContactsForFilters({
      phone_number: callInfo.phone_number,
    });
    setContactsWithSamePhone(result);
  };

  const refreshContactInfo = async () => {
    fetchContactsWithSamePhone();

    if (contactId) {
      fetchContactSidebarInfos();
      return;
    }

    if (!dialerInfo.id && !(dialerInfo.contact && dialerInfo.contact.id)) {
      return;
    }
    const newDialerInfo = await CallQueueService.getQueueItem(
      dialerInfo.dial_session.id,
      dialerInfo.contact.id
    );
    newDialerInfo.contact.enabled_phone_numbers =
      filterContactNumberTypes(newDialerInfo);

    // Add "empty" tag if none exist
    if (newDialerInfo.contact.tags.length === 0) {
      newDialerInfo.contact.tags = [noTag];
    }
    if (newDialerInfo.entity.tags.length === 0) {
      newDialerInfo.entity.tags = [noTag];
    }

    setDialerInfo(newDialerInfo);
    setContact(newDialerInfo.contact);
    setUpdateAuditLogFlag(updateAuditLogFlag + 1);
    if (newDialerInfo.contact.enabled_phone_numbers.length > 0) {
      setCallNumber(newDialerInfo.contact.enabled_phone_numbers[0].number);
    }
  };

  const fetchNotes = useCallback(async () => {
    if (!contact.id) return;
    const has_notes = await ActivityService.hasNotes(contact.id, "Contact");
    setHaveNotes(has_notes);
  }, [contact.id]);

  useEffect(() => {
    fetchNotes();
  }, [contact.id, fetchNotes]);

  const addContactToCall = useCallback(
    async (callSid, number) => {
      const payload = {
        agent: store.get("userAuth").email,
        To: number,
      };
      if (callerIdPhoneNumberId) {
        const [numberType, numberId] = callerIdPhoneNumberId.split("-");
        payload["caller_id_number_id"] = numberId;
        payload["caller_id_number_type"] = numberType;
      }
      try {
        const resp = await TwilioService.addToCall(callSid, payload);
        return resp;
      } catch (error) {
        hangup();
      }
    },
    [hangup, callerIdPhoneNumberId, callerIdPhoneNumberType]
  );

  const toggleHold = async () => {
    try {
      const resp = await TwilioService.updateParticipant(
        connection.parameters.CallSid,
        calleeSid,
        {
          hold: !paused,
        }
      );
      setPaused(resp.hold);
    } catch {}
  };

  const handleDirectTransfer = async phoneNumber => {
    await addContactToCall(connection.parameters.CallSid, phoneNumber);
    await TwilioService.updateParticipant(
      connection.parameters.CallSid,
      connection.parameters.CallSid,
      {
        muted: true,
      }
    );
    paused && (await toggleHold());
    hangup();
  };

  const startWarmTransfer = async phoneNumber => {
    const resp = await addContactToCall(
      connection.parameters.CallSid,
      phoneNumber
    );
    setTransferCallData({
      callSid: resp.call_sid,
      phoneNumber: phoneNumber,
    });
  };

  const cancelWarmTransfer = async () => {
    // Remove dialed transfer number from call
    await removeParticipantFromCall(
      connection.parameters.CallSid,
      transferCallData.callSid
    );
    setTransferCallData({});
  };

  const completeWarmTransfer = async () => {
    // Remove agent from call
    paused && toggleHold();
    await removeParticipantFromCall(
      connection.parameters.CallSid,
      connection.parameters.CallSid
    );
    setTransferCallData({});
  };

  const removeParticipantFromCall = async (callSid, participantCallSid) => {
    const payload = {
      participant_call_sid: participantCallSid,
    };

    try {
      const resp = await TwilioService.removeFromCall(callSid, payload);
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetRecordingStatus = async status => {
    if (!(connection && connection.parameters)) {
      return;
    }
    const callSid = callInfo.isIncomingCall
      ? callInfo.call_id
      : connection.parameters.CallSid;
    try {
      let resp = await TwilioService.setRecordingStatus(callSid, status);
      if (resp.status) {
        setRecordingStatus(resp.status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateCallerIdBasedOnCallNumber = useCallback(
    callNumber => {
      if (!phoneNumberMap || !callNumber) {
        return;
      }

      const { countryCode, areaCode } = parsePhoneNumber(callNumber);
      const matchingPhoneNumbers =
        phoneNumberMap[countryCode]?.[areaCode] || [];

      if (matchingPhoneNumbers.length > 0) {
        // Prefer dedicated Twilio numbers over non-dedicated Twilio numbers
        let twilioNumber = matchingPhoneNumbers.find(
          number => number.type === "twilio" && number.dedicated
        );
        if (twilioNumber) {
          setCallerIdPhoneNumberId(twilioNumber.value);
        } else {
          // Prefer Twilio numbers over personal numbers
          twilioNumber = matchingPhoneNumbers.find(
            number => number.type === "twilio"
          );
          if (twilioNumber) {
            setCallerIdPhoneNumberId(twilioNumber.value);
          } else {
            setCallerIdPhoneNumberId(matchingPhoneNumbers[0].value);
          }
        }
      }
    },
    [phoneNumberMap]
  );

  useEffect(() => {
    updateCallerIdBasedOnCallNumber(callNumber);
  }, [callNumber, updateCallerIdBasedOnCallNumber]);

  useEffect(() => {
    TwilioService.generateToken().then(data => {
      setToken(data.token);
    });
  }, []);

  useEffect(() => {
    if (callInfo.isIncomingCall) {
      joinConference(device);
      setCallerId(callInfo.dial_to);
      setDeviceStatus(DIALER_STATES.CONNECTED);
      setCallAttempted(true);
      fetchContactSidebarInfos();
    }
  }, [device]);

  const fetchContactSidebarInfos = async contactId => {
    if (!contactId && callInfo.contacts_with_phone?.length) {
      contactId = callInfo.contacts_with_phone[0].id;
    }

    if (contactId) {
      const contactFound = await ContactService.getContact(contactId);
      const entityFound = await EntityService.getEntity(contactFound.entity_id);

      if (contactFound.tags.length === 0) {
        contactFound.tags = [noTag];
      }
      if (entityFound.tags.length === 0) {
        entityFound.tags = [noTag];
      }

      setContact(contactFound);
      setEntity(entityFound);
      setContactId(contactId);
    }
    fetchContactsWithSamePhone();
  };
  const joinConference = async device => {
    console.log("Call accepted!");

    const user = store.get("userAuth");

    if (!user) return;

    const deviceParams = {
      To: callInfo.dial_to,
      call_id: callInfo.call_id,
      agent_id: user.id,
      dial_session_id: params.id,
      campaign_target_call_id: callInfo.campaign_target_call_id,
      enable_recording: true,
      is_incoming_call: true,
    };

    if (callInfo.contacts_with_phone?.length) {
      deviceParams.contact_id = callInfo.contacts_with_phone[0].id;
    }

    const conn = device.connect(deviceParams);
    setConnection(conn);
    setRecordingLegalStatus(true);
    setRecordingStatus(CALL_RECORDING_ACTIONS.START);
    setGrantedConsent(true);
  };

  useEffect(() => {
    if (token) {
      const device = new Device();
      device.setup(token, { debug: CONFIG.DEBUG });

      device.on("ready", function (device) {
        console.debug(DIALER_STATES.READY);
        setDevice(device);
        setDeviceStatus(DIALER_STATES.READY);
      });
      device.on("cancel", function (device) {
        console.debug("cancel");
        setDeviceStatus(DIALER_STATES.READY);
      });
      device.on("reject", () => {
        console.debug("reject");
        setDeviceStatus(DIALER_STATES.READY);
        setConnection(null);
      });
      device.on("connect", function (connection) {
        console.debug("connect");

        const isIncomingCall =
          connection.customParameters.get("is_incoming_call");
        const callSid = isIncomingCall
          ? connection.customParameters.get("call_id")
          : connection.parameters.CallSid;

        setConnection(connection);
        setCallSid(callSid);

        fetchCallInfo(callSid);
        pendingConnection.current = connection;
        setDeviceStatus(DIALER_STATES.CONNECTED);
        setPaused(false);
      });
      device.on("incoming", function (connection) {
        console.debug(DIALER_STATES.INCOMING);
        setDeviceStatus(DIALER_STATES.INCOMING);
        connection.on("reject", () => {
          setDeviceStatus(DIALER_STATES.READY);
          setConnection(null);
        });
      });
      device.on("disconnect", function (connection) {
        console.debug("disconnect");
        setDeviceStatus(DIALER_STATES.READY);
        setConnection(null);
        setRecordingStatus(null);
        setPaused(false);
        setCalleeSid(null);
      });
      device.on(DIALER_STATES.OFFLINE, function (device) {
        // reconnect device
        console.debug(DIALER_STATES.OFFLINE);
        setDeviceStatus(DIALER_STATES.OFFLINE);
        setConnection(null);
        setRecordingStatus(null);
        setPaused(false);
      });
      device.on("error", function (error) {
        // TODO: handle errors
        device.disconnectAll();
        setConnection(null);
        setRecordingStatus(null);
        if (error.code === 31009) {
          // TODO: figure out why this error is always happening
          console.warn(error.message);
        }
      });
    }
    return () => {
      device && device.destroy();
      setConnection(null);
      setDevice(null);
      setDeviceStatus(DIALER_STATES.OFFLINE);
    };
  }, [token]);

  useEffect(() => {
    if (
      deviceStatus !== "connected" ||
      paused ||
      !pendingConnection.current ||
      callInfo.isIncomingCall
    ) {
      return;
    }
    const connection = pendingConnection.current;
    pendingConnection.current = null;
    (async () => {
      const resp = await addContactToCall(
        connection.parameters.CallSid,
        connection.customParameters.get("To")
      );
      const calleeSid = resp ? resp.call_sid : null;
      setCalleeSid(calleeSid);
    })();
  }, [deviceStatus, paused, addContactToCall]);

  const fetchEmailedForms = useCallback(
    async filters => {
      filters = filters || {};
      return await ContactService.getEmails(dialerInfo.contact.id, filters);
    },
    [dialerInfo.contact]
  );

  const setDisplayName = useCallback(disposition => {
    disposition.displayName = startCase(disposition.name);
    if (
      !!disposition.child_dispositions &&
      disposition.child_dispositions.length > 0
    ) {
      disposition.child_dispositions = disposition.child_dispositions.map(
        subDisposition => setDisplayName(subDisposition)
      );
    }
    return disposition;
  }, []);

  const fetchDispositions = useCallback(
    async sessionDispositions => {
      sessionDispositions = sessionDispositions || [];
      let params = { per_page: 999 };
      if (sessionDispositions.length > 0) {
        params.id = sessionDispositions.join(",");
      }
      let resp = await CallDispositionService.getDispositions(params);

      const dispositions = resp.data.map(disposition =>
        setDisplayName(disposition)
      );
      setDispositions(dispositions);
    },
    [setDisplayName]
  );

  const parsePhoneNumber = phoneNumber => {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    if (!parsedNumber) {
      return { country_code: null, area_code: null };
    }

    const countryCode = parsedNumber.countryCallingCode;
    let areaCode = null;
    if (countryCode === "1") {
      const national_number = parsedNumber.nationalNumber;
      areaCode = national_number.toString().slice(0, 3);
    }
    return { countryCode, areaCode };
  };

  const fetchCallerIdOptions = useCallback(async () => {
    const phoneNumberOptions = await TwilioService.getCallerIdOptions();
    const phoneNumberMap = {};

    const processedOptions = phoneNumberOptions
      .map(phoneNumber => {
        const { countryCode, areaCode } = parsePhoneNumber(phoneNumber.number);
        updatePhoneNumberMap(
          phoneNumberMap,
          countryCode,
          areaCode,
          phoneNumber
        );
        return createPhoneNumberOption(phoneNumber);
      })
      .sort((a, b) => a.text.localeCompare(b.text));

    if (processedOptions.length > 0) {
      updateCallerIdState(
        processedOptions[0].value,
        processedOptions,
        phoneNumberMap
      );
    }
  }, []);

  const updatePhoneNumberMap = (map, countryCode, areaCode, phoneNumber) => {
    if (!map[countryCode]) map[countryCode] = {};
    if (!map[countryCode][areaCode]) map[countryCode][areaCode] = [];
    map[countryCode][areaCode].push({
      text: phoneNumber.number,
      key: phoneNumber.id,
      value: `${phoneNumber.type}-${phoneNumber.id}`,
      type: phoneNumber.type,
    });
  };

  const createPhoneNumberOption = phoneNumber => {
    let displayText = phoneNumber.number;
    if (phoneNumber.type === "personal") displayText += " (P)";
    if (phoneNumber.type === "twilio" && phoneNumber.dedicated)
      displayText += " (D)";

    return {
      text: displayText,
      key: phoneNumber.id,
      value: `${phoneNumber.type}-${phoneNumber.id}`,
      type: phoneNumber.type,
    };
  };

  const updateCallerIdState = (defaultValue, options, map) => {
    setCallerIdPhoneNumberId(defaultValue);
    setCallerIdPhoneNumbers(options);
    setPhoneNumberMap(map);
  };

  const fetchCallTagsForDialSession = async dialSessionId => {
    const callTags = await TagService.getTags({
      model: "CampaignTargetCall",
      dial_session_id: dialSessionId,
    });
    setCallTags(callTags);
  };

  useEffect(() => {
    const data = {
      call_sid: callSid,
      disposition_id: dispositionId,
      redial: false,
      callNumber,
    };
    if (dispositionComment) {
      data.other_comment = dispositionComment;
    }
    if (callbackDate) {
      data.callback_time = callbackDate;
    }
    if (grantedConsent) {
      data.enable_recording = grantedConsent;
    }
    store.set("currentCallData", { ...data, ...callInfo });
  }, [
    callSid,
    dispositionId,
    dispositionComment,
    callbackDate,
    grantedConsent,
  ]);

  useEffect(() => {
    if (callInfo.isIncomingCall) {
      assignContactToCampaign();
    }
  }, [campaignId]);

  const assignContactToCampaign = async () => {
    DialSessionService.editSession(dialSessionSummary?.dial_session_id, {
      campaign_id: campaignId,
    });

    await CampaignService.createCampaignTarget(campaignId, parseInt(entity.id));
    await CampaignService.addContacts(campaignId, entity.id, [contactId]);

    refreshSidebar();
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      handleCloseSession();
      onUnload();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [callInfo, onUnload, updateStatus]);

  useEffect(() => {
    (async () => {
      const session = await DialSessionService.getSession(params.id);
      const sessionDispositions = session.info && session.info.dispositions;
      fetchDispositions(sessionDispositions);
      fetchCallTagsForDialSession(params.id);
      setSession(params.id);
    })();
  }, [params.id]);

  useEffect(() => {
    getDialSessionSummary(params.id);
  }, [dialerInfo]);

  useEffect(() => {
    // Loads call info. Event sent by main page.
    const handleMessage = event => {
      if (event.origin !== window.location.origin) {
        return;
      }
      if (event.data.type === "loadCallInfo") {
        setCallInfo(event.data.callInfo);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    setPageTitle("Power Dialer");
    fetchCallerIdOptions();
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get("private") === "1") {
        setIsPrivate(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchContactSidebarInfos(contactId);
    updateCampaignTargetCall();
  }, [contactId, campaignId]);

  const updateCampaignTargetCall = async () => {
    if (callInfo.campaign_target_call_id) {
      const contactFound = await ContactService.getContact(contactId);
      CampaignTargetCallService.editCall({
        callId: callInfo.campaign_target_call_id,
        contactId,
        campaignId,
        entityId: contactFound.entity_id,
      });
    }
  };

  useEffect(() => {
    if (
      dialerInfo.dial_session &&
      dialerInfo.dial_session.script &&
      textEditor.current
    ) {
      textEditor.current.importState(
        JSON.parse(dialerInfo.dial_session.script)
      );
    }
  }, [textEditor, dialerInfo.dial_session, activeTab]);

  const assignCampaign = () => {
    assignCampaignRef.current.open();
  };

  const assignContact = async title => {
    if (contactsWithSamePhone.length) {
      assignContactRef.current.open(title, contactsWithSamePhone);
    } else {
      const allContacts = await ContactService.getContactsForFilters();
      assignContactRef.current.open(title, allContacts);
    }
  };

  const createContact = () => {
    addContactRef.current.open();
  };

  const editContact = contact => {
    editContactRef.current.open(contact);
  };

  const renderSidebar = (stateContact, stateEntity) => {
    function getContact() {
      if (callInfo.isIncomingCall) {
        return stateContact;
      }

      return dialerInfo.contact;
    }
    function getEntity() {
      if (callInfo.isIncomingCall) {
        return stateEntity;
      }

      return dialerInfo.entity || {};
    }

    const contact = getContact();
    const entity = getEntity();
    let { dial_session: { campaign_id } = {} } = dialerInfo || {};

    const canRenderSidebar =
      callInfo.isIncomingCall ||
      (!callInfo.isIncomingCall && dialerInfo && contact);

    if (!canRenderSidebar || !contact) return;

    return (
      <Sidebar
        dialer
        modelName="contacts"
        modelId={contact.id}
        campaignId={campaign_id}
        fetchModel={refreshContactInfo}
        icon="user"
        name={contact?.id ? contact.full_name : "(No Contact)"}
        refreshSidebar={refreshSidebarCounter}
        editButton={
          <CustomDropdown
            icon="ellipsis vertical"
            triggerProps={{
              className: "primary",
              style: { verticalAlign: -2, marginLeft: -3 },
            }}
          >
            <Dropdown.Menu direction="left">
              {contact?.id ? (
                <>
                  <EditContactButton onClick={() => editContact(contact)}>
                    <Icon name="pencil" />
                    Edit Contact
                  </EditContactButton>
                  <Dropdown.Item
                    onClick={() => assignContact("Switch Contact")}
                  >
                    <Icon name="sync alternative" />
                    Switch Contact
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => assignCampaign()}>
                    <Icon name="bullhorn" />
                    Assign Campaign
                  </Dropdown.Item>
                </>
              ) : (
                <>
                  <Dropdown.Item onClick={() => assignContact()}>
                    <Icon name="share" />
                    Assign Contact
                  </Dropdown.Item>
                  <AddContactButton onClick={() => createContact()}>
                    <Icon name="plus" />
                    Create Contact
                  </AddContactButton>
                </>
              )}
            </Dropdown.Menu>
          </CustomDropdown>
        }
        info={[
          {
            title: "Contact Name",
            value: contact.full_name,
          },
          {
            title: "Contact ID",
            value: contact.id,
          },
          {
            title: "Contact Email",
            value: contact.email_addresses
              ?.filter(e => e.enabled)
              .sort((a, b) => (a.is_primary ? -1 : 0))
              .map(e => (e.is_primary ? e.email + "*" : e.email)),
          },
          {
            title: "Alternate Names",
            value: contact.alternate_names?.map(an => an.name),
          },
          {
            title: "Contact Department",
            value: contact.department_name,
          },
          {
            title: "Contact Title",
            value: contact.title,
          },
          {
            title: "Contact Phone Number",
            value: contact.phone_numbers?.map(pn => {
              return (
                <PhoneDetails number={pn.number} type={pn.type} ext={pn.ext} />
              );
            }),
          },
          {
            title: "Email BCC",
            value: CONFIG.CC_EMAIL ? <CopyCCEmail /> : "Not Configured",
          },
          {
            title: "Last Voicemail",
            value:
              dialerInfo.last_voicemail == EPOCH_TIMESTAMP ? null : (
                <RuvixxDate date={dialerInfo.last_voicemail} />
              ),
          },
          {
            title: "Contact Tags",
            value: contact.tags,
            type: "tags",
            model: contact,
            modelType: "Contact",
            tags: contactTags,
          },
        ]}
        extraInfo={[
          {
            title: entity.name,
            href: `/entities/${entity.id}`,
            fields: [
              {
                title: "Entity Tags",
                value: entity.tags,
                type: "tags",
                model: entity,
                modelType: "Entity",
                tags: entityTags,
              },
              {
                title: "Website",
                value: entity.urls,
              },
              {
                title: "Account Manager",
                value: entity.account_managers?.map(ams => ams.user_full_name),
              },
            ],
          },
        ]}
        custom={contact.custom_fields}
        entityCustom={entity.custom_fields}
        handleTabChange={handleTabChange}
        contact_id={contact.id}
        entity_id={entity.id}
        contact_phone_numbers={contact.phone_numbers}
        composeEmailModal={({ trigger }) => (
          <SendEmailModal
            open
            trigger={trigger}
            contactId={contact.id || 0}
            campaignId={
              dialerInfo.dial_session &&
              dialerInfo.dial_session.campaign_id !== DEFAULT_CAMPAIGN_ID &&
              dialerInfo.dial_session.campaign_id
            }
            entityId={dialerInfo.entity && dialerInfo.entity.id}
            contactEmail={contact.email || ""}
          />
        )}
        disableEmail={!isContactEmailable(contact)}
        disableCall={!isContactCallable(contact)}
      />
    );
  };

  const handleTabChange = (
    e,
    { name, focusOnNewNote = false, auditLogId = null }
  ) => {
    setActiveTab(name);
    setFocusOnNewNote(focusOnNewNote);
    setAuditLogId(auditLogId);
  };

  const renderTabView = () => {
    if (!dialerInfo || !dialerInfo.contact) {
      return <div>No contact selected...</div>;
    }

    const contactId = dialerInfo.contact.id;

    switch (activeTab) {
      case "script":
        return (
          <div style={{ height: "600px" }}>
            <RichTextEditor ref={textEditor} readOnly />
          </div>
        );

      case "survey":
        if (dialerInfo.form_url) {
          const iframe = {
            __html: `<iframe src="${dialerInfo.form_url}" width="100%" height="100%"></iframe>`,
          };
          return (
            <div
              style={{ height: "500px", width: "75vw" }}
              dangerouslySetInnerHTML={iframe}
            />
          );
        }
        return <div>Questionnaire not set on Dial Session</div>;
      case "activityLog":
        return (
          <AuditLog
            modelId={contactId}
            modelType="Contact"
            auditLogId={auditLogId}
            updateFlag={updateAuditLogFlag}
          />
        );
      case "notes":
        return (
          <NotesView
            callSid={callSid}
            modelType="Contact"
            modelId={contactId}
            focusOnNewNote={focusOnNewNote}
            handleTabChange={handleTabChange}
          />
        );
      case "submittedForms":
        return (
          <ResponsesView
            modelId={contactId}
            modelType="Contact"
            fetchEmailedForms={fetchEmailedForms}
          />
        );
      default:
        return null;
    }
  };

  const renderTabMenu = () => {
    return (
      <Menu className="navbar" pointing secondary>
        {tabs.map(({ name, icon }) => {
          const isActive = activeTab === name;
          return (
            <Menu.Item
              key={name}
              name={name}
              active={isActive}
              onClick={handleTabChange}
            >
              <Icon name={icon} size="large" /> {isActive && startCase(name)}
              {name === "notes" && haveNotes && (
                <div className="new-notif-indicator" />
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const toggleConsentModal = (hanging = false) => {
    if (hanging) {
      setHanging(true);
    }

    setOpenConsentModal(!openConsentModal);
  };

  const isRecordingEnabled =
    callInfo.isIncomingCall ||
    (dialerInfo.dial_session && dialerInfo.dial_session.info.enable_recording);
  // make initial auto dial to contact
  useEffect(() => {
    if (lastCalledContactId === contact.id) {
      return;
    }
    if (
      autoDial &&
      !callAttempted &&
      callNumber &&
      deviceStatus === "ready" &&
      !dialedNumbers.includes(callNumber)
    ) {
      makeCall();
    }
  }, [
    autoDial,
    lastCalledContactId,
    contact.id,
    callAttempted,
    callNumber,
    deviceStatus,
    dialedNumbers,
  ]);

  // handle next call
  useEffect(() => {
    if (paused) {
      return;
    }
    const disposition = dispositions.find(({ id }) => id === dispositionId);
    const isVoicemailDispo =
      disposition && disposition.type === CALL_DISPOSITION_TYPES.VOICEMAIL;

    if (
      dispositionId &&
      isRecordingEnabled &&
      grantedConsent == null &&
      recordingLegalStatus === CALL_RECORDING_LAW.TWO_PARTY
    ) {
      if (autoDial || isVoicemailDispo) {
        toggleConsentModal();
      }
      return;
    }

    if (isVoicemailDispo && deviceStatus === DIALER_STATES.CONNECTED) {
      handleLeaveVoicemail();
      return;
    }

    if (autoDial && dispositionId) {
      let callback, nextCallType;
      if (
        dispositionId === CALL_DISPOSITION_IDS.CALLBACK ||
        disposition.type === "connected" ||
        !dialAllNumbers
      ) {
        callback = handleNext;
        nextCallType = "contact";
      } else if (dialAllNumbers) {
        if (!dialedNumbers.includes(callNumber)) {
          nextCallType = "number";
          callback = makeCall;
        } else {
          const nextNumber = contact.enabled_phone_numbers.find(
            pn => pn.enabled && !dialedNumbers.includes(pn.number)
          );
          if (nextNumber) {
            setCallNumber(nextNumber.number);
            return;
          } else {
            nextCallType = "contact";
            callback = handleNext;
          }
        }
      }

      if (callback && nextCallType) {
        if (
          disposition &&
          disposition.type === CALL_DISPOSITION_TYPES.VOICEMAIL &&
          deviceStatus !== DIALER_STATES.READY
        ) {
          // Don't start countdown on voicemail until call transfers
          return;
        }

        if (
          dispositionId === CALL_DISPOSITION_IDS.CALLBACK ||
          (disposition && disposition.type !== CALL_DISPOSITION_TYPES.VOICEMAIL)
        ) {
          // Auto hang up on any non voicemail disposition
          hangup();
        }

        setNextCallCountdown(
          <Countdown
            date={Date.now() + (nextCallType === "number" ? 3000 : 5000)}
            ref={countdownApi}
            renderer={({ seconds, completed }) => {
              if (completed) {
                setNextCallCountdown(null);
                return null;
              }
              return (
                <div
                  style={{
                    marginTop: "2px",
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>
                    Calling next {nextCallType} in {seconds} seconds
                  </div>
                </div>
              );
            }}
            onComplete={callback}
          />
        );
      }
    }
  }, [
    autoDial,
    callNumber,
    dispositionId,
    paused,
    dialedNumbers,
    grantedConsent,
    deviceStatus,
  ]);

  const onPauseSession = paused => {
    if (paused) {
      countdownApi.current && countdownApi.current.pause();
    } else {
      countdownApi.current && countdownApi.current.start();
    }
    setPaused(paused);
  };

  const handleSetDispositionId = dispositionId => {
    setDispositionId(dispositionId);
  };

  return (
    <Container fluid className="route">
      <AssignCampaignModal
        ref={assignCampaignRef}
        updateCampaignId={setCampaignId}
      />
      <AssignContactModal
        ref={assignContactRef}
        updateContactId={setContactId}
        phoneNumber={callInfo.phone_number}
      />
      <AddContactModal
        ref={addContactRef}
        noTrigger
        onSuccess={({ id }) => setContactId(id)}
        phoneNumber={callInfo.phone_number}
      />
      <EditContactModal
        ref={editContactRef}
        noTrigger
        onSuccess={refreshContactInfo}
      />
      <DialerHeader
        call={call}
        callTags={callTags}
        onTagChange={() => fetchCallInfo(callSid)}
        deviceStatus={deviceStatus}
        dialerInfo={dialerInfo}
        counters={counters}
        onPauseSession={onPauseSession}
        paused={paused}
        onEndSession={handleCloseSession}
        toggleHold={toggleHold}
        privateSession={isPrivate}
        dialSessionSummary={dialSessionSummary}
        dialSessionId={params.id}
        autoDial={autoDial}
        nextCallCountdown={nextCallCountdown}
      />
      <div className="dialer-ui">
        {renderSidebar(contact, entity)}
        <div className="main">
          {!callInfo.isIncomingCall &&
          !dialerInfo.id &&
          !(dialerInfo.contact && dialerInfo.contact.id) ? (
            <p>Please click 'Next' below to begin</p>
          ) : (
            <>
              <DialerInfo
                deviceStatus={deviceStatus}
                autoDial={autoDial}
                callerId={callerId}
                contact={contact}
                callNumber={callNumber}
                makeCall={makeCall}
                hangup={hangup}
                paused={paused}
                setCallNumber={setCallNumber}
                callerIdPhoneNumbers={callerIdPhoneNumbers}
                callerIdPhoneNumberId={callerIdPhoneNumberId}
                setCallerIdPhoneNumberId={setCallerIdPhoneNumberId}
                callerIdPhoneNumberType={callerIdPhoneNumberType}
                setCallerIdPhoneNumberType={setCallerIdPhoneNumberType}
                callAttempted={callAttempted}
                dispositionId={dispositionId}
                recordingStatus={recordingStatus}
                isTwoPartyConsent={
                  recordingLegalStatus === CALL_RECORDING_LAW.TWO_PARTY
                }
                setConsentModal={toggleConsentModal}
                grantedConsent={grantedConsent}
                handleSetRecordingStatus={handleSetRecordingStatus}
                isRecordingEnabled={isRecordingEnabled}
                numCalls={dialerInfo.num_calls}
                device={device}
                callInfo={callInfo}
              />
              {!callInfo.isIncomingCall && (
                <>
                  {renderTabMenu()}
                  {renderTabView()}
                  <ScheduledCallModal
                    setScheduledModal={setScheduledModal}
                    scheduledModal={scheduledModal}
                    dialerInfo={dialerInfo}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
      <DialerFooter
        autoDial={autoDial}
        connection={connection}
        contact_id={(dialerInfo.contact && dialerInfo.contact.id) || contactId}
        entity_id={(dialerInfo.entity && dialerInfo.entity.id) || entity?.id}
        campaign_id={
          (dialerInfo.dial_session && dialerInfo.dial_session.campaign_id) ||
          campaignId
        }
        dialSessionId={
          (dialerInfo.dial_session && dialerInfo.dial_session.id) ||
          dialSessionSummary.dial_session_id
        }
        deviceStatus={deviceStatus}
        addContactToQueue={addContactToQueue}
        dispositions={dispositions}
        dispositionId={dispositionId}
        onSetDispositionId={handleSetDispositionId}
        dispositionComment={dispositionComment}
        setDispositionComment={setDispositionComment}
        handleNext={handleNext}
        handleCloseSession={handleCloseSession}
        handleSkip={handleSkip}
        submitting={submitting}
        startedSession={!!(dialerInfo.contact && dialerInfo.contact.id)}
        callAttempted={callAttempted}
        onDirectTransfer={handleDirectTransfer}
        startWarmTransfer={startWarmTransfer}
        cancelWarmTransfer={cancelWarmTransfer}
        completeWarmTransfer={completeWarmTransfer}
        transferCallData={transferCallData}
        paused={paused}
        setCallbackDate={setCallbackDate}
        timezone={dialerInfo.timezone}
        nextContactExists={nextContactExists}
        privateSession={isPrivate}
        grantedConsent={grantedConsent}
        isTwoPartyConsent={
          recordingLegalStatus === CALL_RECORDING_LAW.TWO_PARTY
        }
        toggleConsentModal={toggleConsentModal}
        isRecordingEnabled={isRecordingEnabled}
        numCalls={dialerInfo.num_calls}
        assignToSelf={assignToSelf}
        setAssignToSelf={setAssignToSelf}
        isIncomingCall={callInfo.isIncomingCall}
      />
      {isRecordingEnabled && (
        <RecordingConsentModal
          open={
            openConsentModal && dialerInfo.dial_session.info.enable_recording
          }
          handleStopRecording={() => {
            handleSetRecordingStatus(CALL_RECORDING_ACTIONS.DELETE);
          }}
          handleUpdateRecordingLegalStatus={handleUpdateRecordingLegalStatus}
          setGrantedConsent={v => setGrantedConsent(v)}
          toggleConsentModal={toggleConsentModal}
          hanging={hanging}
          hangup={hangup}
        />
      )}
      {preventClosingModal && (
        <PreventClosingModal
          open={preventClosingModal}
          setModal={setPreventClosingModal}
        />
      )}
    </Container>
  );
}

export default PowerDialer;
