import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Divider, Icon, Menu } from "semantic-ui-react";
import { formatDate } from "../../components/helpers";

import Sidebar from "../../components/Sidebar";
import AuditLog from "../../components/views/AuditLog";
import NotesView from "../../components/views/NotesView";
import { CASE_TIERS } from "../../constants/Case";
import { generateTabs } from "../../constants/Constants";
import { noTag } from "../../constants/Tag";
import { formatAddress } from "../../helpers/address";
import setPageTitle from "../../helpers/title";
import useRouteParam from "../../hooks/params/useRouteParam";
import AuthService from "../../services/Auth";
import CaseService from "../../services/Case";
import EntityService from "../../services/Entity";
import TagService from "../../services/Tag";
import CaseEventsTable from "../case_event/CaseEventsTable";
import EntityContactsTable from "../entity/components/ContactsTable";
import MachinesTable from "../machine/MachinesTable";
import CaseFormModal from "./CaseFormModal";
import ActivityService from "../../services/Activity";
import RevenueOpportunityTable from "components/RevenueOpportunityTable";

const CaseDetail = () => {
  const [caseTags, setCaseTags] = useState([]);
  const [entityTags, setEntityTags] = useState([]);
  const [entityCustomFields, setEntityCustomFields] = useState([]);
  const [focusOnNewNote, setFocusOnNewNote] = useState(false);
  const [model, setModel] = useState({});
  const [entity, setEntity] = useState(null);
  const [updateAuditLogFlag, setUpdateAuditLogFlag] = useState(0);
  const [tabs, setTabs] = useState(
    generateTabs(
      "activityLog",
      "machines",
      "events",
      "notes",
      "revenueOpportunities"
    )
  );
  const [activeTab, setActiveTab] = useRouteParam("tab", tabs[0].name);
  const [modelId] = useRouteParam("id");
  const [refreshMachinesTable, setRefreshMachinesTable] = useState(false);
  const [hasNotes, setHasNotes] = useState(false);

  const fetchNotes = useCallback(async () => {
    if (!modelId) return;
    const result = await ActivityService.hasNotes(modelId, "Case");
    setHasNotes(result);
  }, [modelId]);

  useEffect(() => {
    if (AuthService.isLoggedIn() && modelId) {
      fetchModel(); // don't await
      fetchTags();
      fetchNotes();
    }
  }, [modelId]);

  const fetchEntity = async entity_id => {
    const entity = await EntityService.getEntity(entity_id);
    if (entity.tags.length === 0) {
      entity.tags = [noTag];
    }
    setEntity(entity);
    setEntityCustomFields(entity.custom_fields);
  };

  const fetchModel = async () => {
    let model = await CaseService.get(modelId);
    if (model.entity_id) fetchEntity(model.entity_id);
    if (model.tags.length === 0) {
      model.tags = [noTag];
    }
    if (model.entity_id) {
      setTabs(
        generateTabs(
          "activityLog",
          "machines",
          "events",
          "contacts",
          "notes",
          "revenueOpportunities"
        )
      );
    }

    setModel(model);
    setPageTitle(model.name);
    setRefreshMachinesTable(prevState => !prevState);
    setUpdateAuditLogFlag(updateAuditLogFlag + 1);
  };

  const handleTabChange = (
    e,
    { name, focusOnNewNote = false, auditLogId = null }
  ) => {
    setFocusOnNewNote(focusOnNewNote);
    // setAuditLogId(auditLogId);
    setActiveTab(name);
  };

  const renderTabView = useMemo(() => {
    if (!modelId || isEmpty(model)) {
      return null;
    }
    switch (activeTab) {
      case "activityLog":
        return (
          <AuditLog
            modelId={modelId}
            modelType="Case"
            updateFlag={updateAuditLogFlag}
            // auditLogId={auditLogId}
          />
        );
      case "machines":
        return (
          <MachinesTable
            caseRouteId={model.id}
            caseId={model.case_id}
            handleTabChange={handleTabChange}
            onUpdate={fetchModel}
            refresh={refreshMachinesTable}
          />
        );
      case "events":
        return <CaseEventsTable caseId={model.case_id} />;
      case "contacts":
        if (model.entity_id) {
          return <EntityContactsTable entityId={model.entity_id} />;
        } else {
          return null;
        }
      case "notes":
        return (
          <NotesView
            modelType="Case"
            modelId={modelId}
            focusOnNewNote={focusOnNewNote}
            handleTabChange={handleTabChange}
          />
        );
      case "revenueOpportunities":
        return (
          <RevenueOpportunityTable
            caseId={model.id}
            entityId={model.entity_id}
          />
        );
      default:
        return null;
    }
  }, [
    activeTab,
    modelId,
    model,
    // auditLogId,
    focusOnNewNote,
    updateAuditLogFlag,
  ]);

  const fetchTags = async () => {
    const caseTags = await TagService.getTags({ model: "case" });
    const entityTags = await TagService.getTags({ model: "entity" });
    setCaseTags(caseTags);
    setEntityTags(entityTags);
  };

  const onTagUpdate = async () => {
    fetchTags();
    fetchModel();
  };

  const entityDetails = useCallback(() => {
    return {
      title: entity.name,
      href: `/entities/${entity.id}`,
      fields: [
        {
          title: "Entity Tags",
          value: entity.tags,
          type: "tags",
          model: entity,
          modelType: "Entity",
          tags: entityTags,
        },
        {
          title: "Website",
          value: entity.url,
        },
        {
          title: "Account Manager",
          value: entity.account_managers.map(ams => ams.user_full_name),
        },
        {
          title: "Addresses",
          value: entity.addresses?.map(address => formatAddress(address)),
        },
      ],
    };
  }, [entity]);

  const renderTabMenu = () => (
    <Menu className="navbar icon-only" pointing secondary>
      {tabs.map(({ name, icon }) => {
        const isActive = activeTab === name;
        return (
          <Menu.Item
            key={name}
            name={name}
            active={isActive}
            onClick={handleTabChange}
          >
            <Icon name={icon} size="large" /> {isActive && startCase(name)}
            {name === "notes" && hasNotes && !isActive && (
              <div className="new-notif-indicator-contact" />
            )}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Container fluid className="route divided">
      <Sidebar
        modelName="cases"
        modelId={modelId}
        fetchModel={fetchModel}
        icon="folder open"
        name={model.name}
        editButton={
          <CaseFormModal
            caseId={modelId}
            fetchData={fetchModel}
            iconTrigger={true}
          />
        }
        entity_id={model.entity_id}
        info={[
          {
            title: "Case ID",
            value: model.case_id,
          },
          {
            title: "Case Tier",
            value: Object.keys(CASE_TIERS).find(
              key => CASE_TIERS[key] === model.case_tier
            ),
          },
          {
            title: "Investigation Status",
            value: model.investigation_status?.name,
          },
          {
            title: "Case Investigators",
            value: (model.case_investigators ?? []).map(({ name }) => name),
          },
          {
            title: "Website",
            value: model.website,
          },
          {
            title: "Industry",
            value: model.industry,
          },
          {
            title: "Company Description",
            value: model.company_description,
          },
          {
            title: "Addresses",
            value: model.addresses?.map(address => formatAddress(address)),
          },
          {
            title: "Actionable Domain",
            value: model.actionable_domain,
          },
          {
            title: "Region",
            value: model.region_name,
          },
          {
            title: "Source Status",
            value: model.source_status_name,
          },
          {
            value: model.tags,
            type: "tags",
            model,
            modelType: "Case",
            tags: caseTags,
          },
        ]}
        custom={model.custom_fields}
        entityCustom={entityCustomFields}
        extraInfo={[
          {
            title: "Case Summary",
            fields: [
              {
                title: "Number of Machines",
                value: model.total_num_machines,
              },
              {
                title: "Number of Recent Machines",
                value: model.recent_num_machines,
              },
              {
                title: "Number of Approved Machines",
                value: model.approved_num_machines,
              },
              {
                title: "Data Score",
                value: model.data_score,
              },
              {
                title: "First Event",
                value: formatDate(model.first_event),
              },
              {
                title: "Last Event",
                value: formatDate(model.last_event),
              },
              {
                title: "Time Span",
                value: model.time_span,
              },
              {
                title: "Created",
                value: formatDate(model.created_at),
              },
              {
                title: "Updated",
                value: formatDate(model.updated_at),
              },
            ],
          },
          ...(entity ? [entityDetails()] : []),
        ]}
        handleTabChange={handleTabChange}
      />
      <div className="main">
        {renderTabMenu()}
        {renderTabView}
        <Divider hidden />
      </div>
    </Container>
  );
};

export default CaseDetail;
