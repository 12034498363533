import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import React from "react";
import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import "react-table-hoc-fixed-columns/lib/styles.css";
import { Dropdown, Icon } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import BaseTable from "../../components/BaseTable";
import CustomDropdown from "../../components/CustomDropdown";
import {
  createDateTimeFormatter,
  getPercentage,
} from "../../components/helpers";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import { EMAIL_SERVICES } from "../../constants/Constants";
import AuthService from "../../services/Auth";
import CampaignScheduleService from "../../services/CampaignScheduleService";
import ConstantContactCampaignsService from "../../services/ConstantContactCampaigns";
import "../../styles/datetime.scss";
import { DeleteScheduleModal as _DeleteScheduleModal } from "./components/Modals";

import _ScheduleModal from "./components/ScheduleModal";

const ScheduleModal = withRoleCheck(_ScheduleModal, [
  ACL_RELATIONSHIPS.campaignSchedule.create,
]);
const DeleteScheduleModal = withRoleCheck(_DeleteScheduleModal, [
  ACL_RELATIONSHIPS.campaignSchedule.delete,
]);

class EmailSchedulesTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CampaignScheduleService.getSchedules;
    this.queryArgs = [this.props.campaignId];

    this.state = {
      ...this.state,
      tableName: "email_schedules",
      email_schedules: [],
      noDataText: "No Schedules Found. Try adjusting your filters.",
      enableSearch: false,
      syncing: [],
      createButton: (
        <ScheduleModal
          isClone={false}
          campaignId={parseInt(this.props.campaignId)}
          fetchData={this.fetchData}
        />
      ),
      emailServices: [],
    };
  }

  fetchEmailServices = async () => {
    const emailServices = Object.keys(EMAIL_SERVICES).map(key => ({
      key: EMAIL_SERVICES[key],
      value: EMAIL_SERVICES[key],
      text: startCase(key.toLowerCase()),
    }));
    this.setState({ emailServices });
  };

  async componentDidMount() {
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchEmailServices();
      this.checkSyncingStatus();
    }
  }

  componentWillUnmount() {
    this.syncingTimeout = false;
  }

  checkSyncingStatus = async () => {
    const { campaignId } = this.props;
    const { pageIndex, pageSize, syncing, rows } = this.state;
    let schedules = [];
    if (campaignId) {
      const response = await CampaignScheduleService.getSchedules(campaignId, {
        page: pageIndex + 1,
        per_page: pageSize,
      });
      schedules = response ? response.data : [];
    }
    const syncingSchedules = schedules.filter(
      ({ info }) => info && info.syncing
    );
    this.syncingTimeout = syncingSchedules.length > 0;
    const newSyncing = syncingSchedules.map(({ id }) => id);
    if (!isEqual(newSyncing, syncing)) {
      const finishedSyncing = syncing.filter(id => !newSyncing.includes(id));
      const newRows =
        finishedSyncing.length > 0
          ? rows.map(row => {
              if (finishedSyncing.includes(row.id)) {
                row.info.last_synced_at = schedules.find(
                  ({ id }) => id === row.id
                ).info.last_synced_at;
              }
              return row;
            })
          : rows;
      this.setState({ syncing: newSyncing, rows: newRows });
    }
    if (this.syncingTimeout) {
      setTimeout(this.checkSyncingStatus, 5000);
    }
  };

  async deleteSchedule(campaignId, scheduleId) {
    await CampaignScheduleService.deleteSchedule(campaignId, scheduleId);
    this.fetchData();
  }

  handleSyncData = async (scheduleId, templateOnly, contactsOnly) => {
    this.setState({ syncing: [...this.state.syncing, scheduleId] });
    await ConstantContactCampaignsService.syncSchedule(
      scheduleId,
      templateOnly,
      contactsOnly
    );
    if (!this.syncingTimeout) {
      this.syncingTimeout = true;
      setTimeout(this.checkSyncingStatus, 5000);
    }
  };

  setColumns = () => {
    const formatDate = tz => createDateTimeFormatter({ timeZone: tz });
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Name",
        accessor: "schedule_name",
      },
      {
        Header: "Status",
        accessor: "status",
        className: "centered",
        Cell: ({ original: { email_service, status } }) =>
          email_service === EMAIL_SERVICES["CONSTANT_CONTACT"] ? (
            "N/A"
          ) : (
            <Icon name={status ? "check" : "close"} />
          ),
      },
      {
        Header: "Provider",
        accessor: "email_service",
        Cell: props => (
          <p>
            {this.state.emailServices.find(p => p.value === props.value).text}
          </p>
        ),
      },
      {
        Header: "Delivered Time & Date",
        minWidth: 160,
        Cell: ({
          original: { email_service, email_delivery_time, time_zone },
        }) =>
          email_service === EMAIL_SERVICES["CONSTANT_CONTACT"]
            ? "N/A"
            : formatDate(time_zone)(email_delivery_time),
      },
      {
        Header: "Template",
        accessor: "email_template_name",
      },
      {
        Header: "From Name",
        accessor: "sender_name",
      },
      {
        Header: "From Address",
        accessor: "info.from_address",
      },
      {
        Header: "CC",
        accessor: "info.cc",
        sortable: false,
      },
      {
        Header: "BCC",
        accessor: "info.bcc",
        sortable: false,
      },
      {
        Header: "# Sent",
        accessor: "metrics.sent",
      },
      {
        Header: "# Delivered",
        accessor: "metrics.delivered",
      },
      {
        Header: "Bounce Rate",
        accessor: "metrics.bounce_rate",
        Cell: props => {
          const metrics = props.original.metrics;
          if (metrics.delivered !== undefined) {
            return (
              <div style={{ textAlign: "center" }}>
                {metrics.sent === 0
                  ? "0.0%"
                  : getPercentage(metrics.bounced, metrics.sent)}
              </div>
            );
          }
        },
      },
      {
        Header: "Opened",
        accessor: "metrics.unique_opened",
      },
      {
        Header: "Clicks",
        accessor: "metrics.unique_clicked",
      },
      {
        Header: "Open Rate",
        accessor: "metrics.open_rate",
        Cell: props => {
          const metrics = props.original.metrics;
          if (metrics.unique_opened !== undefined) {
            return (
              <div style={{ textAlign: "center" }}>
                {getPercentage(metrics.unique_opened, metrics.delivered)}
              </div>
            );
          }
        },
      },
      {
        Header: "Click Rate",
        accessor: "metrics.click_rate",
        Cell: props => {
          const metrics = props.original.metrics;
          if (metrics.unique_clicked !== undefined) {
            return (
              <div style={{ textAlign: "center" }}>
                {getPercentage(metrics.unique_clicked, metrics.unique_opened)}
              </div>
            );
          }
        },
      },
      {
        Header: "Unsubscribed",
        accessor: "metrics.unsubscribed",
      },
      {
        Header: "Last Synced At",
        accessor: "info.last_synced_at",
        minWidth: 160,
        Cell: ({ value, original: { id, time_zone } }) =>
          this.state.syncing.includes(id)
            ? "Syncing..."
            : formatDate(time_zone)(value),
      },
      {
        Header: "CC AM",
        accessor: "info.cc_account_managers",
        sortable: false,
        width: 60,
        className: "centered",
        Cell: ({ value }) => <Icon name={value ? "check" : "close"} />,
      },
      {
        Header: "CC Ext",
        accessor: "info.cc_external_account_managers",
        sortable: false,
        width: 60,
        className: "centered",
        Cell: ({ value }) => <Icon name={value ? "check" : "close"} />,
      },
      {
        Header: "Actions",
        id: "actions",
        minWidth: 60,
        sortable: false,
        headerClassName: "non-sortable",
        className: "action-menu",
        Cell: ({ original: { id, email_service, info } }) =>
          id ? (
            <CustomDropdown icon="ellipsis horizontal">
              <Dropdown.Menu direction="left">
                <ScheduleModal
                  campaignId={parseInt(this.props.campaignId)}
                  newSchedule={false}
                  scheduleId={id}
                  fetchData={this.fetchData}
                  isClone={false}
                />
                <ScheduleModal
                  campaignId={parseInt(this.props.campaignId)}
                  newSchedule={false}
                  scheduleId={id}
                  fetchData={this.fetchData}
                  isClone={true}
                />
                <DeleteScheduleModal
                  scheduleId={id}
                  campaignId={this.props.campaignId}
                  menuTrigger
                  onConfirmDelete={() =>
                    this.deleteSchedule(this.props.campaignId, id)
                  }
                />
                {email_service === EMAIL_SERVICES.CONSTANT_CONTACT ? (
                  <>
                    <Dropdown.Item
                      content="Sync All"
                      onClick={() => this.handleSyncData(id, false, false)}
                      icon="sync"
                    />
                    <Dropdown.Item
                      content="Sync Template"
                      onClick={() => this.handleSyncData(id, true, false)}
                      icon="sync"
                    />
                    <Dropdown.Item
                      disabled={!(info && info.cc_campaign_activity_id)}
                      content="Sync Contacts"
                      onClick={() => {
                        this.handleSyncData(id, false, true);
                      }}
                      icon="sync"
                    />
                  </>
                ) : null}
              </Dropdown.Menu>
            </CustomDropdown>
          ) : null,
      },
    ];
    this.initTableSettings(columns);
  };
}

export default EmailSchedulesTable;
