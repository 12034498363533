import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  Message,
  Accordion,
  Checkbox,
  Form,
} from "semantic-ui-react";
import PermissionsTable from "./PermissionsTable";
import RoleService from "../../../services/Role";
import PermissionService from "../../../services/Permission";
import Constants from "../../../constants/Constants";
import { startCase } from "../../../helpers/string";
import RuvixxForm from "../../../components/RuvixxForm";
import "./RoleForm.scss";

const RoleForm = ({
  role = { permissions: [] },
  onSuccess,
  copyMode,
  onClose,
}) => {
  const [name, setName] = useState(
    (copyMode ? "Copy of " : "") + (role.name || "")
  );
  const [formCategories, setFormCategories] = useState([]);
  const [categoryPermissions, setCategoryPermissions] = useState({});
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeCategories, setActiveCategories] = useState([]);

  const methods = {
    post: "Create",
    get: "Read",
    put: "Update",
    delete: "Delete",
  };

  useEffect(() => {
    (async () => {
      const permissions = await PermissionService.getPermissions();
      const permissionCategories =
        await PermissionService.getPermissionCategories();

      if (!permissions) {
        return;
      }

      const categoryEndpoints = Object.entries(permissions).reduce(
        (obj, [path, pathMethods]) => {
          const categories = Object.entries(permissionCategories)
            .filter(
              ([cat, catPaths]) =>
                !!catPaths.find(p =>
                  path
                    .replace(/{\w+_id}/gi, 1)
                    .replace(/{\w+}/gi, "str")
                    .match(RegExp(p))
                )
            )
            .map(([cat, _]) => cat);

          const pathPermissions = Object.entries(pathMethods)
            .filter(([method, _]) => Constants.HTTP_METHODS.includes(method))
            .map(([method, { operationId: description }]) => ({
              path,
              method,
              description,
              is_allowed: !!(role.permissions || []).find(
                permission =>
                  (path
                    .replace(/{\w+_id}/gi, 1)
                    .replace(/{\w+}/gi, "str")
                    .match(RegExp(permission.path)) ||
                    categories.includes(permission.path.replace("@", ""))) &&
                  permission.method === method
              ),
            }));

          const category = categories?.[0];
          if (category) {
            obj[category] = [...(obj[category] || []), ...pathPermissions];
          }

          return obj;
        },
        {}
      );

      setCategoryPermissions(categoryEndpoints);

      setFormCategories(
        Object.entries(categoryEndpoints)
          .filter(
            ([category, permissions]) => !!permissions.find(p => p.is_allowed)
          )
          .map(([category, _]) => category)
      );

      setLoadingPermissions(false);
    })();
  }, []);

  useEffect(() => {
    Object.entries(categoryPermissions).forEach(([category, permissions]) => {
      if (
        !formCategories.includes(category) &&
        !!permissions.find(p => p.is_allowed)
      ) {
        handleSetPermissions(
          [...permissions.map(p => ({ ...p, is_allowed: false }))],
          category
        );
      }
    });
  }, [formCategories]);

  const handleSelectCategory = (_, { value: category }) => {
    const newFormCategories = [...formCategories, category];
    setFormCategories(newFormCategories);
  };

  const handleRemoveCategory = (e, category) => {
    e.stopPropagation();
    let _formCategories = [...formCategories];
    _formCategories = _formCategories.filter(c => c !== category);
    setFormCategories(_formCategories);
    handleSetPermissions(
      categoryPermissions[category].map(p => ({ ...p, is_allowed: false })),
      category
    );
  };

  const handleToggleCategoryMethod = (
    e,
    { name: category, value: method, checked }
  ) => {
    e.stopPropagation();
    let newPermissions = categoryPermissions[category].map(p =>
      p.method === method ? { ...p, is_allowed: checked } : p
    );
    handleSetPermissions(newPermissions, category);
  };

  const handleSetPermissions = (newPermissions, category) => {
    let _categoryPermissions = { ...categoryPermissions };
    _categoryPermissions[category] = newPermissions;
    setCategoryPermissions(_categoryPermissions);
  };

  const handleSubmit = async () => {
    setErrorMessage(null);

    const newPerms = Object.entries(categoryPermissions).reduce(
      (obj, [category, permissions]) => {
        Object.keys(methods).forEach(method => {
          const methodPermissions = permissions.filter(
            p => p.method === method
          );
          const allChecked =
            methodPermissions.length > 0 &&
            methodPermissions.every(p => p.is_allowed);
          if (allChecked) {
            obj = [...obj, { method, path: `@${category}`, is_allowed: true }];
          } else {
            obj = [
              ...obj,
              ...methodPermissions
                .filter(p => p.is_allowed)
                .map(({ method, path, is_allowed }) => ({
                  method,
                  path:
                    "^" +
                    path
                      .replace(/{\w+_id}/gi, "\\d+")
                      .replace(/{\w+}/gi, "\\w+")
                      .replace(/\/$/, "") +
                    "/?$",
                  is_allowed,
                })),
            ];
          }
        });

        return obj;
      },
      []
    );

    try {
      if ("id" in role && !copyMode) {
        await RoleService.updateRole(role.id, name, newPerms);
      } else {
        await RoleService.createRole(name, newPerms);
      }
      onSuccess();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleCategoryToggled = (_, { active, index }) => {
    let newActiveCategories;
    if (active) {
      newActiveCategories = activeCategories.filter(cat => cat !== index);
    } else {
      newActiveCategories = [...activeCategories, index];
    }
    setActiveCategories(newActiveCategories);
  };

  const handleExpandCollapseAll = () => {
    let newActiveCategories;
    if (activeCategories.length > 0) {
      // Collapse All
      newActiveCategories = [];
    } else {
      // Expand All
      newActiveCategories = [...Array(accordionPanels.length).keys()];
    }
    setActiveCategories(newActiveCategories);
  };

  const accordionPanels = formCategories.sort().map((category, index) => {
    return {
      key: category,
      title: {
        className: "title-container",
        content: (
          <React.Fragment key={index} className="permission-container">
            <div className="permission-category-title">
              {startCase(category.replace(/_/g, " "))}
            </div>
            <div className="permission-category-buttons">
              {Object.keys(methods).map(method => {
                const _permissions = categoryPermissions[category].filter(
                  p => p.method === method
                );
                const allChecked =
                  _permissions.length > 0 &&
                  _permissions.every(p => p.is_allowed);
                const someChecked = _permissions.some(p => p.is_allowed);

                return (
                  <Checkbox
                    label={methods[method]}
                    name={category}
                    value={method}
                    onChange={handleToggleCategoryMethod}
                    checked={allChecked}
                    indeterminate={someChecked && !allChecked}
                    key={method}
                    disabled={!_permissions.length}
                  />
                );
              })}
              <Icon
                name="close"
                onClick={e => handleRemoveCategory(e, category)}
              />
            </div>
          </React.Fragment>
        ),
      },
      content: {
        content: (
          <PermissionsTable
            permissions={categoryPermissions[category]}
            onSetPermissions={newPermissions =>
              handleSetPermissions(newPermissions, category)
            }
            loading={loadingPermissions}
          />
        ),
      },
    };
  });

  return (
    <RuvixxForm
      ready={
        !!name &&
        !!Object.values(categoryPermissions)
          .flat()
          .some(p => p.is_allowed)
      }
      submitButtonText="Save"
      cancelButtonText="Cancel"
      onSubmit={handleSubmit}
      onCancel={onClose}
    >
      <Message error hidden={!errorMessage} content={errorMessage} />
      <Form.Input
        inline
        label="Name"
        size="small"
        value={name}
        onChange={(_, { value }) => setName(value)}
      />
      <Form.Select
        inline
        search
        options={Object.keys(categoryPermissions)
          .sort()
          .filter(category => !formCategories.includes(category))
          .map((category, index) => ({
            text: startCase(category.replace(/_/g, " ")),
            key: index,
            value: category,
          }))}
        value={null}
        onChange={handleSelectCategory}
        label="Permission Category"
      />
      <div className="filter-buttons">
        {Object.keys(categoryPermissions)
          .sort()
          .filter(category => formCategories.includes(category))
          .map((category, index) => (
            <Button
              key={index}
              basic
              size="mini"
              icon="close"
              content={startCase(category.replace(/_/g, " "))}
              onClick={e => handleRemoveCategory(e, category)}
            />
          ))}
      </div>
      {accordionPanels.length > 0 && (
        <div>
          <Button
            className="expand-collapse-button"
            basic
            content={`${
              activeCategories.length > 0 ? "Collapse" : "Expand"
            } All`}
            icon={`chevron ${activeCategories.length > 0 ? "up" : "down"}`}
            size="tiny"
            onClick={handleExpandCollapseAll}
          />
          <Accordion
            panels={accordionPanels}
            className="permission-categories-accordion"
            exclusive={false}
            onTitleClick={handleCategoryToggled}
            activeIndex={activeCategories}
          />
        </div>
      )}
    </RuvixxForm>
  );
};

export default RoleForm;
