import React, { cloneElement, isValidElement, useState } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import Datetime from "react-datetime";
import isString from "lodash/isString";

import RuvixxForm from "components/RuvixxForm";
import RevenueOpportunityService from "services/RevenueOpportunities";
import RevenueOpportunityStatusPresetService from "services/RevenueOpportunityStatusPreset";
import CampaignService from "services/Campaign";

const isValidRevenueOpportunityStatus = statusName => {
  return isString(statusName);
};

const RevenueOpportunityStatusModal = ({
  revenueOpportunityId,
  statusId,
  statusName,
  effectiveDate,
  updateData,
  trigger,
  disabled,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [formData, setFormData] = useState({
    status_id: statusId,
    effective_date: effectiveDate,
  });

  const fetchStatuses = async () => {
    let statuses = null;

    const revenueOpportunity =
      await RevenueOpportunityService.getRevenueOpportunityById(
        revenueOpportunityId
      );
    const campaignId = revenueOpportunity.campaign_id;

    if (campaignId) {
      const campaign = await CampaignService.getCampaign(campaignId);
      const presetId = campaign.info?.revenue_status_preset;

      if (presetId) {
        const preset =
          await RevenueOpportunityStatusPresetService.getRevenueOpportunityStatusPreset(
            presetId
          );
        statuses = preset.info.revenue_opportunity_statuses;
      }
    }

    if (statuses === null) {
      statuses =
        await RevenueOpportunityService.getRevenueOpportunityStatusesForFilters();
    }

    setStatuses(statuses);
  };

  const handleSubmit = async () => {
    formData["revenue_opportunity_id"] = revenueOpportunityId;
    if (
      statusId !== formData.status_id ||
      effectiveDate !== formData.effective_date
    ) {
      await RevenueOpportunityService.setRevenueOpportunityStatus(formData);
    }
    handleClose();
  };

  const handleOpen = () => {
    setModalOpen(true);
    fetchStatuses();
  };

  const handleClose = () => {
    setModalOpen(false);
    updateData();
  };

  const handleDateChange = moment => {
    let effectiveDate;
    if (typeof moment === "string") {
      effectiveDate = moment;
    } else {
      effectiveDate = moment.format("YYYY-MM-DD");
    }
    setFormData(prevState => ({
      ...prevState,
      effective_date: effectiveDate,
    }));
  };

  const handleStatusChange = (e, { value }) => {
    setFormData(prevState => ({
      ...prevState,
      status_id: value,
      effective_date: undefined,
    }));
  };

  return (
    <>
      {isValidElement(trigger) ? (
        cloneElement(trigger, { onClick: handleOpen, disabled: disabled })
      ) : (
        <>
          {isValidRevenueOpportunityStatus(statusName) ? (
            <strong style={{ color: "black" }}>{statusName}</strong>
          ) : (
            <span style={{ color: "grey" }}>(unset)</span>
          )}
          <Button
            size="mini"
            icon="caret down"
            className="standard"
            style={{ marginLeft: "0.5em" }}
            onClick={handleOpen}
            disabled={disabled}
          />
        </>
      )}
      <Modal
        size="small"
        open={modalOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        closeOnDimmerClick={false}
        closeIcon
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <Modal.Header content={"Revenue Opportunity Status"} />
        <Modal.Content>
          <RuvixxForm
            ready={!!formData.status_id && !!formData.effective_date}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            submitButtonText="APPLY"
            cancelButtonText="Cancel"
            submitButtonClassName=""
            cancelButtonClassName="basic"
          >
            <Form.Select
              required
              inline
              name="status_id"
              clearable
              label="Status"
              style={{ width: "340px" }}
              options={statuses.map(({ id, name }) => ({
                key: id,
                text: name,
                value: id,
              }))}
              value={formData.status_id}
              onChange={handleStatusChange}
            />
            <Form.Field inline required className="dateTime">
              <label>Effective Date</label>
              <Datetime
                name="effective_date"
                required
                closeOnSelect={true}
                onChange={moment => handleDateChange(moment)}
                value={formData.effective_date}
                renderInput={props => (
                  <Input icon="large calendar outline" {...props} />
                )}
                timeFormat={false}
                dateFormat={"YYYY-MM-DD"}
              />
            </Form.Field>
          </RuvixxForm>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default RevenueOpportunityStatusModal;
