import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Message,
  Segment,
} from "semantic-ui-react";
import store from "store";

import moment from "moment-timezone";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import ee from "../../../components/Emitter";
import { checkIsAuthorized, LoadingMessage } from "../../../components/helpers";
import PhoneNumbers from "../../../components/PhoneNumbers";
import RuvixxForm from "../../../components/RuvixxForm";
import Validate from "../../../components/Validate";
import setPageTitle from "../../../helpers/title";
import AttachmentService from "../../../services/Attachment";
import AuthService from "../../../services/Auth";
import RegionService from "../../../services/Regions";
import UserService from "../../../services/User";
import "../../../styles/forms.scss";
import "../../../styles/user_settings.scss";
import Preferences from "../Preferences";

class UserSettingsForm extends Component {
  constructor(props) {
    super(props);

    this.passwordFormatRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*]).{8,}$/;
    this.state = {
      name: "",
      title: "",
      email: "",
      preferredName: "",
      department: "",
      timeZone: "",
      timeZoneName: "",
      timeZones: [],
      regions: [],
      region: null,
      profilePicture: null,
      uploading: false,
      attachment: null,
      user: null,
      emailNotificationOnDelivery: true,
      emailNotificationOnFormResponse: true,
      emailNotificationOnTaskNearDue: true,
      emailNotificationOnTaskPastDue: true,
      emailNotificationOnFormError: true,
      importStatusNotification: true,
      errors: {},
      errorMessage: "",
      errorTitle: "",
      success: false,
      message: "",
      changePassword: false,
      oldPassword: "",
      newPassword: "",
      pwVerification: "",
      passwordValidation: {
        passwordLength: false,
        hasUppercase: false,
        hasLowercase: false,
        hasSpecialCharacters: false,
        hasNumber: false,
      },
      canEditUserSettings: checkIsAuthorized([
        ACL_RELATIONSHIPS.userSettings.edit,
      ]),
      phoneNumbers: [],
      fetchingData: true,
    };
  }

  toggleEmailNotification = (_, data) =>
    this.setState(prevState => ({
      [data.name]: !prevState[data.name],
    }));

  toggleChangePassword = event => {
    this.setState(prevState => ({
      changePassword: !prevState.changePassword,
      oldPassword: "",
      newPassword: "",
      pwVerification: "",
    }));
  };

  handleChange = (_, { name, value, selection }) => {
    if (!this.state.canEditUserSettings) return;
    if (selection && !value) {
      value = null;
    }
    this.setState({
      [name]: value,
      errors: {},
      success: false,
    });
  };

  handlePasswordChange = (_, { name, value, selection }) => {
    this.handleChange(_, { name, value, selection });
    const lengthRegex = /^.{8,}$/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharactersRegex = /[!@#$%^*]/;
    const numberRegex = /\d/;
    this.setState({
      passwordValidation: {
        passwordLength: lengthRegex.test(value),
        hasUppercase: uppercaseRegex.test(value),
        hasLowercase: lowercaseRegex.test(value),
        hasSpecialCharacters: specialCharactersRegex.test(value),
        hasNumber: numberRegex.test(value),
      },
    });
  };

  handleFileChange = event => {
    let file = this.uploadInput.files[0];
    let profilePicture = null;
    let loading = false;
    if (file) {
      loading = true;
      profilePicture = URL.createObjectURL(file);
      // The following call doesn't need to `await`. Leave as it is.
      AttachmentService.uploadFile(file).then(res => {
        this.setState({
          attachment: res,
          loading: false,
        });
      });
    }
    this.setState({
      profilePicture: profilePicture,
      attachment: null,
      loading: loading,
      errors: {},
      success: false,
    });
  };

  addPhoneNumber = (pn, error) => {
    if (error) {
      this.setState({
        error: true,
        errorMessage: error.message,
        errorTitle: error.title,
      });
      return;
    }
    const isDuplicate =
      this.state.phoneNumbers.filter(p => {
        return p === pn;
      }).length > 0;
    if (!isDuplicate) {
      let phoneNumbers = this.state.phoneNumbers;
      phoneNumbers.push(pn);
      this.setState({
        phoneNumbers: phoneNumbers,
        error: false,
      });
    }
  };

  removePhoneNumber = index => {
    let { phoneNumbers } = this.state;
    phoneNumbers.splice(index, 1);
    this.setState({
      phoneNumbers: phoneNumbers,
    });
  };

  updatePhoneNumber = (phoneNumber, index) => {
    let { phoneNumbers } = this.state;
    phoneNumbers[index] = phoneNumber;
    this.setState({ phoneNumbers: phoneNumbers });
  };

  handleSubmit = async e => {
    this.setState({
      success: false,
      errors: {},
      message: "",
    });
    const {
      name,
      title,
      email,
      preferredName,
      department,
      timeZone,
      region,
      attachment,
      emailNotificationOnDelivery,
      emailNotificationOnFormResponse,
      emailNotificationOnTaskNearDue,
      emailNotificationOnTaskPastDue,
      emailNotificationOnFormError,
      importStatusNotification,
      changePassword,
      oldPassword,
      newPassword,
      pwVerification,
      phoneNumbers,
    } = this.state;
    let errors = {};
    if (!Validate.email(email)) {
      errors.email = "Invalid email format";
    }
    if (
      !Validate.imageFileType(
        attachment ? attachment.file_content_type : null
      ) &&
      attachment !== null
    ) {
      errors.fileType = "Invalid image type. Supported types: gif, jpg, png";
    }
    if (changePassword) {
      if (newPassword === "" || oldPassword === "" || pwVerification === "") {
        errors.password = "Password fields must not be empty.";
      } else if (newPassword !== pwVerification) {
        errors.password = "Passwords don't match";
      } else if (!this.passwordFormatRegex.test(newPassword)) {
        errors.password = "Password must have all below items checked";
      }
    }

    if (Object.keys(errors).length === 0) {
      try {
        const nonEmptyPhoneNumbers = phoneNumbers.filter((pn, i) =>
          pn.number?.trim()
        );
        await UserService.editUserSettings({
          name: name || undefined,
          title: title || undefined,
          email: email || undefined,
          preferredName: preferredName || undefined,
          department: department || undefined,
          timeZone:
            timeZone !== "" && timeZone !== null
              ? moment.tz(timeZone).format("Z")
              : undefined,
          timeZoneName: timeZone || undefined,
          region,
          attachment: attachment || undefined,
          newPassword: changePassword ? newPassword : null,
          oldPassword: changePassword ? oldPassword : null,
          phoneNumbers: nonEmptyPhoneNumbers || [],
        });
        let notificationSettings = [
          {
            category: "email",
            action: "delivered",
            status: emailNotificationOnDelivery,
          },
          {
            category: "form",
            action: "submitted",
            status: emailNotificationOnFormResponse,
          },
          {
            category: "task",
            action: "near_due",
            status: emailNotificationOnTaskNearDue,
          },
          {
            category: "task",
            action: "past_due",
            status: emailNotificationOnTaskPastDue,
          },
          {
            category: "form",
            action: "error",
            status: emailNotificationOnFormError,
          },
          {
            category: "import",
            action: "status",
            status: importStatusNotification,
          },
        ];
        await UserService.editUserAuditLogNotificationSettings(
          notificationSettings
        );
        this.setState({
          success: true,
          message: "Settings update successful",
          changePassword: false,
        });
        if (name) {
          AuthService.setName(name);
        }
        if (email) {
          AuthService.setEmail(email);
        }
        if (timeZone) {
          AuthService.setTimeZone(timeZone);
        }
        ee.emitEvent("forceHeaderUpdate");
      } catch (error) {
        this.setState({
          errors: { error: error.message },
        });
      }
    } else {
      this.setState({ errors: errors });
    }
    this.setState({
      oldPassword: "",
      newPassword: "",
      pwVerification: "",
    });
  };

  fetchNotificationStatus = async (category, action) => {
    const user = store.get("userAuth");
    return user.external
      ? false
      : await UserService.getUserAuditLogNotificationSetting(category, action);
  };

  fetchNotificationSettings = async () => {
    const promises = [
      this.fetchNotificationStatus("email", "delivered"),
      this.fetchNotificationStatus("form", "submitted"),
      this.fetchNotificationStatus("task", "near_due"),
      this.fetchNotificationStatus("task", "past_due"),
      this.fetchNotificationStatus("form", "error"),
      this.fetchNotificationStatus("import", "status"),
    ];
    const [
      emailNotificationOnDelivery,
      emailNotificationOnFormResponse,
      emailNotificationOnTaskNearDue,
      emailNotificationOnTaskPastDue,
      emailNotificationOnFormError,
      importStatusNotification,
    ] = await Promise.all(promises);
    this.setState({
      emailNotificationOnDelivery: emailNotificationOnDelivery.status || false,
      emailNotificationOnFormResponse:
        emailNotificationOnFormResponse.status || false,
      emailNotificationOnTaskNearDue:
        emailNotificationOnTaskNearDue.status || false,
      emailNotificationOnTaskPastDue:
        emailNotificationOnTaskPastDue.status || false,
      emailNotificationOnFormError:
        emailNotificationOnFormError.status || false,
      importStatusNotification: importStatusNotification.status || false,
    });
  };

  fetchUserSettings = async () => {
    // TODO: Error state not being reset like elsewhere. Perhaps it should...?
    try {
      const settings = await UserService.getUserSettings();
      const account =
        settings.user_settings !== null ? settings.user_settings.account : {};
      this.fetchNotificationSettings();
      this.setState({
        name: settings.full_name || "",
        title: settings.title || "",
        email: settings.email || "",
        preferredName: account.preferred_name || "",
        department: account.department || "",
        timeZone: account.time_zone_name || "",
        region: settings.region_id,
        profilePicture: account.profile_picture || null,
        phoneNumbers: !!settings.phone_numbers
          ? settings.phone_numbers.map(pn => ({ ...pn }))
          : [],
        fetchingData: false,
      });
    } catch (error) {
      this.setState({
        errors: { server: "Can't get user settings from server" },
      });
    }
  };

  fetchRegions = async () => {
    const rawRegions = await RegionService.getRegions();
    const regions = rawRegions.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));
    this.setState({ regions });
  };

  componentDidMount() {
    setPageTitle("My Account");
    this.fetchUserSettings(); // don't await
    this.fetchRegions();
    const timeZonesNames = moment.tz.names();
    const usPacificNewIdx = timeZonesNames.indexOf("US/Pacific-New");
    if (usPacificNewIdx !== -1) timeZonesNames.splice(usPacificNewIdx, 1);
    const options = timeZonesNames.map(zone => {
      let offset = moment.tz(zone).format("Z");
      return { text: zone + " (" + offset + ")", key: zone, value: zone };
    });
    this.setState({
      timeZones: options,
    });
  }

  render() {
    const externalUser = store.get("userAuth").external;
    const {
      name,
      title,
      email,
      preferredName,
      department,
      timeZone,
      timeZones,
      regions,
      region,
      profilePicture,
      emailNotificationOnDelivery,
      emailNotificationOnFormResponse,
      emailNotificationOnTaskNearDue,
      emailNotificationOnTaskPastDue,
      emailNotificationOnFormError,
      importStatusNotification,
      /* attachment, */
      loading,
      errors,
      success,
      message,
      changePassword,
      oldPassword,
      newPassword,
      pwVerification,
      canEditUserSettings,
      phoneNumbers,
      fetchingData,
      passwordValidation,
    } = this.state;
    return (
      <>
        {fetchingData ? (
          <div style={{ textAlign: "center" }}>
            {" "}
            <LoadingMessage />{" "}
          </div>
        ) : (
          <RuvixxForm
            ready={
              !!name &&
              !!email &&
              !loading &&
              (!changePassword ||
                (changePassword &&
                  oldPassword !== "" &&
                  newPassword !== "" &&
                  oldPassword !== newPassword &&
                  pwVerification === newPassword &&
                  this.passwordFormatRegex.test(newPassword)))
            }
            onSubmit={!!canEditUserSettings && this.handleSubmit}
            onSuccess={!!canEditUserSettings && this.fetchUserSettings}
            successMessage={message}
            submitButtonText="SAVE"
            success={success}
            error={Object.keys(errors).length > 0}
            actionBarPadding={20}
          >
            <Segment basic>
              <Grid columns="equal" relaxed>
                <Grid.Row>
                  <Grid.Column style={{ width: 220, flexGrow: 0 }}>
                    <Grid centered className="noPadding">
                      <Grid.Row>
                        {!loading && profilePicture ? (
                          <Image
                            src={profilePicture}
                            size="medium"
                            centered
                            className="profilePic"
                          />
                        ) : (
                          <Icon
                            name="user"
                            bordered
                            className="default profilePic"
                          />
                        )}
                      </Grid.Row>
                      {canEditUserSettings && (
                        <Grid.Row>
                          <Button
                            as="label"
                            compact
                            className="textLink"
                            style={{ marginTop: "0.5em" }}
                          >
                            {loading ? (
                              <>
                                <Icon name="circle notched" loading />
                                Uploading…
                              </>
                            ) : (
                              <>Upload Profile Picture</>
                            )}
                            <input
                              ref={ref => (this.uploadInput = ref)}
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={this.handleFileChange}
                            />
                          </Button>
                        </Grid.Row>
                      )}
                    </Grid>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      inline
                      className={`limited fillSpace ${
                        !canEditUserSettings && "permission-disabled"
                      }`}
                      name="name"
                      label="Name"
                      required
                      value={name}
                      error={errors.name ? true : false}
                      onChange={this.handleChange}
                    />
                    <Form.Input
                      inline
                      className={`limited fillSpace ${
                        !canEditUserSettings && "permission-disabled"
                      }`}
                      name="preferredName"
                      label="Preferred Name"
                      value={preferredName}
                      onChange={this.handleChange}
                    />
                    <Form.Input
                      inline
                      className={`limited fillSpace ${
                        !canEditUserSettings && "permission-disabled"
                      }`}
                      name="title"
                      label="Title"
                      value={title}
                      onChange={this.handleChange}
                    />
                    <Form.Input
                      inline
                      className={`limited fillSpace ${
                        !canEditUserSettings && "permission-disabled"
                      }`}
                      name="department"
                      label="Department"
                      value={department}
                      onChange={this.handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginRight: "3em" }}>
                    <Form.Input
                      inline
                      className={`limited fillSpace ${
                        !canEditUserSettings && "permission-disabled"
                      }`}
                      required
                      error={errors.email ? true : false}
                      name="email"
                      label="Email"
                      value={email}
                      onChange={this.handleChange}
                    />
                    <Form.Select
                      inline
                      search
                      className={`limited fillSpace ${
                        !canEditUserSettings && "permission-disabled"
                      }`}
                      label="Time Zone"
                      name="timeZone"
                      value={timeZone}
                      options={timeZones}
                      onChange={this.handleChange}
                    />
                    <Form.Select
                      inline
                      search
                      clearable
                      className={`limited fillSpace ${
                        !canEditUserSettings && "permission-disabled"
                      }`}
                      label="Region"
                      name="region"
                      value={region}
                      options={regions}
                      onChange={this.handleChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <Header dividing content="Phone Numbers" />
            <Segment basic>
              <PhoneNumbers
                key={phoneNumbers}
                type={"user"}
                phoneNumbers={phoneNumbers}
                addPhoneNumber={this.addPhoneNumber}
                removePhoneNumber={this.removePhoneNumber}
                updatePhoneNumber={this.updatePhoneNumber}
                onValidationSuccess={this.fetchUserSettings}
              />
              {/*action={<Button content={"Validate"} onClick={() => this.validateNumber(phone)}/>}*/}
            </Segment>
            {canEditUserSettings && (
              <>
                <Header dividing={changePassword}>
                  Change Password
                  <Checkbox
                    toggle
                    name="changePassword"
                    checked={changePassword}
                    onChange={this.toggleChangePassword}
                    className="right"
                    style={{ marginLeft: "1em" }}
                  />
                </Header>
                {changePassword && (
                  <Segment basic>
                    <Form.Group widths="equal">
                      <Form.Input
                        inline
                        className="forceOutline limited fillSpace"
                        required
                        error={errors.oldPassword ? true : false}
                        name="oldPassword"
                        label="Old Password"
                        value={oldPassword}
                        onChange={this.handleChange}
                        type="password"
                      />
                      <Form.Input
                        inline
                        className="forceOutline limited fillSpace"
                        required
                        error={errors.newPassword ? true : false}
                        name="newPassword"
                        label="New Password"
                        value={newPassword}
                        onChange={this.handlePasswordChange}
                        type="password"
                      />
                      <Form.Input
                        inline
                        className="forceOutline limited fillSpace"
                        required
                        error={errors.pwVerification ? true : false}
                        name="pwVerification"
                        label="Verify New Password"
                        value={pwVerification}
                        onChange={this.handleChange}
                        type="password"
                      />
                    </Form.Group>
                    {newPassword.length > 0 ? (
                      <List>
                        <List.Item
                          className={
                            passwordValidation.passwordLength
                              ? " passwordItem--right"
                              : " passwordItem--wrong"
                          }
                        >
                          <List.Icon
                            name={
                              passwordValidation.passwordLength
                                ? "check"
                                : "times"
                            }
                          />
                          <List.Content>8 characters or longer</List.Content>
                        </List.Item>
                        <List.Item
                          className={
                            passwordValidation.hasUppercase
                              ? " passwordItem--right"
                              : " passwordItem--wrong"
                          }
                        >
                          <List.Icon
                            name={
                              passwordValidation.hasUppercase
                                ? "check"
                                : "times"
                            }
                          />
                          <List.Content>1 uppercase</List.Content>
                        </List.Item>
                        <List.Item
                          className={
                            passwordValidation.hasLowercase
                              ? " passwordItem--right"
                              : " passwordItem--wrong"
                          }
                        >
                          <List.Icon
                            name={
                              passwordValidation.hasLowercase
                                ? "check"
                                : "times"
                            }
                          />
                          <List.Content>1 lowercase</List.Content>
                        </List.Item>
                        <List.Item
                          className={
                            passwordValidation.hasSpecialCharacters
                              ? " passwordItem--right"
                              : " passwordItem--wrong"
                          }
                        >
                          <List.Icon
                            name={
                              passwordValidation.hasSpecialCharacters
                                ? "check"
                                : "times"
                            }
                          />
                          <List.Content>
                            1 special character (!@#$%^*)
                          </List.Content>
                        </List.Item>
                        <List.Item
                          className={
                            passwordValidation.hasNumber
                              ? " passwordItem--right"
                              : " passwordItem--wrong"
                          }
                        >
                          <List.Icon
                            name={
                              passwordValidation.hasNumber ? "check" : "times"
                            }
                          />
                          <List.Content>1 number</List.Content>
                        </List.Item>
                        {pwVerification.length > 0 && (
                          <List.Item
                            className={
                              newPassword === pwVerification
                                ? " passwordItem--right"
                                : " passwordItem--wrong"
                            }
                          >
                            <List.Icon
                              name={
                                newPassword === pwVerification
                                  ? "check"
                                  : "times"
                              }
                            />
                            <List.Content>Passwords should match</List.Content>
                          </List.Item>
                        )}
                        {oldPassword === newPassword && (
                          <List.Item className={" passwordItem--wrong"}>
                            <List.Icon name={"times"} />
                            <List.Content>
                              New password should be different from the previous
                              one
                            </List.Content>
                          </List.Item>
                        )}
                      </List>
                    ) : null}
                  </Segment>
                )}
              </>
            )}
            {!externalUser && [
              <Header dividing content="Notifications" />,
              <Segment basic>
                <Form.Group widths={3}>
                  <Form.Checkbox
                    toggle
                    name="emailNotificationOnDelivery"
                    checked={emailNotificationOnDelivery}
                    onChange={this.toggleEmailNotification}
                    label="Be notified on Email Deliveries"
                    disabled={!canEditUserSettings}
                  />
                  <Form.Checkbox
                    toggle
                    name="emailNotificationOnFormResponse"
                    checked={emailNotificationOnFormResponse}
                    onChange={this.toggleEmailNotification}
                    label="Be notified on Form Responses"
                    disabled={!canEditUserSettings}
                  />
                </Form.Group>
                <Form.Group widths={3}>
                  <Form.Checkbox
                    toggle
                    name="emailNotificationOnTaskNearDue"
                    checked={emailNotificationOnTaskNearDue}
                    onChange={this.toggleEmailNotification}
                    label="Be notified on Task close to due date"
                    disabled={!canEditUserSettings}
                  />
                  <Form.Checkbox
                    toggle
                    name="emailNotificationOnTaskPastDue"
                    checked={emailNotificationOnTaskPastDue}
                    onChange={this.toggleEmailNotification}
                    label="Be notified on Task past due date"
                    disabled={!canEditUserSettings}
                  />
                </Form.Group>
                <Form.Group widths={3}>
                  <Form.Checkbox
                    toggle
                    name="emailNotificationOnFormError"
                    checked={emailNotificationOnFormError}
                    onChange={this.toggleEmailNotification}
                    label="Be notified on Form errors"
                    disabled={!canEditUserSettings}
                  />
                  <Form.Checkbox
                    toggle
                    name="importStatusNotification"
                    checked={importStatusNotification}
                    onChange={this.toggleEmailNotification}
                    label="Be notified on Import status"
                    disabled={!canEditUserSettings}
                  />
                </Form.Group>
                {Object.keys(errors).map(key => (
                  <Message
                    key={key}
                    error={true}
                    header={key}
                    content={errors[key]}
                  />
                ))}
              </Segment>,
            ]}
            <Header dividing content="Preferences" />
            <Segment basic>
              <Preferences />
            </Segment>
          </RuvixxForm>
        )}
      </>
    );
  }
}

export default UserSettingsForm;
