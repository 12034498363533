import React, { useState } from "react";
import { Modal, Button, Icon, Dropdown } from "semantic-ui-react";
import RuvixxForm from "components/RuvixxForm";
import _ from "lodash";
import MachineService from "services/Machine";
import MachineApprovalStatusesService from "services/MachineApprovalStatus";
import RuvixxSelect from "components/RuvixxSelect";

const MachineStatusModal = ({
  machineStatusId,
  machineIds,
  machineStatusName,
  fetchFn,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStatusId, setSelectedStatusId] = useState(machineStatusId);

  const handleSubmit = async () => {
    await MachineService.updateStatus({
      machine_ids: machineIds,
      new_status_id: selectedStatusId,
    });
    fetchFn();
    handleClose();
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <>
        {machineStatusName ? (
          <>
            <strong style={{ color: "black" }}>{machineStatusName}</strong>
          </>
        ) : (
          <span style={{ color: "grey" }}>(unset)</span>
        )}
        <Button
          size="mini"
          icon="caret down"
          className="standard"
          style={{ marginLeft: "0.5em" }}
          onClick={handleOpen}
        />
      </>
      <Modal
        size="small"
        open={modalOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        closeOnDimmerClick={false}
        closeIcon
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <Modal.Header content="Change Machine Approval Status" />
        <Modal.Content>
          <RuvixxForm
            onSubmit={handleSubmit}
            onCancel={handleClose}
            submitButtonText="APPLY"
            cancelButtonText="Cancel"
            submitButtonClassName=""
            cancelButtonClassName="basic"
          >
            <RuvixxSelect
              required={true}
              placeholder="Machine Approval Status"
              name="machine_approval_status"
              value={selectedStatusId}
              onChange={(_, data) => {
                setSelectedStatusId(data.value);
              }}
              queryFn={async _ => {
                return MachineApprovalStatusesService.getForFilters({});
              }}
              className="or-filter"
            />
          </RuvixxForm>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default MachineStatusModal;
