import React, { createRef, useEffect, useState } from "react";
import {
  Checkbox,
  Container,
  Dropdown,
  Grid,
  Popup,
  Progress,
  Segment,
} from "semantic-ui-react";

import ACL_RELATIONSHIPS from "../../acl-relationships";
import BaseTable from "../../components/BaseTable";
import CustomDropdown from "../../components/CustomDropdown";
import {
  checkIsAuthorized,
  createDateTimeFormatter,
} from "../../components/helpers";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import TagList from "../../components/TagList";
import Constants from "../../constants/Constants";
import AuthService from "../../services/Auth";
import DataJobService from "../../services/DataJob";
import UserService from "../../services/User";
import DataTabSideButtons from "./components/DataTabSideButtons";
import {
  DeleteJobModal as _DeleteJobModal,
  ImportModal,
} from "./components/Modals";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import CONSTANTS from "../../constants/Constants";
import setPageTitle from "../../helpers/title";

const { DATA_JOB_STATUSES, IMPORT_TYPES, EXPORT_TYPES } = Constants;

const DeleteJobModal = withRoleCheck(_DeleteJobModal, [
  ACL_RELATIONSHIPS.dataJob.delete,
]);

const ImportModalTrigger = withRoleCheck(ImportModal.Trigger, [
  ACL_RELATIONSHIPS.dataJob.read,
  ACL_RELATIONSHIPS.importData.edit,
]);

const getStatusKey = (status, info) => {
  let statusKey;

  for (const JOB_STATUS in DATA_JOB_STATUSES) {
    if (DATA_JOB_STATUSES[JOB_STATUS] === status) {
      statusKey = JOB_STATUS.replace(/_/g, " ");
    }
  }

  if (info.aborted) {
    statusKey = "aborted";
  }

  return statusKey.toUpperCase();
};

const getProgress = progress =>
  Math.floor((progress["current_row"] / progress["max_rows"]) * 100);

const JobProgress = ({ jobId, info, status, fetchData }) => {
  const [percentage, setPercentage] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAborted, setIsAborted] = useState(false);
  const [jobStatus, setJobStatus] = useState(status);
  const [jobInfo, setJobInfo] = useState(info);
  const [label, setLabel] = useState(null);
  const [stopMonitor, setStopMonitor] = useState(false);
  const timeoutMilis = 1000;

  const monitor = async () => {
    try {
      const dataJob = await DataJobService.getDataJob(jobId);

      if (dataJob.status !== jobStatus) {
        fetchData();
      }

      if (dataJob.status !== DATA_JOB_STATUSES.IN_PROGRESS) {
        setStopMonitor(true);
      }

      if (
        jobInfo["is_import"] &&
        dataJob.status === DATA_JOB_STATUSES.IN_PROGRESS
      ) {
        setPercentage(getCurrentProgress(dataJob.info));
      }

      setJobStatus(dataJob.status);
      setJobInfo(dataJob.info);
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    let percentage = 0;

    switch (status) {
      case DATA_JOB_STATUSES.SCHEDULED:
        percentage = 0;
        break;
      case DATA_JOB_STATUSES.IN_PROGRESS:
        if (jobInfo["is_import"]) {
          percentage = getCurrentProgress(jobInfo);
        } else {
          percentage = 50;
        }

        if (!stopMonitor) {
          setTimeout(monitor, timeoutMilis);
        }
        break;
      case DATA_JOB_STATUSES.FAILED:
        setIsError(true);
        percentage = 100;
        break;
      case DATA_JOB_STATUSES.COMPLETE:
        percentage = 100;
        setIsFinished(true);
        break;
      case DATA_JOB_STATUSES.ABORTED:
      case DATA_JOB_STATUSES.EXPIRED:
        percentage = 100;
        setIsAborted(true);
        break;
      case DATA_JOB_STATUSES.READY:
        percentage = getCurrentProgress(jobInfo);
        break;
      default:
        percentage = 0;
    }

    setPercentage(percentage);
  }, [jobStatus, jobInfo]);

  const getCurrentProgress = jobInfo => {
    const validation = jobInfo.validation;
    const importing = jobInfo.import;
    let percentage = 0;

    if (importing) {
      percentage = getProgress(importing);
      if (percentage >= 100) {
        setLabel("Import Complete!");
      } else {
        setLabel("Importing...");
      }
    } else if (validation) {
      percentage = getProgress(validation);
      if (percentage >= 100) {
        setLabel("Validation Complete!");
      } else {
        setLabel("Validating...");
      }
    }

    return Math.floor(percentage);
  };

  return (
    <Progress
      percent={percentage}
      // indicating={!isFinished}
      progress
      error={isError || isAborted}
      color={isFinished ? "green" : "blue"}
    />
  );
};

class DataJobTable extends BaseTable {
  constructor(props) {
    super(props);
    this.importModal = createRef();
    this.caseDataImportModal = createRef();

    this.queryMethod = DataJobService.getDataJobs;

    this.state = {
      ...this.state,
      className: "DataJob",
      tableName: "dataJobs",
      noDataText: "No Data Jobs Found",
      enableSearch: false,
      headerIcon: "clone",
      header: "Data Jobs",
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.dataJobTags.create,
        ACL_RELATIONSHIPS.dataJobTags.read,
        ACL_RELATIONSHIPS.dataJobTags.delete,
      ]),
      statuses: [],
      types: [],
      users: [],
    };
  }

  async componentDidMount() {
    setPageTitle("Data");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.getStatuses();
      this.fetchSubmitters();
      this.fetchJobTypes();
      this.fetchTags();
    }
  }

  deleteJob = async id => {
    await DataJobService.deleteJob(id);
    this.fetchData(); // don't await, it must occur after delete modal closes
  };

  formatDate = () => createDateTimeFormatter({ format: "YYYY-MM-DD HH:mm" });

  openImportModal = dataJobId => {
    this.importModal.current.open(dataJobId);
  };

  openCaseDataImportModal = dataJobId => {
    this.caseDataImportModal.current.open(dataJobId);
  };

  setColumns = () => {
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Job Type",
        accessor: "type",
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: props => (
          <Popup
            content={props.original.description}
            trigger={<span>{props.original.description}</span>}
          />
        ),
      },
      {
        Header: "Submitter",
        accessor: "submitter",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        maxWidth: 130,
        Cell: props => (
          <p>{this.formatDate("UTC")(props.original.created_at)}</p>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        maxWidth: 100,
        Cell: props => (
          <p>{getStatusKey(props.original.status, props.original.info)}</p>
        ),
      },
      {
        Header: "Progress",
        accessor: "progress",
        style: { maxHeight: "50px" },
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <div>
            <JobProgress
              jobId={props.original.id}
              status={props.original.status}
              info={props.original.info}
              fetchData={this.fetchData}
            />
          </div>
        ),
      },
      {
        Header: "Result",
        accessor: "id",
        headerClassName: "non-sortable",
        className: "centered",
        sortable: false,
        Cell: ({ original: { id, type } }) => {
          if (type.startsWith("EXPORT")) {
            return "---";
          }
          return (
            <ImportModalTrigger
              content="Check Result"
              size="mini"
              onClick={() =>
                type === "CASE DATA IMPORT"
                  ? this.openCaseDataImportModal(id)
                  : this.openImportModal(id)
              }
            />
          );
        },
      },
      {
        Header: "Download URL",
        accessor: "download_url",
        headerClassName: "non-sortable",
        className: "centered",
        sortable: false,
        Cell: props => {
          if (props.value) {
            return (
              <Popup
                content={
                  props.original.file_name
                    ? props.original.file_name
                    : "No File Name"
                }
                trigger={<a href={props.value}>Download Here</a>}
              />
            );
          } else {
            return <p> No Link </p>;
          }
        },
      },
      {
        Header: "Actions",
        id: "actions",
        className: "action-menu",
        width: 100,
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ original: { id, type, status } }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              {(type.startsWith("IMPORT") || type.startsWith("VALIDATION")) &&
                status !== DATA_JOB_STATUSES.EXPIRED && (
                  <ImportModalTrigger
                    status={status}
                    dropdown
                    onClick={() => this.openImportModal(id)}
                  />
                )}
              <DeleteJobModal
                onConfirmDelete={() => this.deleteJob(id)}
                menuTrigger
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  getStatuses = () => {
    let statuses = [];
    for (const JOB_STATUS in DATA_JOB_STATUSES) {
      statuses.push({
        id: DATA_JOB_STATUSES[JOB_STATUS],
        name: JOB_STATUS.replace(/_/g, " ").toUpperCase(),
      });
    }
    this.setState({ statuses });
  };

  fetchSubmitters = async () => {
    const users = (await UserService.getUsers()).map(
      ({ id, full_name: name }) => ({ id, name })
    );
    this.setState({ users }, this.updateFilterOptions);
  };

  fetchJobTypes = async () => {
    const types = [];
    types.push({
      id: "VALIDATION",
      name: "VALIDATION",
    });
    for (const importType in IMPORT_TYPES) {
      types.push({
        id: importType.replace(/_/g, " ").toUpperCase() + " IMPORT",
        name: importType.replace(/_/g, " ").toUpperCase() + " IMPORT",
      });
    }
    for (const exportType in EXPORT_TYPES) {
      types.push({
        id: "EXPORT " + exportType.replace(/_/g, " ").toUpperCase(),
        name: "EXPORT " + exportType.replace(/_/g, " ").toUpperCase(),
      });
    }
    this.setState({ types }, this.updateFilterOptions);
  };

  updateFilterOptions = async () => {
    const { statuses, users, types, tags } = this.state;
    let filters = [
      {
        key: "status",
        title: "Status",
        type: "select",
        data: statuses,
      },
      {
        key: "model",
        title: "Type",
        type: "select",
        data: [
          { id: "audit_log", name: "Audit Log" },
          { id: "contact", name: "Contact" },
          { id: "campaign_target", name: "Campaign Targets" },
        ],
      },
      {
        key: "action",
        title: "Action",
        type: "select",
        data: [
          { id: "IMPORT", name: "Import" },
          { id: "EXPORT", name: "Export" },
        ],
      },
      {
        key: "creator_user_id",
        title: "Submitter",
        type: "select",
        data: users,
      },
      {
        key: "job_type",
        title: "Job Type",
        type: "select",
        data: types,
      },
      {
        key: "created_date",
        title: "Created Date",
        type: "dateRange",
        _type: "advanced",
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
    ];
    this.setState({ filters });
  };

  render() {
    return (
      <Container fluid className="route">
        <ImportModal ref={this.importModal} fetchData={this.fetchData} />
        <ImportModal
          importType={CONSTANTS.IMPORT_TYPES.CASE_DATA}
          ref={this.caseDataImportModal}
          fetchData={this.fetchData}
        />
        <Grid className="bg" divided style={{ height: "100%" }}>
          <Grid.Row columns="2">
            <Grid.Column width={3} className="aside">
              <DataTabSideButtons
                fetchData={this.fetchData}
                openImportModal={this.openImportModal}
                openCaseDataImportModal={this.openCaseDataImportModal}
              />
            </Grid.Column>
            <Grid.Column width={13}>
              <div className="campaign-segment">
                <Segment>{this.renderTable()}</Segment>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default DataJobTable;
