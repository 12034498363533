import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Header, Icon, Modal } from "semantic-ui-react";

import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import AppendContactForm from "./../forms/Contact";
import EditContactForm from "./../forms/EditContact";
import EditEntityForm from "./../forms/EditEntity";
import NewEntityForm from "./../forms/NewEntity";

const AppendContactModal = ({ addContact, contacts }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = e => {
    e.preventDefault();
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      trigger={
        <Button
          onClick={e => handleOpen(e)}
          size="tiny"
          basic
          style={{ marginBottom: "1em" }}
        >
          <Icon name="plus" />
          <strong>Add Contact</strong>
        </Button>
      }
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="small"
      closeIcon
    >
      <Header content="Add Contact" />
      <Modal.Content>
        <AppendContactForm
          append={true}
          handleClose={handleClose}
          addContact={addContact}
          contacts={contacts}
        />
      </Modal.Content>
    </Modal>
  );
};

AppendContactModal.propTypes = {
  addContact: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired,
};

const DeleteEntityModal = ({ onConfirmDelete, menuTrigger, noIcon }) => {
  return (
    <ConfirmationModal
      actionDescription="Delete Entity"
      buttonColor="grey"
      onConfirm={onConfirmDelete}
      menuTrigger={menuTrigger}
      icon={noIcon ? null : "trash"}
      warning
    >
      <p>Are you sure you want to delete this Entity?</p>
    </ConfirmationModal>
  );
};

DeleteEntityModal.propTypes = {
  onConfirmDelete: PropTypes.func.isRequired,
  menuTrigger: PropTypes.bool,
  noIcon: PropTypes.bool,
};

const EditEntityModal = ({ fetchEntities, entityId, iconTrigger, noIcon }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = async () => {
    await fetchEntities(); // maybe-promise
    handleClose();
  };

  return (
    <Modal
      size="large"
      trigger={
        iconTrigger ? (
          <Icon
            name="pencil"
            className="primary"
            link
            onClick={handleOpen}
            style={{ verticalAlign: "-2px" }}
          />
        ) : (
          <Dropdown.Item
            onClick={handleOpen}
            content="Edit"
            icon={noIcon ? null : "edit"}
          />
        )
      }
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      onFocus={e => {
        e.stopPropagation();
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Modal.Header>Edit Entity</Modal.Header>
      <Modal.Content>
        <EditEntityForm onSuccess={handleSuccess} entityId={entityId} />
      </Modal.Content>
    </Modal>
  );
};

EditEntityModal.propTypes = {
  fetchEntities: PropTypes.func.isRequired,
  entityId: PropTypes.number.isRequired,
  iconTrigger: PropTypes.bool,
  noIcon: PropTypes.bool,
};

const NewEntityModal = ({ fetchEntities }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = async () => {
    await fetchEntities(); // maybe-promise
    handleClose();
  };

  return (
    <Modal
      size="large"
      trigger={
        <Button
          size="tiny"
          content="New Entity"
          className="item-adder"
          onClick={handleOpen}
        />
      }
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>New Entity</Modal.Header>
      <Modal.Content>
        <NewEntityForm onSuccess={handleSuccess} />
      </Modal.Content>
    </Modal>
  );
};

NewEntityModal.propTypes = {
  fetchEntities: PropTypes.func.isRequired,
};

const EditContactModal = forwardRef(
  (
    {
      onSuccess,
      entityId,
      contact: parentContact,
      iconTrigger = false,
      noIcon,
      dialer = false,
      noTrigger,
    },
    ref
  ) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [contact, setContact] = useState(parentContact);

    useEffect(() => {
      setContact(parentContact);
    }, [parentContact]);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
    }));

    const handleOpen = contact => {
      if (contact !== undefined) {
        setContact(contact);
      }
      setModalOpen(true);
    };

    const handleClose = () => {
      setModalOpen(false);
    };

    const handleSuccess = async () => {
      await onSuccess(); // maybe-promise
      handleClose();
    };

    return (
      <Modal
        size="small"
        trigger={
          !noTrigger &&
          (iconTrigger ? (
            <Icon
              name="pencil"
              className="primary"
              link
              onClick={() => handleOpen()}
              style={{
                verticalAlign: dialer ? "-20px" : "-2px",
              }}
            />
          ) : (
            <Dropdown.Item
              onClick={() => handleOpen()}
              content="Edit"
              icon={noIcon ? null : "edit"}
            />
          ))
        }
        open={modalOpen}
        onClose={handleClose}
        closeOnDimmerClick={false}
        closeIcon
        onFocus={e => {
          e.stopPropagation();
        }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Modal.Header>Edit Contact</Modal.Header>
        <Modal.Content>
          <EditContactForm
            onSuccess={handleSuccess}
            entityId={entityId ?? contact?.entity_id}
            contact={contact}
          />
        </Modal.Content>
      </Modal>
    );
  }
);

EditContactModal.propTypes = {
  onSuccess: PropTypes.func,
  entityId: PropTypes.number,
  contact: PropTypes.object,
  iconTrigger: PropTypes.bool,
  noIcon: PropTypes.bool,
  dialer: PropTypes.bool,
  noTrigger: PropTypes.bool,
};

EditContactModal.defaultProps = {
  onSuccess: () => {},
  noTrigger: false,
};

const DeleteContactModal = ({ onConfirmDelete, menuTrigger }) => {
  return (
    <ConfirmationModal
      icon="trash"
      onConfirm={onConfirmDelete}
      buttonColor="grey"
      actionDescription="Delete"
      menuTrigger={menuTrigger}
      warning
    >
      <p>Are you sure you want to delete this Contact?</p>
    </ConfirmationModal>
  );
};

DeleteContactModal.propTypes = {
  onConfirmDelete: PropTypes.func.isRequired,
  menuTrigger: PropTypes.bool,
};

export {
  DeleteEntityModal,
  EditEntityModal,
  NewEntityModal,
  AppendContactModal,
  EditContactModal,
  DeleteContactModal,
};
