import React from "react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import { Container, Dropdown, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "acl-relationships";

import BaseTable from "components/BaseTable";
import CustomDropdown from "components/CustomDropdown";
import withRoleCheck from "components/hocs/withRoleCheck";
import ConfirmationModal from "components/modals/ConfirmationModal";
import FormModal from "components/modals/FormModal";
import setPageTitle from "helpers/title";
import AuthService from "services/Auth";
import MachineApprovalStatusService from "services/MachineApprovalStatus";
import "styles/campaign.scss";
import "styles/table.scss";
import MachineApprovalStatusForm from "./MachineApprovalStatusForm";

const CreateMachineApprovalStatusModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.machine_approval_status.create,
]);
const EditMachineApprovalStatusModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.machine_approval_status.edit,
]);
const DeleteMachineApprovalStatusModal = withRoleCheck(ConfirmationModal, [
  ACL_RELATIONSHIPS.machine_approval_status.delete,
]);

class MachineApprovalStatusesTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = MachineApprovalStatusService.getTable;

    this.state = {
      ...this.state,
      enableSearch: false,
      header: "Machine Approval Statuses",
      headerIcon: "shop",
      className: "MachineApprovalStatuses",
      tableName: "MachineApprovalStatuses",
      noDataText: "No MachineApprovalStatus found. Create one.",
      createButton: (
        <CreateMachineApprovalStatusModal
          onSuccess={this.fetchData}
          modelType={"Machine Approval Status"}
          FormComponent={MachineApprovalStatusForm}
        />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Machine Approval Statuses");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  deleteMachineApprovalStatus = async id => {
    await MachineApprovalStatusService.delete(id);
    this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Actions",
        id: "actions",
        width: 60,
        className: "action-menu",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditMachineApprovalStatusModal
                dropdown
                onSuccess={this.fetchData}
                modelType={"Machine Approval Status"}
                model={props.original}
                FormComponent={MachineApprovalStatusForm}
              />
              <DeleteMachineApprovalStatusModal
                actionDescription="Delete Machine Approval Status"
                buttonColor="grey"
                onConfirm={() =>
                  this.deleteMachineApprovalStatus(props.original.id)
                }
                menuTrigger
                icon="trash"
                warning
              >
                <p>
                  Are you sure you want to delete this Machine Approval Status?
                </p>
              </DeleteMachineApprovalStatusModal>
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default MachineApprovalStatusesTable;
