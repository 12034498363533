import React from "react";
import { Container } from "semantic-ui-react";
import { DialSessionList } from "./components/DialSessionList";

export default function DialSessions() {
  return (
    <Container fluid className="route">
      <DialSessionList />
    </Container>
  );
}
