import isEqual from "lodash/isEqual";
import React, { Component } from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import CampaignTargetCallService from "../../services/CampaignTargetCall";

import EditCallForm from "../forms/EditCallForm";

class EditCallModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      call: this.props.call || {},
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!!nextProps.callId && !nextProps.call) {
      return null; // call is set in modal & not from props
    }
    if (!isEqual(nextProps.call, prevState.call)) {
      return {
        call: nextProps.call,
      };
    }

    return null;
  }

  async handleOpen() {
    if (!this.props.call && !!this.props.callId) {
      const _call = await CampaignTargetCallService.getCall(this.props.callId);
      this.setState({ call: _call });
    }

    this.setState({ modalOpen: true });
  }

  onSuccess = async call => {
    this.handleClose();
    this.props.onSuccess(call, this.props.callIndex);
  };

  handleClose() {
    this.setState({ modalOpen: false });
  }

  render() {
    return (
      <Modal
        trigger={
          <Button
            type="button"
            size="mini"
            basic
            color="red"
            content="Edit Call"
            onClick={this.handleOpen}
          />
        }
        size="large"
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Header content="Edit Call" />
        <Modal.Content>
          <EditCallForm
            call={this.state.call}
            callIndex={this.props.callIndex}
            callStatuses={this.props.callStatuses}
            handleClose={this.handleClose}
            onSuccess={this.onSuccess}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default EditCallModal;
