import CONSTANTS from "../constants/Constants";
import { applyFilters, doRequest } from "./helpers";

const { DATA_JOB_STATUSES, IMPORT_TYPES, EXPORT_TYPES } = CONSTANTS;

export default class DataJobService {
  static async getDataJobs(filters = null) {
    let path = `/data_job/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getDataJob(data_job_id) {
    let path = `/data_job/${data_job_id}`;
    return doRequest("GET", path);
  }

  static async deleteJob(data_job_id) {
    let path = `/data_job/${data_job_id}`;
    return doRequest("DELETE", path);
  }

  static async createImportJob(campaignId, attachments) {
    const action = "DATA IMPORT";
    const model = "data";

    return doRequest("POST", "/data_job/", {
      data: {
        status: DATA_JOB_STATUSES.SCHEDULED,
        action,
        model,
        campaign_ids: [campaignId],
        info: {
          comment: "Attached file was used for importing",
          is_import: true,
          log_action: "import_job_created",
        },
        attachments: attachments,
      },
    });
  }

  static async createValidationJob(
    campaignIds,
    attachments,
    options,
    description
  ) {
    return doRequest("POST", "/data_job/", {
      data: {
        status: DATA_JOB_STATUSES.SCHEDULED,
        action: "VALIDATION",
        model: "data",
        campaign_ids: campaignIds,
        info: {
          comment: "Attached file was used for validating",
          is_import: true,
          log_action: "validation_job_created",
          validation_options: options,
        },
        attachments,
        description,
      },
    });
  }

  static async createExportJob(model) {
    const action = `EXPORT ${model.replace(/_/g, " ").toUpperCase()}S`;

    return doRequest("POST", "/data_job/", {
      data: {
        status: DATA_JOB_STATUSES.SCHEDULED,
        action,
        model,
        info: {
          comment: "Export filters are being used for Exporting",
          is_export: true,
          log_action: "export_job_created",
        },
        description: "export",
      },
    });
  }

  static async exportData(data_job_id, filters, model) {
    const path = applyFilters(filters, `/data_job/export/${model}`);
    return doRequest("POST", path, {
      data: {
        data_job_id,
      },
    });
  }

  static async abortImport(data_job_id) {
    const import_type = "data";

    return doRequest("PUT", `/data_job/import/${import_type}`, {
      data: {
        data_job_id,
        abort: true,
        import_type,
      },
    });
  }

  static async confirmImport(data_job_id, confirm, replace) {
    const import_type = "data";

    return doRequest("PUT", `/data_job/import/${import_type}`, {
      data: {
        data_job_id,
        confirm,
        replace,
        import_type,
      },
    });
  }

  static async getImportTemplate() {
    return doRequest("GET", "/data_job/import/template/");
  }

  static async getDataJobErrors(dataJobId, filters = null, download = false) {
    let path = `/data_job/${dataJobId}/errors/`;
    if (download) path = path + "download";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getCaseDataImportTemplate() {
    return doRequest("GET", "/data_job/case_data/template/");
  }

  static async createCaseDataImportJob(attachments, info, description) {
    return doRequest("POST", "/data_job/case_data/", {
      data: {
        status: DATA_JOB_STATUSES.SCHEDULED,
        action: "CASE DATA IMPORT",
        model: "CASE_DATA",
        attachments: attachments,
        info: info,
        description,
      },
    });
  }
}
