import React from "react";
import { Grid, Header, Icon } from "semantic-ui-react";

import DialSessionsTable from "./DialSessionsTable";

export function DialSessionList() {
  return (
    <Grid className="bg list-grid" divided>
      <Grid.Row>
        <Grid.Column className="eight wide table-top-buttons">
          <Header>
            <Icon name="phone" />
            Dial Sessions
          </Header>
        </Grid.Column>
        <Grid.Column className="eight wide table-top-buttons"></Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="sixteen wide">
          <DialSessionsTable />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
