import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Label, Menu, Icon } from "semantic-ui-react";
import store from "store";
import { Device } from "twilio-client";

import ACL_RELATIONSHIPS from "../../acl-relationships";
import CopyCCEmail from "../../components/CopyCCEmail";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import PhoneDetails from "../../components/PhoneDetails";
import RichTextEditor from "../../components/RichEditor/RichTextEditor";
import Sidebar from "../../components/Sidebar";
import AuditLog from "../../components/views/AuditLog";
import NotesView from "../../components/views/NotesView";
import ResponsesView from "../../components/views/ResponsesView";
import CONFIG from "../../Config";
import { DEFAULT_CAMPAIGN_ID, generateTabs } from "../../constants/Constants";
import { isContactEmailable, isContactCallable } from "../../helpers/contacts";
import setPageTitle from "../../helpers/title";
import useTags from "../../hooks/useTags";
import AuthService from "../../services/Auth";
import CallQueueService from "../../services/CallQueue";
import CampaignTargetCallService from "../../services/CampaignTargetCall";
import ContactService from "../../services/Contact";
import TwilioService from "../../services/twilio";
import { SendEmailModal as _SendEmailModal } from "../campaign/components/Modals";
import { EditContactModal as _EditContactModal } from "../entity/components/Modals";
import DialerHeader from "./components/DialerHeader";
import DialerMonitorFooter from "./components/DialerMonitorFooter";
import { EPOCH_TIMESTAMP } from "constants/Constants";
import RuvixxDate from "components/RuvixxDate";

import "./PowerDialer.scoped.scss";

const EditContactModal = withRoleCheck(_EditContactModal, [
  ACL_RELATIONSHIPS.entityContact.edit,
  ACL_RELATIONSHIPS.entityForFilters.read,
]);
const SendEmailModal = withRoleCheck(_SendEmailModal, [
  ACL_RELATIONSHIPS.emailTemplates.read,
  ACL_RELATIONSHIPS.contactEmail.create,
]);

const tabs = generateTabs(
  "script",
  "survey",
  "activityLog",
  "notes",
  "submittedForms"
);

const DialButton = ({
  paused,
  phoneNumber,
  deviceStatus,
  makeCall,
  hangup,
}) => {
  let buttonText = "Not Ready";
  let buttonDisabled = true;
  let onClick = () => void 0;
  if (paused) {
    buttonText = "Paused";
  } else if (phoneNumber) {
    switch (deviceStatus) {
      case "offline":
        buttonText = "Offline";
        break;
      case "ready":
        buttonText = "Dial";
        onClick = makeCall;
        break;
      case "connected":
        buttonText = "Hang Up";
        onClick = hangup;
        break;
      case "incoming":
        buttonText = "Incoming";
        break;
      default:
    }
  }

  return (
    <Button content={buttonText} disabled={buttonDisabled} onClick={onClick} />
  );
};

const DialerInfo = ({
  paused,
  deviceStatus,
  callerId,
  phoneNumber,
  makeCall,
  hangup,
}) => {
  const savedCallInfo = store.get("currentCallData") || {};

  return (
    <div className="dialer-top">
      <div className="dialer-numbers">
        <div className="dialer-number">
          {savedCallInfo.isIncomingCall ? (
            <>
              <p>In a call with </p>
              <Button as="div" labelPosition="left" className="dial">
                <Label basic size="large">
                  {savedCallInfo.phone_number}
                </Label>
                <Button className="dial" disabled>
                  Hang up
                </Button>
              </Button>
            </>
          ) : (
            <>
              <p>Calling To</p>
              <Button as="div" labelPosition="left" className="dial">
                <Label as="a" basic content={phoneNumber || "unavailable"} />
                <DialButton
                  phoneNumber={phoneNumber}
                  deviceStatus={deviceStatus}
                  makeCall={makeCall}
                  hangup={hangup}
                  paused={paused}
                />
              </Button>
            </>
          )}
        </div>
        <div className="dialer-number">
          <p>{savedCallInfo.isIncomingCall ? "Inbound Line" : "Caller ID"}</p>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
            }}
          >
            <Label
              as="a"
              basic
              size="large"
              content={
                savedCallInfo.isIncomingCall ? savedCallInfo.dial_to : callerId
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function PowerDialerMonitor() {
  const [token, setToken] = useState(null);
  const [dialerInfo, setDialerInfo] = useState({});
  const [device, setDevice] = useState(null);
  const [connection, setConnection] = useState(null);
  const [deviceStatus, setDeviceStatus] = useState("");
  const [callSid, setCallSid] = useState("");
  const [callerId, setCallerId] = useState("");
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState(null);
  const [call, setCall] = useState(null);
  const [callInfo, setCallInfo] = useState({});
  const [micState, setMicState] = useState("muted"); // call starts muted
  const [counters] = useState({
    contactsCalled: { pending: 0, confirmed: 0 },
    liveCalls: { pending: 0, confirmed: 0 },
    voiceMails: { pending: 0, confirmed: 0 },
  });
  const [paused] = useState(false);
  const [updateAuditLogFlag, setUpdateAuditLogFlag] = useState(1);
  const [activeTab, setActiveTab] = useState("activityLog");
  const [focusOnNewNote, setFocusOnNewNote] = useState(false);
  const [auditLogId, setAuditLogId] = useState(null);

  const callTags = useTags("CampaignTargetCall");
  const contactTags = useTags("Contact");
  const entityTags = useTags("Entity");

  const params = useParams();

  const fetchCallerId = useCallback(async callSid => {
    if (callSid) {
      const call = await CampaignTargetCallService.getCallInfo(callSid);
      setCall(call);
      setCallerId(call.caller_id);
    }
  }, []);

  const makeCall = useCallback(async () => {
    setDeviceStatus("calling");
    if (AuthService.isLoggedIn()) {
      const payload = {
        agent: store.get("userAuth").email,
        supervisor: true,
        Caller: `client:${store.get("userAuth").email}`,
        call_id: callInfo.call_sid,
        contact_id: callInfo.contact_id,
        entity_id: callInfo.entity_id,
        campaign_id: callInfo.campaign_id,
        call_queue_id: callInfo.info && callInfo.info.call_queue_id,
      };
      setCall(device.connect(payload));
    }
  }, [
    callInfo.call_sid,
    callInfo.campaign_id,
    callInfo.contact_id,
    callInfo.entity_id,
    device,
  ]);

  const hangup = useCallback(async () => {
    if (device) {
      device.disconnectAll();
    }
    setDeviceStatus("");
    setConnection(null);
  }, [device]);

  const handleLeaveCall = async () => {
    if (deviceStatus === "connected") {
      hangup();
    }
    window.close();
  };

  const initDevice = useCallback(() => {
    if (!device) {
      setDevice(new Device());
    }
  }, [device]);

  const refreshContactInfo = useCallback(async () => {
    if (!dialerInfo.id && !(dialerInfo.contact && dialerInfo.contact.id)) {
      return;
    }
    const newDialerInfo = await CallQueueService.getQueueItem(
      dialerInfo.dial_session.id,
      dialerInfo.contact.id
    );
    setDialerInfo(newDialerInfo);
    setUpdateAuditLogFlag(updateAuditLogFlag + 1);
  }, [dialerInfo.id, dialerInfo.contact?.id]);

  useEffect(() => {
    if (device) {
      async function setupDevice() {
        const data = await TwilioService.generateToken();
        device.setup(data.token);
      }

      setupDevice();

      device.on("cancel", function (device) {
        console.log("cancel");
        setDeviceStatus("ready");
      });
      device.on("ready", function (device) {
        console.log("ready");
        setDeviceStatus("ready");
        makeCall();
      });
      device.on("connect", function (connection) {
        console.log("connect");
        setCallSid(connection.parameters.CallSid);
        // The caller id should be the outbound number facing the client
        fetchCallerId(callInfo.call_sid);
        setDeviceStatus("connected");
      });
      device.on("incoming", function (connection) {
        console.log("incoming");
        setDeviceStatus("incoming");
      });
      device.on("disconnect", function (connection) {
        console.log("disconnect");
        setDeviceStatus("ready");
        setConnection(null);
      });
      device.on("offline", function (device) {
        // reconnect device
        console.log("offline");
        setDeviceStatus("offline");
        setConnection(null);
        setupDevice();
      });
      device.on("error", function (error) {
        // TODO: handle errors
        device.disconnectAll();
        setConnection(null);
        if (error.code === 31009) {
          // TODO: figure out why this error is always happening
          console.warn(error.message);
        }
      });
    }

    return () => {
      device && device.destroy();
    };
  }, [device, callInfo.call_sid, fetchCallerId, makeCall]);

  const fetchCallInfo = useCallback(async () => {
    // TODO: get callInfo from callSid??
    const callId = params.callId;
    const callInfo = await CampaignTargetCallService.getCall(callId);
    setCallInfo(callInfo);
    const dialerInfo = await CallQueueService.getQueueItem(
      callInfo.dial_session_id,
      callInfo.contact_id
    );
    if (dialerInfo) {
      setDialerInfo(dialerInfo);
    }
    setCurrentPhoneNumber(callInfo.info.number_called);
  }, [params.campaignId, params.callId]);

  const fetchEmailedForms = useCallback(
    async filters => {
      filters = filters || {};
      return await ContactService.getEmails(dialerInfo?.contact.id, filters);
    },
    [dialerInfo.contact]
  );

  const unmuteToAgent = async () => {
    try {
      const resp = await TwilioService.updateParticipant(
        callInfo.call_sid,
        callSid,
        {
          call_sid_to_coach: callInfo.call_sid,
          coaching: true,
          muted: false,
        }
      );
      setMicState(!!resp.call_sid_to_coach && "unmute-agent");
    } catch {}
  };

  const unmuteToAll = async () => {
    try {
      const resp = await TwilioService.updateParticipant(
        callInfo.call_sid,
        callSid,
        {
          muted: false,
          coaching: false,
        }
      );
      setMicState(resp.muted === false && "unmute-all");
    } catch {}
  };

  const mute = async () => {
    try {
      const resp = await TwilioService.updateParticipant(
        callInfo.call_sid,
        callSid,
        {
          muted: true,
          coaching: false,
        }
      );
      setMicState(resp.muted && "muted");
    } catch {}
  };

  const handleWindowClose = useCallback(() => {
    window.addEventListener("beforeunload", hangup);
  }, [hangup]);

  useEffect(() => {
    setPageTitle("Dialer Monitor");
    (async () => {
      await fetchCallInfo();
      await initDevice();
    })();
    handleWindowClose();
  }, [fetchCallInfo, handleWindowClose, initDevice]);

  useEffect(() => {
    (async () => {
      await refreshContactInfo();
    })();
  }, [refreshContactInfo]);

  const renderSidebar = () => {
    const {
      contact,
      entity,
      dial_session: { campaign_id } = {},
    } = dialerInfo || {};
    if (!(dialerInfo && contact)) return;

    return (
      <Sidebar
        dialer
        modelName="contacts"
        modelId={contact.id}
        campaignId={campaign_id}
        fetchModel={refreshContactInfo}
        icon="user"
        name={contact.full_name}
        editButton={
          !isEmpty(contact) && (
            <EditContactModal
              entityId={entity.id}
              contact={contact}
              onSuccess={refreshContactInfo}
              iconTrigger={true}
            />
          )
        }
        info={[
          {
            title: "Contact Name",
            value: contact.full_name,
          },
          {
            title: "Contact ID",
            value: contact.id,
          },
          {
            title: "Contact Email",
            value: contact.email_addresses
              .filter(e => e.enabled)
              .sort((a, b) => (a.is_primary ? -1 : 0))
              .map(e => (e.is_primary ? e.email + "*" : e.email)),
          },
          {
            title: "Alternate Names",
            value: contact.alternate_names.map(an => an.name),
          },
          {
            title: "Contact Department",
            value: contact.department_name,
          },
          {
            title: "Last Voicemail",
            value:
              dialerInfo.last_voicemail == EPOCH_TIMESTAMP ? null : (
                <RuvixxDate date={dialerInfo.last_voicemail} />
              ),
          },
          {
            title: "Contact Title",
            value: contact.title,
          },
          {
            title: "Contact Phone Number",
            value: contact.phone_numbers.map(pn => {
              return (
                <PhoneDetails number={pn.number} type={pn.type} ext={pn.ext} />
              );
            }),
          },
          {
            title: "Email BCC",
            value: CONFIG.CC_EMAIL ? <CopyCCEmail /> : "Not Configured",
          },
          {
            title: "Contact Tags",
            value: contact.tags,
            type: "tags",
            model: contact,
            modelType: "Contact",
            tags: contactTags,
          },
        ]}
        extraInfo={[
          {
            title: entity.name,
            href: `/entities/${entity.id}`,
            fields: [
              {
                title: "Entity Tags",
                value: entity.tags,
                type: "tags",
                model: entity,
                modelType: "Entity",
                tags: entityTags,
              },
              {
                title: "Website",
                value: entity.urls,
              },
              {
                title: "Account Manager",
                value: entity.account_managers.map(ams => ams.user_full_name),
              },
            ],
          },
        ]}
        custom={contact.custom_fields}
        handleTabChange={handleTabChange}
        contact_id={contact.id}
        entity_id={entity.id}
        contact_phone_numbers={contact.phone_numbers}
        composeEmailModal={({ trigger }) => (
          <SendEmailModal
            open
            trigger={trigger}
            contactId={contact.id || 0}
            campaignId={
              dialerInfo.dial_session &&
              dialerInfo.dial_session.campaign_id !== DEFAULT_CAMPAIGN_ID &&
              dialerInfo.dial_session.campaign_id
            }
            entityId={dialerInfo.entity && dialerInfo.entity.id}
            contactEmail={contact.email || ""}
          />
        )}
        disableEmail={!isContactEmailable(dialerInfo.contact)}
        disableCall={!isContactCallable(dialerInfo.contact)}
      />
    );
  };

  const handleTabChange = (
    e,
    { name, focusOnNewNote = false, auditLogId = null }
  ) => {
    setActiveTab(name);
    setFocusOnNewNote(focusOnNewNote);
    setAuditLogId(auditLogId);
  };

  const renderTabView = () => {
    if (!dialerInfo.contact) {
      return <div>No contact selected...</div>;
    }

    const contactId = dialerInfo?.contact?.id;

    switch (activeTab) {
      case "script":
        return (
          <div style={{ height: "600px" }}>
            <RichTextEditor readOnly />
          </div>
        );

      case "survey":
        if (dialerInfo.form_url) {
          const iframe = {
            __html: `<iframe src="${dialerInfo.form_url}" width="100%" height="100%"></iframe>`,
          };
          return (
            <div
              style={{ height: "500px", width: "75vw" }}
              dangerouslySetInnerHTML={iframe}
            />
          );
        }
        return <div>Questionnaire not set on Dial Session</div>;
      case "activityLog":
        return (
          <AuditLog
            modelId={contactId}
            modelType="Contact"
            auditLogId={auditLogId}
            updateFlag={updateAuditLogFlag}
          />
        );
      case "notes":
        return (
          <NotesView
            modelType="Contact"
            modelId={contactId}
            focusOnNewNote={focusOnNewNote}
            handleTabChange={handleTabChange}
          />
        );
      case "submittedForms":
        return (
          <ResponsesView
            modelId={contactId}
            modelType="Contact"
            fetchEmailedForms={fetchEmailedForms}
          />
        );
      default:
        return null;
    }
  };

  const renderTabMenu = () => {
    return (
      <Menu className="navbar" pointing secondary>
        {tabs.map(({ name, icon }) => {
          const isActive = activeTab === name;
          return (
            <Menu.Item
              key={name}
              name={name}
              active={isActive}
              onClick={handleTabChange}
            >
              <Icon name={icon} size="large" /> {isActive && startCase(name)}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <Container fluid className="route">
      <DialerHeader
        call={call}
        callTags={callTags}
        onTagChange={() => fetchCallInfo(callSid)}
        deviceStatus={deviceStatus}
        dialerInfo={dialerInfo}
        counters={counters}
        monitorMode={true}
      />
      <div className="dialer-ui">
        {renderSidebar()}
        <div className="main">
          <DialerInfo
            deviceStatus={deviceStatus}
            callerId={callerId}
            phoneNumber={currentPhoneNumber}
            makeCall={makeCall}
            hangup={hangup}
            paused={paused}
            monitorMode={true}
          />
          {renderTabMenu()}
          {renderTabView()}
        </div>
      </div>
      <DialerMonitorFooter
        deviceStatus={deviceStatus}
        callInfo={callInfo}
        micState={micState}
        handleLeaveCall={handleLeaveCall}
        unmuteToAgent={unmuteToAgent}
        unmuteToAll={unmuteToAll}
        mute={mute}
      />
    </Container>
  );
}

export default PowerDialerMonitor;
