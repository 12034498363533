import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, List, Pagination } from "semantic-ui-react";
import omit from "lodash/omit";
import { createDateTimeFormatter, objToArr, sortByKey } from "../helpers";
import moment from "moment";
import Filter, { FilterButtons } from "../modals/Filter";

import "./../../styles/tab_view.scss";
import "./../../styles/notes_view.scss";

import ActivityService from "../../services/Activity";
import UserService from "../../services/User";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import NoteItem from "../NoteItem";
import useQueryParam from "../../hooks/params/useQueryParam";
import NoteForm from "../forms/NoteForm";

const NoteFormListItem = withRoleCheck(List.Item, [
  ACL_RELATIONSHIPS.activity.create,
]);

const itemsPerPage = 20;
const formatDateForGrouping = createDateTimeFormatter({ format: "YYYYMM" });

function groupLogByDate(log) {
  const groups = log.reduce((groups, item) => {
    const date = formatDateForGrouping(item.created_at);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});
  return Object.keys(groups).map(date => {
    return {
      date,
      items: groups[date],
    };
  });
}

function paginateLog(log, activePage) {
  const sliceIndex = (activePage - 1) * itemsPerPage;
  return log.slice(sliceIndex, sliceIndex + itemsPerPage);
}

function formatDateGroup(date) {
  return moment(date, "YYYYMM").format("MMMM YYYY");
}

function NotesView({ modelType, modelId, handleTabChange, fluid, callSid }) {
  const [notes, setNotes] = useState([]);
  const [activePage, setActivePage] = useQueryParam("page", 1);
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [options, setOptions] = useState([]);
  const [checked, setChecked] = useState({});
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchUsers();
    fetchCategories();
  }, []);

  useEffect(() => {
    const mappedCategories = categories.map(({ key: id, text: name }) => ({
      id,
      name,
    }));
    setOptions([
      { title: "Category", data: mappedCategories },
      { title: "User", data: users },
      { title: "Has Attachment", type: "checkbox" },
    ]);
  }, [users, categories]);

  const fetchUsers = async () => {
    const users = (await UserService.getUsers()).map(
      ({ id, full_name: name }) => ({ id, name })
    );
    setUsers(users);
  };

  const fetchCategories = async () => {
    const rawCategories = await ActivityService.getCategories();
    const newCategories = rawCategories.map(category => ({
      key: category.id,
      text: category.name,
      value: category.id,
      name: category.name,
      hidden: category.is_hidden,
    }));
    setCategories(newCategories);
  };

  const fetchNotes = useCallback(async () => {
    if (!modelId) return;
    const notes = await ActivityService.getActivityLogs(
      modelId,
      modelType,
      filters
    );
    setNotes(notes);
  }, [modelId, modelType, filters]);

  useEffect(() => {
    (async () => {
      await fetchNotes();
    })();
  }, [fetchNotes, modelId, modelType, filters]);

  useEffect(() => {
    setTotalPages(Math.ceil(notes.length / itemsPerPage));
  }, [notes]);

  function handlePageChange(_, { activePage }) {
    setActivePage(activePage);
  }

  function applyFilter(newChecked = null) {
    const chk = newChecked || checked;
    const filters = options.reduce((accum, { title }) => {
      const val = chk[title];
      const isObj = ["object", "undefined"].includes(typeof val);
      return {
        ...accum,
        [title]: isObj ? objToArr(chk[title]) : val,
      };
    }, {});
    newChecked && setChecked(newChecked);
    setFilters(filters);
  }

  function clearFilter(filter) {
    applyFilter(filter === "Clear All" ? {} : omit(checked, filter));
  }

  const sortedLog = sortByKey(notes, "created_at", true);
  const paginatedLog = paginateLog(sortedLog, activePage);
  const groupedLog = sortByKey(groupLogByDate(paginatedLog), "date", true);
  const className = `notes tabView${fluid ? " fluid" : ""}`;

  return (
    <List className={className}>
      <List.Item className="filterButton">
        <Pagination
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        Filter
        <Filter
          options={options}
          checked={checked}
          updateChecked={setChecked}
          applyFilter={applyFilter}
          trigger={<Button basic size="tiny" icon="filter" />}
          inverted
        />
      </List.Item>
      <List.Item className="filterButtons">
        <FilterButtons
          filters={filters}
          checked={checked}
          clearFilter={clearFilter}
        />
      </List.Item>
      <NoteFormListItem className="form">
        <NoteForm
          callSid={callSid}
          modelType={modelType}
          modelId={modelId}
          updateCategories={setCategories}
          categories={categories}
          onSuccess={fetchNotes}
        />
      </NoteFormListItem>
      {groupedLog.map(({ date, items }, index) => (
        <List.Item key={index}>
          <List.Header>{formatDateGroup(date)}</List.Header>
          <List.Content>
            <Card.Group>
              {items.map(note => (
                <NoteItem
                  key={note.id}
                  note={note}
                  categories={categories}
                  handleTabChange={handleTabChange}
                  onChange={fetchNotes}
                />
              ))}
            </Card.Group>
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
}

export default NotesView;
