import React, { Component } from "react";
import {
  Accordion,
  Button,
  Form,
  Icon,
  Message,
  Modal,
  Segment,
  Select,
} from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import RuvixxForm from "../../../components/RuvixxForm";
import EmailTemplateService from "../../../services/EmailTemplate";
import EmailFooterService from "../../../services/EmailFooters";
import EmailSignatureService from "../../../services/EmailSignatures";
import CampaignService from "../../../services/Campaign";
import SmtpAccountService from "../../../services/SmtpAccounts";
import EmailProviderService from "../../../services/EmailProviders";
import PropTypes from "prop-types";
import UploadManager from "../../../components/UploadManager";
import UploadProgressRing from "../../../components/UploadProgressRing";
import CONSTANTS from "../../../constants/Constants";
import store from "store";
import Validate from "../../../components/Validate";

const { EMAIL_SERVICES } = CONSTANTS;

const DEFAULT_PROVIDER = 5;

class NewCampaignEmailForm extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  static nullOption = {
    key: null,
    value: null,
    text: "None",
  };
  static nullTemplate = {
    id: null,
    name: "",
    subject: "",
    text: "",
    html: "",
    info: {},
  };

  constructor(props) {
    super(props);
    this.uploadManager = new UploadManager();
    this.uploadManager.addProgressListener(this.handleFileProgress);
    this.state = {
      templateOptions: [],
      providerOptions: [],
      smtpOptions: [],
      smtpAccount: {},
      smtp_config_id: "",
      templates: [],
      template: { ...NewCampaignEmailForm.nullTemplate },
      campaignTemplates: [],
      files: [],
      senderName: store.get("userAuth").full_name,
      provider: "",
      fromUser: "",
      cc: "",
      bcc: "",
      errorMessage: null,
      ccAccountManagers: false,
      ccExternalAccountManagers: false,
      ccMenuOpen: false,
      fromDomain: "",
      footerOptions: [],
      footerId: null,
      footerHtml: "",
      signatureOptions: [],
      signatureId: null,
      signatureHtml: "",
      previewHtml: "",
      providerIsSMTP: false,
      providerIsOutlook: false,
      outlookEmailAliases: [],
      outlookAliasSelected: null,
      checkFromField: true,
    };
  }

  async componentDidMount() {
    this.fetchTemplates();

    const smtpAccounts = await SmtpAccountService.getSmtpAccounts();
    const providerOptions = await EmailProviderService.getEmailProviders(
      smtpAccounts.length > 0
    );
    let defaultProvider = [
      { key: 0, text: "Campaign Default", value: DEFAULT_PROVIDER },
    ];
    const smtpOptions = smtpAccounts.map(({ id, return_addr }) => ({
      key: id,
      text: return_addr,
      value: id,
    }));
    const smtpAccount = smtpAccounts.find(({ is_default }) => is_default);

    this.fetchCampaign();
    this.fetchEmailFooters();
    this.fetchEmailSignatures();
    this.fetchOutlookAliases();

    this.setState({
      fromDomain: "email.domain.com", // TODO: fetch from backend
      providerOptions: defaultProvider.concat(providerOptions),
      smtpOptions,
      smtpAccount,
    });
  }

  fetchOutlookAliases = async () => {
    const outlookEmailAliases =
      await EmailProviderService.getEmailAliasesForOutlook();
    this.setState({ outlookEmailAliases });
  };

  templateDisabled = template => {
    let provider = this.state.provider;
    const selectedTemplate = this.state.template;
    const { provider_list: providerList } = template.info;
    if (!provider) return false;
    if (!providerList) return false;
    if (provider === DEFAULT_PROVIDER) {
      provider = this.props.emailService;
    }
    let disabled;
    switch (provider) {
      case CONSTANTS.EMAIL_SERVICES.MAILGUN:
      case CONSTANTS.EMAIL_SERVICES.SENDGRID:
        disabled = !providerList.mailgun_sendgrid;
        break;
      case CONSTANTS.EMAIL_SERVICES.CONSTANT_CONTACT:
        disabled = !providerList.constant_contact;
        break;
      case CONSTANTS.EMAIL_SERVICES.SMTP:
        disabled = !providerList.smtp;
        break;
      default:
        disabled = false;
    }
    if (disabled && selectedTemplate.id === template.id) {
      this.setState(
        {
          template: { ...NewCampaignEmailForm.nullTemplate },
        },
        this.combinedTemplate
      );
    }

    return disabled;
  };

  setTemplateOptions = async () => {
    const { templates, campaignTemplates } = this.state;
    let templateOptions = templates.map(template => {
      let disabled = this.templateDisabled(template);
      let description = disabled ? "Disabled for selected provider" : null;

      if (!template.enabled) {
        disabled = true;
        description = "Empty template";
      }

      return {
        ...template,
        disabled,
        description,
      };
    });
    if (campaignTemplates && campaignTemplates.length > 0) {
      templateOptions = templateOptions.filter(template => {
        return campaignTemplates.includes(template.id);
      });
    }
    this.setState({
      templateOptions,
    });
  };

  fetchTemplates = async () => {
    let templates = await EmailTemplateService.getEmailTemplatesForFilters();
    templates = templates.map(template => ({
      id: template.id,
      key: template.id,
      value: template.id,
      text: template.name,
      info: template.info,
      enabled: template.enabled,
    }));
    this.setState({ templates }, this.setTemplateOptions);
  };

  handleTemplateChange = async (e, { value }) => {
    const { templates } = this.state;
    let selectedTemplate = templates.find(template => {
      return template.id === value;
    });
    selectedTemplate = selectedTemplate || NewCampaignEmailForm.nullTemplate;
    if (selectedTemplate && !selectedTemplate.html) {
      selectedTemplate = await EmailTemplateService.getEmailTemplate(value);
      const idx = templates.indexOf(template => template.id);
      templates[idx] = selectedTemplate;
    }
    this.setState(
      {
        template: selectedTemplate,
        templates: templates,
      },
      this.combinedTemplate
    );
  };

  handleProviderChange = (e, { value }) => {
    const { smtpAccount } = this.state;
    const smtp_config_id =
      value === EMAIL_SERVICES.SMTP && smtpAccount ? smtpAccount.id : null;
    const isConstantContact = value === EMAIL_SERVICES.CONSTANT_CONTACT;
    const isSMTP = value === EMAIL_SERVICES.SMTP;
    const isOutlook = value === EMAIL_SERVICES.OUTLOOK;
    const isConstantContactOrSMTP = isConstantContact || isSMTP;
    this.setState(
      {
        provider: value,
        smtp_config_id,
        providerIsSMTP: isSMTP,
        providerIsOutlook: isOutlook,
        checkFromField: !isConstantContactOrSMTP,
      },
      this.setTemplateOptions
    );
  };

  fetchCampaign = async () => {
    const campaign = await CampaignService.getCampaign(this.props.campaignId);
    this.setState(
      {
        campaignTemplates: campaign.info.selected_templates || [],
      },
      this.setTemplateOptions
    );
  };

  fetchEmailFooters = async () => {
    let footers = await EmailFooterService.getEmailFootersForFilters();
    let footerOptions = footers.map(footer => ({
      key: footer.id,
      value: footer.id,
      text: footer.name,
    }));
    footerOptions.push({ key: null, value: null, text: "None" });
    this.setState({
      footerOptions: footerOptions,
    });
  };

  fetchEmailSignatures = async () => {
    let signatures = await EmailSignatureService.getEmailSignaturesForFilters();
    let signatureOptions = signatures.map(signature => ({
      key: signature.id,
      value: signature.id,
      text: signature.name,
    }));
    signatureOptions.unshift({ key: null, value: null, text: "None" });
    this.setState({
      signatureOptions: signatureOptions,
    });
  };

  handleOutlookAliasesChange = (event, data) =>
    this.setState({ [data.name]: data.value });

  combinedTemplate = () => {
    let template = this.decodeHtml(this.state.template.html);
    if (this.state.footerHtml) {
      let footer = this.decodeHtml(this.state.footerHtml);
      let before = template.indexOf("</body>");
      let combined =
        template.substring(0, before) + footer + template.substring(before);
      this.setState({ previewHtml: combined });
    } else {
      this.setState({ previewHtml: template });
    }
  };

  handleFooterChange = async (e, { value }) => {
    let footerId = value;
    let footerHtml;
    if (!!footerId) {
      footerHtml = (await EmailFooterService.getEmailFooter(footerId)).html;
    } else {
      footerHtml = null;
    }
    this.setState({ footerHtml, footerId }, this.combinedTemplate);
  };

  handleSignatureChange = async (e, { value }) => {
    let signatureId = value;
    let signatureHtml;
    if (!!signatureId) {
      signatureHtml = (
        await EmailSignatureService.getEmailSignature(signatureId)
      ).html;
    } else {
      signatureHtml = null;
    }
    this.setState({ signatureHtml, signatureId }, this.combinedTemplate);
  };

  handleSubmit = async e => {
    const {
      template,
      files,
      senderName,
      cc,
      bcc,
      ccAccountManagers,
      ccExternalAccountManagers,
      fromUser,
      provider,
      smtp_config_id,
      smtpAccount,
      footerId,
      providerIsOutlook,
      outlookAliasSelected,
      checkFromField,
    } = this.state;

    const uploads = files.map(f => f.attachment);

    let fromUserResolved = fromUser;
    if (smtp_config_id) {
      fromUserResolved = smtpAccount.return_addr;
    } else if (providerIsOutlook) {
      fromUserResolved = outlookAliasSelected;
    }

    // TODO: If some files upload succesfully and others fail, it will reupload all
    // of them on the next submit. This will lead to unbound files in the server and
    // could be improved later.

    // Validates that the email user part (THIS_PART@domain.com) is valid.
    if (checkFromField && !Validate.emailUsername(fromUser)) {
      this.setState({ errorMessage: 'Invalid value in "From" field' });
    } else {
      await CampaignService.sendEmailByCampaign({
        campaignId: this.props.campaignId,
        template: template.id,
        attachments: uploads,
        senderName,
        cc,
        bcc,
        ccAccountManagers,
        ccExternalAccountManagers,
        fromUser: fromUserResolved,
        provider,
        footerId,
        ...(smtp_config_id && { smtp_config_id }),
      });
      this.setState({ sentModal: true });
    }
  };

  handleSelect = (e, { value }) => {
    this.setState({ value });
  };

  handleChange = (event, data) => {
    this.setState({
      [data.name]: data.type === "checkbox" ? data.checked : data.value,
    });
  };

  handleFromChange = (event, { value }) => {
    const { smtpOptions } = this.state;
    const smtpAccount = smtpOptions
      .filter(opt => opt.value === value)
      .map(({ value, text }) => ({
        id: value,
        return_addr: text,
      }))[0];
    this.setState({
      smtpAccount,
      smtp_config_id: value,
    });
  };

  decodeHtml = html => {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  handleFileChange = async event => {
    let fileArr = [];
    for (var key in this.uploadInput.files) {
      if (this.uploadInput.files.hasOwnProperty(key)) {
        fileArr.push(this.uploadInput.files[key]);
      }
    }
    this.setState({ files: fileArr });
    await this.uploadManager.uploadFiles(fileArr);
  };

  handleFileProgress = files => {
    this.setState({
      files: [...files],
    });
  };

  handleConfirmationClose = () => {
    this.setState({ sentModal: false });
    this.props.closeParentModal();
  };

  render() {
    const {
      templateOptions,
      providerOptions,
      template,
      files,
      senderName,
      fromUser,
      cc,
      bcc,
      ccAccountManagers,
      ccExternalAccountManagers,
      ccMenuOpen,
      fromDomain,
      provider,
      smtpOptions,
      smtp_config_id,
      footerOptions,
      previewHtml,
      outlookEmailAliases,
      outlookAliasSelected,
      providerIsSMTP,
      providerIsOutlook,
      errorMessage,
    } = this.state;

    let hasFromUserAddress = !!fromUser;
    if (providerIsOutlook) {
      hasFromUserAddress = outlookAliasSelected !== null;
    } else if (providerIsSMTP) {
      hasFromUserAddress = smtp_config_id !== null;
    }

    let usernameField = (
      <>
        <Form.Input
          name="fromUser"
          label="From"
          placeholder="ex. username"
          value={fromUser}
          onChange={this.handleChange}
          required
          error={!!errorMessage}
          readOnly={providerIsSMTP}
        />
        <div
          style={{ paddingTop: "2rem", marginLeft: "-1rem" }}
          hidden={!fromDomain || providerIsSMTP}
        >
          <p>{`@${fromDomain}`}</p>
        </div>
      </>
    );

    if (providerIsSMTP) {
      usernameField = (
        <Form.Field
          required
          control={Select}
          label="From"
          options={smtpOptions}
          onChange={this.handleFromChange}
          value={smtp_config_id}
        />
      );
    } else if (providerIsOutlook) {
      usernameField = (
        <Form.Select
          name="outlookAliasSelected"
          selection
          label="Select an alias"
          options={outlookEmailAliases}
          onChange={this.handleOutlookAliasesChange}
          value={outlookAliasSelected}
          required
        />
      );
    }

    return (
      <>
        <Modal
          open={this.state.sentModal}
          onClose={this.handleConfirmationClose}
        >
          <Modal.Content>
            <Message info>
              <Message.Header>Emails Sent</Message.Header>
            </Message>
            <Button content="ok" onClick={this.handleConfirmationClose} />
          </Modal.Content>
        </Modal>
        <RuvixxForm
          ready={
            !!template.id && !!senderName && !!provider && hasFromUserAddress
          }
          onSubmit={this.handleSubmit}
          onSuccess={!errorMessage ? this.props.onSuccess : () => {}}
          successHeader={"Email Sent"}
          confirmSubmit={true}
          confirmText="Are you sure you want to send this email?"
          ignoreEnterKey={true}
          error={!!errorMessage}
          errorMessage={errorMessage}
        >
          <Form.Select
            selection
            required
            label="Email Provider"
            options={providerOptions}
            placeholder="Select an Email Provider"
            onChange={this.handleProviderChange}
            value={provider}
          />

          <Form.Group widths={"equal"}>
            <Form.Input
              required
              label="Sender Name"
              placeholder="Type in Sender Name"
              onChange={this.handleChange}
              name="senderName"
              value={senderName}
            />
            {usernameField}
          </Form.Group>

          <Accordion active={ccMenuOpen} as={Form.Field}>
            <Accordion.Title
              active={ccMenuOpen}
              index={0}
              onClick={() => this.setState({ ccMenuOpen: !ccMenuOpen })}
            >
              <Icon name="dropdown" />
              CC/BCC Options
            </Accordion.Title>
            <Accordion.Content active={ccMenuOpen}>
              <Form.Group>
                <Form.Checkbox
                  toggle
                  name="ccAccountManagers"
                  checked={ccAccountManagers}
                  onChange={this.handleChange}
                  label="CC Account Managers"
                />
                <Form.Checkbox
                  toggle
                  name="ccExternalAccountManagers"
                  checked={ccExternalAccountManagers}
                  onChange={this.handleChange}
                  label="CC External Account Managers"
                />
              </Form.Group>
              <Form.Input
                label="CC"
                placeholder="email1@test.com,email2@test.com"
                onChange={this.handleChange}
                name="cc"
                value={cc}
              />
              <Form.Input
                label="BCC"
                placeholder="email1@test.com,email2@test.com"
                onChange={this.handleChange}
                name="bcc"
                value={bcc}
              />
            </Accordion.Content>
          </Accordion>
          <Form.Group widths={"equal"}>
            <Form.Select
              search
              required
              label="Email Template"
              value={template.id}
              options={templateOptions}
              placeholder="Select a Template"
              onChange={this.handleTemplateChange}
            />
            <Form.Select
              clearable
              label="Email Footer"
              placeholder="Select an Email Footer"
              onChange={this.handleFooterChange}
              options={footerOptions}
            />
            <Form.Select
              clearable
              label="Email Signature"
              placeholder="Select an Email Signature"
              onChange={this.handleSignatureChange}
              options={this.state.signatureOptions}
            />
          </Form.Group>
          <Form.Field>
            <Button as="label" className="fileButton">
              <Icon name="plus" />
              Upload File
              <input
                ref={ref => (this.uploadInput = ref)}
                type="file"
                hidden
                multiple
                onChange={this.handleFileChange}
              />
            </Button>
            <UploadProgressRing
              uploads={files}
              onDelete={this.uploadManager.removeUpload}
              xOnComplete={true}
            />
          </Form.Field>
          <Form.Field>
            <label>Subject</label>
            <Segment>{template.subject}</Segment>
          </Form.Field>
          <Form.Field>
            <label>Preview</label>
            <Segment>{ReactHtmlParser(previewHtml)}</Segment>
          </Form.Field>
        </RuvixxForm>
      </>
    );
  }
}

export default NewCampaignEmailForm;
