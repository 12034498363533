import React from "react";
import { Container, Grid, Header, Icon } from "semantic-ui-react";

import AgentDashboardTable from "./AgentDashboardTable";

const AgentDashboard = () => (
  <Container fluid className="route">
    <Grid className="bg list-grid" divided>
      <Grid.Row>
        <Grid.Column className="eight wide table-top-buttons">
          <Header>
            <Icon name="phone" />
            Agent Dashboard
          </Header>
        </Grid.Column>
        <Grid.Column className="eight wide table-top-buttons"></Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="sixteen wide">
          <AgentDashboardTable />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);

export default AgentDashboard;
