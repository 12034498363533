import React, { useRef } from "react";
import { Icon, Grid, Button } from "semantic-ui-react";
import { useIncomingCall } from "./IncomingCallContext";
import DialSessionService from "services/DialSessions";
import "./IncomingCall.scoped.scss";
import store from "store";
import { useSocket } from "providers/SocketProvider";

function IncomingCall() {
  const powerDialerRef = useRef(null);
  const {
    isDialogOpen,
    isCalling,
    isSoundOn,
    callInfo,
    toggleMute,
    stopNotification,
    isMinimized,
    setIsMinimized,
    isAgentOnCall,
  } = useIncomingCall();

  const { socket } = useSocket();

  const acceptCall = async () => {
    const sessionData = {
      name: `Contact (incoming call) - ${new Date().toISOString()}`,
      status: true,
      info: {
        enable_recording: true,
      },
    };

    const dialSession = await DialSessionService.createPrivateSession(
      sessionData
    );
    powerDialerRef.current = window.open(
      `/dialer/${dialSession.id}?private=1`,
      "_blank",
      "location=yes,height=750,width=1800,scrollbars=yes,status=yes"
    );

    powerDialerRef.current.onload = () => {
      const messageObject = {
        type: "loadCallInfo",
        callInfo: {
          isIncomingCall: true,
          ...callInfo,
        },
      };
      // Send to powerDialer all needed information about the incoming call
      powerDialerRef.current.postMessage(messageObject, "*");
    };

    const user = store.get("userAuth");
    if (socket) {
      socket.emit(
        "call_accepted",
        JSON.stringify({
          call_sid: callInfo.call_id,
          agent_id: user.id,
        })
      );
    }
  };

  const declineCall = () => {
    stopNotification();
    const user = store.get("userAuth");
    if (socket) {
      socket.emit(
        "call_declined",
        JSON.stringify({
          call_sid: callInfo.call_id,
          agent_id: user.id,
        })
      );
    }
  };

  const getCallerName = () => {
    switch (callInfo?.contacts_with_phone?.length) {
      case 0:
        return "(No contact)";
      case 1:
        return callInfo.contacts_with_phone[0].name;
      default:
        return "More than one contact found with this phone number";
    }
  };

  if (!isDialogOpen) return null;

  return (
    <React.Fragment>
      <div className="incoming-call">
        <div className={`header ${isCalling ? "calling" : ""}`}>
          <Grid>
            <Grid.Row>
              <Grid.Column
                onClick={() => setIsMinimized(!isMinimized)}
                width={14}
              >
                <p className="title">Inbound Call</p>
              </Grid.Column>
              <Grid.Column width={2} className="actions">
                <Icon
                  name={`bell${isSoundOn ? "" : " slash"}`}
                  onClick={toggleMute}
                />
                <Icon name="close" onClick={declineCall} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        {!isMinimized && (
          <div className="content">
            <div className="display">
              <p className="call-title">Incoming Call...</p>
              <div className="caller">
                <p className="name">{getCallerName()}</p>
                <p className="phone">{callInfo.phone_number}</p>
              </div>

              <div className="actions">
                <Button
                  icon
                  className="accept"
                  onClick={acceptCall}
                  disabled={isAgentOnCall()}
                >
                  <Icon name="call" size="large" inverted />
                </Button>
                <Button icon className="decline" onClick={declineCall}>
                  <Icon name="call" size="large" inverted />
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default IncomingCall;
