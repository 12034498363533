import moment from "moment";
import React, { Component, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Header,
  Icon,
  Label,
  Message,
  Modal,
  Dropdown,
} from "semantic-ui-react";
import store from "store";

import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import remove from "lodash/remove";
import sortBy from "lodash/sortBy";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import withDraggableColumns from "react-table-hoc-draggable-columns";
import "react-table-hoc-draggable-columns/dist/styles.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import { DEFAULT_PAGE_SIZE, RANGE_FILTER } from "../constants/Constants";
import tagConstants from "../constants/Tag";
import QueryParamsManager from "../hooks/params/QueryParamsManager";
import { setQueryParams } from "../hooks/params/helpers";
import TableSettings from "../routes/campaign/components/TableSettings";
import TableExportService from "../services/TableExport";
import TagService from "../services/Tag";
import UserService from "../services/User";
import { attachCancelTokenToNextRequest } from "../services/helpers";
import "./../styles/table.scss";
import AdvancedSearch from "./AdvancedSearch";
import CustomPaginationComponent from "./CustomPaginationComponent";
import SearchInput from "./SearchInput";
import TagDropdown from "./TagDropdown";
import {
  CustomLoader,
  generateFilterParamsFromQueryParams,
  generateQueryParamsFromCustomFilters,
  generateQueryParamsFromFilterParams,
  generateQueryParamsFromTagFilters,
  swapElements,
} from "./helpers";

const MAX_PAGE_SIZE = 100;

const ReactTableFixedColumns = withFixedColumns(ReactTable);
const ReactTableFixedDraggableColumns = withDraggableColumns(
  ReactTableFixedColumns
);

let cancelToken = null;

function applyFilters(filters, queryFilters, filterParams) {
  if (!filters) return;
  filters.forEach(({ key, title, type }) => {
    if (type === "input") {
      if (!key.startsWith("search:custom:")) {
        queryFilters[key] = filterParams[title];
      }
    } else if (type === "select" || type === "ruvixxSelect") {
      if (filterParams[title] && filterParams[title].length) {
        queryFilters[key] = filterParams[title].map(item => item.value);
      }
    } else if (type === "dateRange") {
      if (filterParams[title] && filterParams[title].from) {
        queryFilters[key + ":from"] =
          filterParams[title].from.format("YYYY-MM-DD");
      }
      if (filterParams[title] && filterParams[title].to) {
        queryFilters[key + ":to"] = filterParams[title].to.format("YYYY-MM-DD");
      }
    } else if (type === "checkbox" && filterParams[title] === "true") {
      queryFilters[key] = true;
    } else if (type === "range" && filterParams[title]) {
      queryFilters[key + ":type"] = filterParams[title].type;
      queryFilters[key + ":start"] = filterParams[title].start;
      queryFilters[key + ":end"] = filterParams[title].end;
    } else if (
      type === "ruvixxToggle" &&
      String(filterParams[title]) === "true"
    ) {
      queryFilters[key] = true;
    }
  });
}

function applyCustomFilters(customFilters, queryFilters) {
  if (!customFilters) return;
  if (customFilters.length) {
    for (let customFilter of customFilters) {
      queryFilters[customFilter.selectValue] = customFilter.inputValue;
    }
  }
}

function applyTagFilters(tagsFilters, queryFilters) {
  if (!tagsFilters) return;
  if (tagsFilters.length) {
    for (let tagFilter of tagsFilters) {
      if (tagFilter.tags.length) {
        queryFilters[`search:tags:${tagFilter.uuid}`] = JSON.stringify({
          operationType: tagFilter.operationType,
          tags: tagFilter.tags,
        });
      }
    }
  }
}

function applySearchQuery(searchQuery, queryFilters) {
  if (searchQuery) queryFilters.search = encodeURIComponent(searchQuery);
}

function applySortParams(sorted, queryFilters) {
  if (!sorted) return;
  if (sorted[0]) {
    queryFilters.sort_by = sorted[0].id;
    queryFilters.sort_dir = sorted[0].desc ? "desc" : "asc";
  }
}

const DownloadModal = props => {
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [confirmButton, setConfirmButton] = useState();
  const [cancelButton, setCancelButton] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const openPrompt = ({ text, confirm, cancel }) => {
    setMsg(text);
    setConfirmButton(confirm?.text || confirm);
    setCancelButton(cancel?.text || cancel);
  };

  const submitPrompt = confirmed => {
    setConfirmButton(null);
    setCancelButton(null);
    const additionalParams =
      props.downloadConfig.prompt[confirmed ? "confirm" : "cancel"]?.params ||
      {};
    initDownload(additionalParams);
  };

  const initDownload = async (additionalParams = {}) => {
    setLoading(true);
    const {
      searchQuery,
      sorted,
      filters,
      filterParams,
      defaultFilterParams,
      customFilters,
      tagsFilters,
      exportTableName,
      downloadParams,
      visibleColumns,
      showDisabledTags,
      savedSearchName,
    } = props;
    let queryFilters = { ...defaultFilterParams };
    applyFilters(filters, queryFilters, filterParams);
    applyCustomFilters(customFilters, queryFilters);
    applyTagFilters(tagsFilters, queryFilters);
    applySearchQuery(searchQuery, queryFilters);
    applySortParams(sorted, queryFilters);

    if (downloadParams) {
      queryFilters = { ...queryFilters, ...downloadParams };
    }
    if (additionalParams) {
      queryFilters = {
        ...queryFilters,
        additional_params: JSON.stringify(additionalParams),
      };
    }
    queryFilters = {
      ...queryFilters,
      visible_columns: visibleColumns,
      show_disabled_tags: showDisabledTags,
    };

    const resp = await TableExportService.exportTable(
      exportTableName,
      queryFilters,
      savedSearchName
    );
    setLoading(false);
    if (resp === true) {
      setMsg(
        "Your download has started. You'll receive an email when it's finished. You can also check the 'Data' tab for progress and download."
      );
    } else {
      setMsg("An error occurred initiating download.");
    }
  };

  const exportTable = async () => {
    if (props.downloadConfig?.prompt) {
      openPrompt(props.downloadConfig.prompt);
    } else {
      initDownload();
    }
    setOpen(true);
  };

  const redirectToData = () => {
    setOpen(false);
    navigate("/data");
  };

  return (
    <>
      <Button size="mini" icon="download" onClick={exportTable} />
      <Modal
        closeIcon={!loading}
        closeOnDimmerClick={!loading}
        size="small"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Content>
          {loading ? (
            <Icon
              name="circle notched"
              size="large"
              loading
              className="centered"
            />
          ) : (
            <p>{msg}</p>
          )}
        </Modal.Content>
        {cancelButton || confirmButton ? (
          <Modal.Actions>
            {cancelButton && (
              <Button onClick={() => submitPrompt(false)}>
                {cancelButton}
              </Button>
            )}
            {confirmButton && (
              <Button primary onClick={() => submitPrompt(true)}>
                {confirmButton}
              </Button>
            )}
          </Modal.Actions>
        ) : (
          <Modal.Actions>
            <Button onClick={() => setOpen(false)}>OK</Button>
            <Button primary onClick={() => redirectToData()}>
              Go to Data
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    </>
  );
};

class BaseTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: {},
      checkedKey: "id",
      checkedArr: [],
      allSelected: false,

      columns: [],
      visibleColumns: [],
      visibleCustomFields: [],
      freezedColumns: [],
      freezedCustomFields: [],
      resizedColumns: {},

      filterParams: {},
      defaultFilterParams: {},
      defaultQueryParams: {},
      tagsFilters: [],
      customFilters: [],

      rows: [],
      filteredRows: [],
      sorted: [],
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pages: 0,
      total: 0,
      extra: {},
      isLoading: true,
      checkedFilters: {},
      customFields: [],
      filters: [],
      tags: [],
      searchQuery: "",
      enableSearch: true,
      enableTags: false,
      overrideTags: false,
      enableMerge: false,
      enableBatchEdit: false,
      enableCustomFieldsSettings: false,
      mergeMode: false,
      batchEdit: false,
      mergeItems: [],
      createButton: null,
      style: {},
      noDataText: "No records found. Try adjusting your filters.",
      header: null,
      headerIcon: null,
      primaryKey: "id",
      enableSettings: true,
      queryParams: {},
      useParamManager: true,
      firstLoad: false,
      showDisabledTags: false,
      savedSearchName: null,
      downloadConfig: {},
      persistSelection: true,
    };

    this.fetchData = debounce(this.fetchData.bind(this), 300);
    this.oldResized = [];
  }

  getTableSettings(columns) {
    const settings = store.get("userAuth").table_settings;
    const tableSettings =
      settings !== undefined && settings !== null
        ? settings[this.state.tableName] || {}
        : {};
    const visibleColumns =
      tableSettings?.visible_columns ||
      columns
        ?.map(c => c.Header)
        .filter(h => h !== undefined && h !== null && typeof h === "string");
    const freezedColumns = tableSettings.freezed_columns || [];
    const resizedColumns = tableSettings.resized_columns || {};
    const sort = tableSettings.sort || {};
    const pageSize = tableSettings.per_page || DEFAULT_PAGE_SIZE;
    const showDisabledTags = settings.show_disabled_tags || false;
    return {
      visibleColumns,
      freezedColumns,
      resizedColumns,
      pageSize,
      showDisabledTags,
      sort,
    };
  }

  addCustomFieldsColumns = async visibleColumns => {
    return new Promise(resolve => {
      let columns = this.state.columns;
      const customFieldsColumns = visibleColumns
        .filter(
          visibleColumn =>
            !!this.state.customFields.find(
              cf =>
                visibleColumn &&
                visibleColumn.startsWith &&
                visibleColumn.startsWith(`${cf.accessorPrefix}.`)
            )
        )
        .filter(
          visibleColumn => !columns.find(c => c.accessor === visibleColumn)
        )
        .map(c => {
          const cf = this.state.customFields.find(cf =>
            c.startsWith(`${cf.accessorPrefix}.`)
          );

          let customFieldName = cf.headerPrefix ? `${cf.headerPrefix}.` : "";
          customFieldName += c.replace(`${cf.accessorPrefix}.`, "");
          return {
            Header: customFieldName,
            accessor: c,
            show: true,
            Cell: props =>
              Array.isArray(props.value) ? props.value.join(", ") : props.value,
          };
        });
      columns.push(...customFieldsColumns);
      this.setState({ columns }, resolve);
    });
  };

  updateColumns = async (visibleColumns, updateBackend = true) => {
    let columns = this.state.columns;
    columns.forEach(c => {
      c.show = !!(
        typeof c.Header !== "string" ||
        visibleColumns.includes(c.Header) ||
        (c.accessor &&
          c.accessor.startsWith &&
          !!this.state.customFields.find(cf =>
            c.accessor.startsWith(`${cf.accessorPrefix}.`)
          ) &&
          visibleColumns.includes(c.accessor))
      );
    });
    columns = columns.filter(
      c =>
        !(
          c.accessor &&
          c.accessor.startsWith &&
          !!this.state.customFields.find(cf =>
            c.accessor.startsWith(`${cf.accessorPrefix}.`)
          ) &&
          !visibleColumns.includes(c.accessor)
        )
    );
    columns = sortBy(columns, [
      column => {
        const { Header, accessor, fixed } = column;
        if (typeof Header !== "string") {
          return -2 * columns.length; // keep column w/checkboxes before all the others
        }

        let idx = visibleColumns.indexOf(Header);
        if (idx === -1) {
          idx = visibleColumns.indexOf(accessor);
        }
        if (fixed === "left") {
          idx -= columns.length; // keep frozen columns at the start
        } else if (idx === -1 || Header === "Actions") {
          idx = columns.length; // push invisible and action columns to the end
        }
        return idx;
      },
    ]);
    visibleColumns = sortBy(visibleColumns, [
      visibleColumn => {
        return columns.findIndex(
          ({ Header, accessor }) =>
            visibleColumn === Header || visibleColumn === accessor
        );
      },
    ]);
    this.setState({
      columns,
      visibleColumns,
    });
    if (updateBackend) {
      await UserService.updateUserTableSettings(
        this.state.tableName,
        "visible_columns",
        visibleColumns
      );
    }
  };

  freezeColumns = async (freezedColumns, updateBackend = true) => {
    let columns = this.state.columns;
    columns = columns.map(col => {
      if (
        typeof col.Header !== "string" ||
        freezedColumns.includes(col.Header) ||
        (col.accessor &&
          col.accessor.startsWith &&
          !!this.state.customFields.find(cf =>
            col.accessor.startsWith(`${cf.accessorPrefix}.`)
          ) &&
          freezedColumns.includes(col.accessor))
      ) {
        col.fixed = "left";
      } else {
        delete col.fixed;
      }
      return col;
    });

    this.setState({
      columns,
      freezedColumns,
    });
    if (updateBackend) {
      await UserService.updateUserTableSettings(
        this.state.tableName,
        "freezed_columns",
        freezedColumns
      );
    }
  };

  resizeColumns = async (
    resizedColumns,
    { updateColumns = true, updateBackend = true } = {}
  ) => {
    let { columns } = this.state;
    if (updateColumns) {
      columns = columns.map(column => {
        const width = resizedColumns[column.accessor];
        return width ? { ...column, width } : column;
      });
    }
    this.setState({
      columns,
      resizedColumns,
    });
    if (updateBackend) {
      await UserService.updateUserTableSettings(
        this.state.tableName,
        "resized_columns",
        resizedColumns
      );
    }
  };

  changeShowDisabledTags = async (showDisabledTags, updateBackend = true) => {
    if (!this.shouldDisplayTagDropdown()) {
      return;
    }
    let filteredRows = this.state.rows;
    if (!showDisabledTags) {
      const disabledTags = this.state.tags
        ? this.state.tags
            .filter(({ enabled }) => enabled === false)
            .map(({ id }) => id)
        : [];
      filteredRows = this.state.rows.map(row => {
        const filteredTags = row.tags
          ? row.tags.filter(({ id }) => !disabledTags.includes(id))
          : [];
        return { ...row, tags: filteredTags };
      });
    }
    this.setState({
      showDisabledTags,
      filteredRows,
    });
    if (updateBackend) {
      await UserService.updateUserTableSettings(
        this.state.tableName,
        "show_disabled_tags",
        showDisabledTags
      );
    }
  };

  initTableSettings = async columns => {
    const { freezedColumns, visibleColumns, resizedColumns, showDisabledTags } =
      this.getTableSettings(columns);
    let columnsCopy = columns;
    this.setState({ columns: columnsCopy }, async () => {
      await this.addCustomFieldsColumns(visibleColumns);
      await this.freezeColumns(freezedColumns, false);
      await this.updateColumns(visibleColumns, false);
      await this.resizeColumns(resizedColumns, { updateBackend: false });
      await this.changeShowDisabledTags(showDisabledTags, false);
    });
  };

  updateSort = () => {
    const { sort } = this.getTableSettings();
    if (sort.sort_by) {
      this.setState({
        sorted: [
          {
            id: sort.sort_by,
            desc: sort.sort_dir === "desc",
          },
        ],
      });
    }
  };

  updateTableSettings = async ({
    visibleColumns,
    freezedColumns,
    showDisabledTags,
  }) => {
    await this.addCustomFieldsColumns(visibleColumns);
    await this.freezeColumns(freezedColumns);
    await this.updateColumns(visibleColumns);
    await this.changeShowDisabledTags(showDisabledTags);
  };

  fetchData = async () => {
    // TODO: figure out pagination in modals on top of tables
    if (cancelToken) {
      cancelToken.cancel();
    }

    this.updateSort();

    const {
      pageIndex,
      pageSize,
      searchQuery,
      sorted,
      filterParams,
      defaultFilterParams,
      customFilters,
      tagsFilters,
      checked,
      checkedArr,
    } = this.state;
    const filters = this.getFlattenedFilters();
    let queryFilters = { ...defaultFilterParams };
    queryFilters.page = pageIndex + 1; // PageIndex is 0 based
    queryFilters.per_page = pageSize;

    applyFilters(filters, queryFilters, filterParams);
    applyCustomFilters(customFilters, queryFilters);
    applyTagFilters(tagsFilters, queryFilters);
    applySearchQuery(searchQuery, queryFilters);
    applySortParams(sorted, queryFilters);

    this.setState({ isLoading: true });

    cancelToken = attachCancelTokenToNextRequest();
    let data = {};

    try {
      if (this.queryArgs && this.queryArgs.length) {
        data = await this.queryMethod(...this.queryArgs, queryFilters);
      } else {
        data = await this.queryMethod(queryFilters);
      }
      const newRows = data.data;
      const newCheckedArr = checkedArr.filter(itemId =>
        newRows.some(row => row.id === itemId)
      );
      const newChecked = Object.keys(checked)
        .map(k => +k)
        .reduce(
          (obj, cur) =>
            newCheckedArr.includes(cur) ? { ...obj, [cur]: checked[cur] } : obj,
          {}
        );

      this.setState(
        {
          allSelected: false,
          checked: newChecked,
          checkedArr: newCheckedArr,
          isLoading: false,
          rows: newRows,
          filteredRows: newRows,
          pages: data.pages,
          total: data.total,
          extra: data.extra,
        },
        this.doAfterLoad
      );
    } catch (e) {
      data.data = [];
      console.error(e);
      this.setState({ isLoading: false });
    }
  };

  doAfterLoad = () => {
    if (!this.state.firstLoad) {
      if (this.doAfterFirstLoad) {
        this.doAfterFirstLoad();
      }
      this.setState({
        firstLoad: true,
      });
    }
    if (this.doEveryLoad) {
      this.doEveryLoad();
    }
    this.setColumns();
  };

  handleChange = (e, data) => {
    let { checked, checkedArr, mergeMode, mergeItems, rows } = this.state;
    checked[data.id] = data.checked;
    checkedArr.includes(data.id)
      ? checkedArr.splice(checkedArr.indexOf(data.id), 1)
      : checkedArr.push(data.id);
    if (mergeMode) {
      if (data.checked) {
        // Avoid duplicate items
        if (!mergeItems.find(item => item.id === data.id)) {
          mergeItems.push(this.state.rows.find(row => row.id === data.id));
        }
      } else {
        mergeItems = mergeItems.filter(row => row.id !== data.id);
      }
    }
    const allSelected = this.state.rows.length === checkedArr.length;
    this.setState({
      checked: checked,
      checkedArr: checkedArr,
      allSelected: allSelected,
      mergeItems: mergeItems,
    });
  };

  onSelectAll = (e, data) => {
    let { checkedKey, rows, mergeMode, mergeItems } = this.state;
    let checked = data.checked;
    let checkedRows = {};
    let checkedArr = [];

    rows.forEach(row => {
      checkedRows[row[checkedKey]] = checked;
      if (checked) {
        checkedArr.push(row[checkedKey]);
      }
    });
    if (mergeMode && !checked) {
      // deselect current page items on select none
      let rowIds = rows.map(r => r.id);
      mergeItems = mergeItems.filter(item => !rowIds.includes(item.id));
    }

    this.setState({
      allSelected: checked,
      checked: checkedRows,
      checkedArr: checkedArr,
      mergeItems: mergeItems,
    });
  };

  uncheckAll = () => {
    const { rows, checkedKey } = this.state;
    let checked = {};
    rows.forEach(row => {
      checked[row[checkedKey]] = false;
    });
    this.setState({
      allSelected: false,
      checked: checked,
      checkedArr: [],
    });
  };

  toggleMergeMode = () => {
    let { mergeMode, checkedArr, rows } = this.state;
    let mergeItems = [];
    if (!mergeMode) {
      mergeItems = rows.filter(row => checkedArr.includes(row.id));
    }
    this.setState({
      mergeMode: !mergeMode,
      mergeItems: mergeItems,
    });
  };

  toggleBatchEditMode = () => {
    this.setState({
      batchEdit: !this.state.batchEdit,
    });
  };

  finishMerge = () => {
    this.toggleMergeMode();
    this.fetchData();
  };

  finishBatchEdit = () => {
    this.toggleBatchEditMode();
    this.fetchData();
  };

  removeSelectedItem = id => {
    let { checked, checkedArr, mergeItems } = this.state;
    checked[id] = false;
    this.setState({
      mergeItems: mergeItems.filter(row => row.id !== id),
      checkedArr: checkedArr.filter(i => i !== id),
      checked: checked,
    });
  };

  updateSearch = async searchQuery => {
    const { filterParams, filters, queryParams } = this.state;

    filters.forEach(({ key, title }) => {
      if (key.startsWith("search:")) {
        delete filterParams[title];
      }
    });
    this.setState(
      {
        searchQuery,
        filterParams,
        pageIndex: 0,
        queryParams: { ...queryParams, search: searchQuery, page: 1 },
      },
      this.fetchData
    );
  };

  displayTagNames = tagIds => {
    let tagNames = this.state.tags
      .filter(tag => tagIds.includes(tag.id))
      .map(tag => tag.name)
      .join(", ");
    return tagNames;
  };

  getFilterButtons = () => {
    const { filterParams, customFilters, tagsFilters } = this.state;
    const filters = this.getFlattenedFilters();
    let buttons = [];
    Object.keys(filterParams).forEach(groupKey => {
      const filter = filters.find(({ title }) => title === groupKey);
      if (Array.isArray(filterParams[groupKey])) {
        filterParams[groupKey].forEach(item => {
          buttons.push(
            <Button
              basic
              key={groupKey + ":" + item.value}
              size="mini"
              icon="close"
              content={groupKey + ": " + (item.text || item.value)}
              onClick={async () =>
                await this.removeFilter({
                  group: groupKey,
                  item: item,
                })
              }
            />
          );
        });
      } else if (typeof filterParams[groupKey] === "string") {
        const value = filterParams[groupKey];
        buttons.push(
          <Button
            basic
            key={groupKey + ":" + value}
            size="mini"
            icon="close"
            content={groupKey + (value === "true" ? "" : `: ${value}`)}
            onClick={async () =>
              await this.removeFilter({
                group: groupKey,
                item: value,
              })
            }
          />
        );
      } else if (typeof filterParams[groupKey] === "boolean") {
        const value = filterParams[groupKey];
        const toggleFilterColumnNames = ["Has Recording", "Agent Hung Up"];
        if (!(toggleFilterColumnNames.indexOf(groupKey) > -1 && !value)) {
          buttons.push(
            <Button
              basic
              key={groupKey + ":" + value}
              size="mini"
              icon="close"
              content={
                groupKey + (String(value) === "true" ? "" : `: ${value}`)
              }
              onClick={async () =>
                await this.removeFilter({
                  group: groupKey,
                  item: value,
                })
              }
            />
          );
        }
      } else if (
        !!filterParams[groupKey] &&
        filterParams[groupKey].constructor === Object
      ) {
        const value = filterParams[groupKey];
        let content = null;
        if (filter.type === "range") {
          const { type, start, end } = value;
          content = `${groupKey} `;
          if (type === RANGE_FILTER.GREATER_THAN) {
            content += `>= ${start}`;
          } else if (type === RANGE_FILTER.LESS_THAN) {
            content += `<= ${start}`;
          } else {
            content += `between ${start} and ${end}`;
          }
        } else {
          const { from, to } = value;
          if (!!from && !!to) {
            content = `${groupKey} Date From: ${moment(from).format(
              "LL"
            )} - To: ${moment(to).format("LL")}`;
          } else if (!!from) {
            content = `${groupKey} From: ${moment(from).format("LL")}`;
          } else if (!!to) {
            content = `${groupKey} Up to: ${moment(to).format("LL")}`;
          }
        }
        if (content) {
          buttons.push(
            <Button
              basic
              key={groupKey}
              size="mini"
              icon="close"
              content={content}
              onClick={async () =>
                await this.removeFilter({
                  group: groupKey,
                  item: value,
                })
              }
            />
          );
        }
      }
    });

    for (const customFilter of customFilters) {
      if (customFilter.label !== null) {
        buttons.push(
          <Button
            basic
            key={customFilter.uuid}
            size="mini"
            icon="close"
            content={customFilter.label}
            onClick={() => {
              this.onDeleteCustomFilter(customFilter.uuid, true);
              this.fetchData();
            }}
          />
        );
      }
    }

    for (const tagFilter of tagsFilters) {
      if (tagFilter.tags.length) {
        const tagNames = this.displayTagNames(tagFilter.tags);
        buttons.push(
          <Button
            basic
            key={tagFilter.uuid}
            size="mini"
            icon="close"
            content={`${tagFilter.label} ${tagNames}`}
            onClick={() => {
              this.onDeleteTagFilter(tagFilter.uuid, true);
              this.fetchData();
            }}
          />
        );
      }
    }

    if (buttons.length) {
      return (
        <div className="filter-buttons">
          <Button
            basic
            key="removeAll"
            size="mini"
            icon="close"
            content="Clear Filters"
            onClick={this.removeAllFilters}
          />
          {buttons}
        </div>
      );
    }
    return null;
  };

  removeFilter = async ({ group, item }) => {
    const {
      filterParams,
      queryParams: { per_page, search },
    } = this.state;
    const filters = this.getFlattenedFilters();
    let clonedParams = cloneDeep(filterParams);
    if (Array.isArray(clonedParams[group])) {
      remove(clonedParams[group], clonedItem => {
        return isEqual(clonedItem, item);
      });
      if (isEmpty(clonedParams[group])) {
        delete clonedParams[group];
      }
    } else {
      delete clonedParams[group];
    }
    const queryParams = generateQueryParamsFromFilterParams(
      clonedParams,
      filters
    );
    const customFilterQueryParams = generateQueryParamsFromCustomFilters(
      this.state.customFilters
    );
    const tagQueryParams = generateQueryParamsFromTagFilters(
      this.state.tagsFilters
    );

    this.setState(
      {
        pageIndex: 0,
        filterParams: clonedParams,
        queryParams: {
          page: 1,
          per_page,
          search,
          ...queryParams,
          ...customFilterQueryParams,
          ...tagQueryParams,
        },
        savedSearchName: null,
      },
      this.fetchData
    );
  };

  removeAllFilters = async () => {
    const { per_page, search } = this.state.queryParams;
    this.setState(
      {
        pageIndex: 0,
        filterParams: {},
        queryParams: { page: 1, per_page, search },
        tagsFilters: [],
        customFilters: [],
        savedSearchName: null,
      },
      this.fetchData
    );
  };

  getFlattenedFilters = () => {
    return this.state.filters.reduce((accum, cur) => {
      if (cur.type === "grid") {
        const comp = cur.components.map(c => ({ ...c, ...cur.componentProps }));
        return [...accum, ...comp];
      }
      return [...accum, cur];
    }, []);
  };

  updateParams = (
    filterParams,
    tagsFilters,
    customFilters,
    savedSearchName
  ) => {
    let { searchQuery, queryParams } = this.state;
    const filters = this.getFlattenedFilters();
    const clearSearch = Object.keys(filterParams).some(key => {
      const filter = filters.find(({ title }) => title === key);
      return filter && filter.key.startsWith("search:");
    });
    if (clearSearch) {
      searchQuery = "";
    }
    const filterQueryParams = generateQueryParamsFromFilterParams(
      filterParams,
      filters
    );
    const customFilterQueryParams =
      generateQueryParamsFromCustomFilters(customFilters);
    const tagQueryParams = generateQueryParamsFromTagFilters(tagsFilters);
    const pageIndex = 0;

    this.setState(
      {
        pageIndex,
        searchQuery,
        filterParams,
        tagsFilters,
        customFilters,
        queryParams: {
          page: pageIndex + 1,
          per_page: queryParams.per_page,
          search: searchQuery,
          ...filterQueryParams,
          ...customFilterQueryParams,
          ...tagQueryParams,
        },
        savedSearchName,
      },
      this.fetchData
    );
  };

  onDeleteCustomFilter = (uuid, updateQueryParams = false) => {
    let { customFilters, queryParams } = this.state;
    if (updateQueryParams) {
      const customFilter = customFilters.find(filter => filter.uuid === uuid);
      queryParams = Object.keys(queryParams)
        .filter(key => key !== customFilter.selectValue)
        .reduce((obj, key) => ({ ...obj, [key]: queryParams[key] }), {});
    }
    this.setState({
      customFilters: customFilters.filter(filter => filter.uuid !== uuid),
      queryParams,
    });
  };

  onDeleteTagFilter = (uuid, updateQueryParams = false) => {
    let { tagsFilters, queryParams } = this.state;
    if (updateQueryParams) {
      let tags = queryParams.tags.split(";");
      const tagFilter = tagsFilters.find(filter => filter.uuid === uuid);
      const tagsToDelete = tagFilter.tags.join(",");
      tags = tags.filter(tags => tags !== tagsToDelete);
      queryParams = { ...queryParams, tags: tags.join(";") };
    }
    this.setState({
      tagsFilters: tagsFilters.filter(filter => filter.uuid !== uuid),
      queryParams,
    });
  };

  onSortChange = async newSort => {
    await UserService.updateUserTableSettings(this.state.tableName, "sort", {
      sort_by: newSort[0].id,
      sort_dir: newSort[0].desc ? "desc" : "asc",
    });

    this.setState({ pageIndex: 0, sorted: newSort }, this.fetchData);
  };

  onPageChange = async pageIndex => {
    const { queryParams } = this.state;
    this.setState(
      {
        pageIndex,
        queryParams: { ...queryParams, page: pageIndex + 1 },
      },
      this.fetchData
    );
  };

  onPageSizeChange = async (pageSize, pageIndex, updateBackend = true) => {
    const { queryParams } = this.state;
    this.setState(
      {
        pageSize,
        pageIndex,
        queryParams: {
          ...queryParams,
          page: pageIndex + 1,
          per_page: pageSize,
        },
      },
      this.fetchData
    );
    if (updateBackend) {
      UserService.updateUserTableSettings(
        this.state.tableName,
        "per_page",
        pageSize
      );
    }
  };

  // Tag stuff
  fetchTags = async () => {
    let taggableModels = tagConstants["taggableModels"];
    let model = null;
    if (this.state.className === "EmailScheduleContacts") {
      model = "contact";
    } else {
      model = Object.keys(taggableModels).find(
        key => taggableModels[key] === this.state.className
      );
    }
    const tags = await TagService.getTags({ model: model });
    this.setState({ tags }, this.updateFilterOptions);
  };

  getRows = async () => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    while (this.state.isLoading) {
      await delay(1000);
    }
    return this.state.rows;
  };

  onDataUpdated = async () => {
    if (!this.state.persistSelection) {
      this.uncheckAll();
    }
    this.fetchTags();
    this.fetchData();
  };

  handleResizedChange = async newResized => {
    const column = newResized.find(elem => !this.oldResized.includes(elem));
    this.oldResized = newResized;
    if (!column) {
      return;
    }
    const { id, value } = column;
    const newResizedColumns = {
      ...this.state.resizedColumns,
      [id]: value,
    };
    this.resizeColumns(newResizedColumns, { updateColumns: false });
  };

  handleDropSuccess = async (draggedCol, targetCol) => {
    const { visibleColumns } = this.state;
    const getIndex = column => {
      const header = column.Header;
      const name = visibleColumns.includes(header) ? header : column.accessor;
      return visibleColumns.indexOf(name);
    };
    let draggedColIdx = getIndex(draggedCol);
    let targetColIdx = getIndex(targetCol);
    draggedColIdx = draggedColIdx < 0 ? 0 : draggedColIdx;
    targetColIdx = targetColIdx < 0 ? 0 : targetColIdx;

    const newVisibleColumns = swapElements(
      visibleColumns,
      draggedColIdx,
      targetColIdx
    );
    await this.updateColumns(newVisibleColumns);
  };

  updateQueryParams = async queryParams => {
    const filters = this.getFlattenedFilters();
    let { per_page } = queryParams;
    const { page, search, tags, ...filterQueryParams } = queryParams;
    const pageIndex = page - 1;
    let pageSize;
    if (per_page > MAX_PAGE_SIZE) {
      // prevent users from entering per_page > 100 in URL
      setQueryParams({ page: 1, per_page: MAX_PAGE_SIZE });
      queryParams.per_page = MAX_PAGE_SIZE;
      pageSize = MAX_PAGE_SIZE;
    } else {
      pageSize = per_page;
    }
    const discriminatedParams = Object.keys(filterQueryParams).reduce(
      (obj, key) => {
        const type = key.startsWith("search:custom:") ? "custom" : "regular";
        return {
          ...obj,
          [type]: { ...obj[type], [key]: filterQueryParams[key] },
        };
      },
      { regular: {}, custom: {} }
    );
    const filterParams = await generateFilterParamsFromQueryParams(
      discriminatedParams.regular,
      filters
    );
    const customFilters = Object.keys(discriminatedParams.custom).map(
      (key, idx) => {
        const value = discriminatedParams.custom[key];
        const label = (filters.find(filter => filter.key === key) || {}).title;
        return { selectValue: key, inputValue: value, label, uuid: idx };
      }
    );
    const tagsFilters = (tags ? tags.split(";") : []).map((str, idx) => {
      const excludes = str.indexOf("-") === 0;
      str = str.slice(+excludes);
      return {
        operationType: excludes ? "excludes" : "contains",
        label: excludes ? "Exclude tags: " : "Tags: ",
        tags: str.split(",").map(Number),
        uuid: idx,
      };
    });
    this.setState(
      {
        queryParams,
        pageIndex,
        pageSize,
        searchQuery: search,
        filterParams,
        customFilters,
        ...(tagsFilters && { tagsFilters }),
      },
      () => {
        if (!this.state.isLoading || Object.keys(filterParams).length > 0) {
          this.fetchData();
        }
      }
    );
  };

  shouldDisplayTagDropdown() {
    let { tags, enableTags, overrideTags } = this.state;
    if (tags.length === 0 || overrideTags === true) {
      return false;
    }
    if (!enableTags) {
      const isExternalUser = store.get("userAuth").external;
      enableTags = !isExternalUser;
    }
    return enableTags;
  }

  renderMergeButton(dropdown = false) {
    if (!this.mergeModal) return null;
    const { mergeItems, mergeMode } = this.state;
    if (mergeItems.length !== 2) {
      const Element = dropdown ? Dropdown.Item : Button;
      return (
        <Element
          content={mergeMode ? "Disable Merge Mode" : "Merge Mode"}
          size="mini"
          onClick={this.toggleMergeMode}
        />
      );
    }

    return this.mergeModal();
  }

  renderBatchEditButton() {
    if (!this.batchEditModal) return null;
    const { checkedArr, batchEdit } = this.state;

    if (!batchEdit || checkedArr.length === 0) {
      return (
        <Button
          content={batchEdit ? "Disable Batch Edit" : "Batch Edit"}
          size="mini"
          onClick={this.toggleBatchEditMode}
        />
      );
    }
    return this.batchEditModal();
  }

  tableLeft() {
    return this.props.tableLeft && <>{this.props.tableLeft}</>;
  }

  renderTable() {
    const {
      tableName,
      columns,
      filteredRows: rows,
      pageSize,
      pageIndex,
      pages,
      total,
      isLoading,
      noDataText,
      sorted,
      filters,
      filterParams,
      defaultFilterParams,
      customFilters,
      tagsFilters,
      enableSearch,
      searchQuery,
      visibleColumns,
      freezedColumns,
      createButton,
      header,
      headerIcon,
      className,
      secondaryClassName,
      tags,
      checkedArr,
      enableMerge,
      enableBatchEdit,
      mergeMode,
      batchEdit,
      mergeItems,
      enableSettings,
      exportTableName,
      style,
      queryParams,
      useParamManager,
      enableCustomFieldsSettings,
      customFields,
      defaultQueryParams,
      showDisabledTags,
      savedSearchName,
      downloadConfig,
    } = this.state;
    const settings = store.get("userAuth").table_settings;
    const tableSettings =
      settings !== undefined && settings !== null
        ? settings[tableName] || {}
        : {};
    let filteredTags = tags;
    if (!showDisabledTags) {
      filteredTags = tags.filter(({ enabled }) => enabled === true);
    }
    const displayTagDropdown = this.shouldDisplayTagDropdown();
    function withPageSize(Component, pageSize) {
      return function WrappedComponent(props) {
        const { ...otherProps } = props;
        const loaderStyle = pageSize <= 10 ? { top: "10%" } : {};
        return <Component {...otherProps} style={loaderStyle} />;
      };
    }

    const CustomLoaderWithPageSize = withPageSize(CustomLoader, pageSize);

    return (
      <React.Fragment>
        {useParamManager ? (
          <QueryParamsManager
            defaultParams={{
              page: 1,
              per_page: tableSettings.per_page || DEFAULT_PAGE_SIZE,
              ...defaultQueryParams,
            }}
            queryParams={queryParams}
            setQueryParams={this.updateQueryParams}
            dependencies={[filters]}
          />
        ) : null}
        <div className="table-top-buttons">
          {headerIcon || header ? (
            <Header>
              {headerIcon ? <Icon name={headerIcon} /> : null}
              {header}
            </Header>
          ) : null}
          {createButton}
          {enableSearch ? (
            <SearchInput
              name="search"
              query={searchQuery}
              onUpdateQuery={this.updateSearch}
            />
          ) : null}
          {filters.length ? (
            <AdvancedSearch
              filters={filters}
              filterParams={filterParams}
              tagsFilters={tagsFilters}
              customFilters={customFilters}
              updateFilterParams={this.updateParams.bind(this)}
              tableName={tableName}
              modelType={className}
              secondaryModelType={secondaryClassName}
              savedSearchName={savedSearchName}
            />
          ) : null}
          {this.tableLeft()}
          {(this.actionsDropdown || displayTagDropdown || enableSettings) && (
            <div className="table-options">
              {this.actionsDropdown ? this.actionsDropdown() : null}
              {this.topElements ? this.topElements() : null}
              {enableMerge ? this.renderMergeButton() : null}
              {enableBatchEdit ? this.renderBatchEditButton() : null}
              {displayTagDropdown ? (
                <TagDropdown
                  key={`table-tag-dropdown-${tableName}`}
                  rows={rows}
                  modelType={className}
                  tags={filteredTags}
                  checkedArr={checkedArr}
                  onSuccess={this.onDataUpdated}
                  accessor={this.state.primaryKey}
                  queryParams={queryParams}
                  isMerging={mergeMode}
                />
              ) : null}
              {exportTableName && (
                <DownloadModal
                  searchQuery={searchQuery}
                  filters={this.getFlattenedFilters()}
                  filterParams={filterParams}
                  defaultFilterParams={defaultFilterParams}
                  customFilters={customFilters}
                  tagsFilters={tagsFilters}
                  exportTableName={exportTableName}
                  exportTable={this.exportTable}
                  downloadParams={this.downloadParams}
                  visibleColumns={visibleColumns}
                  showDisabledTags={showDisabledTags}
                  savedSearchName={savedSearchName}
                  downloadConfig={downloadConfig}
                />
              )}
              {enableSettings && (
                <TableSettings
                  tableName={this.state.tableName}
                  allColumns={this.state.columns}
                  visibleColumns={visibleColumns}
                  freezedColumns={freezedColumns}
                  updateTableSettings={this.updateTableSettings}
                  enableCustomFieldsSettings={enableCustomFieldsSettings}
                  customFields={customFields}
                  showDisabledTags={showDisabledTags}
                />
              )}
            </div>
          )}
        </div>
        {mergeMode && (
          <div className="table-merge-items">
            <Message error={mergeItems.length > 2}>
              {mergeItems.length > 2 && (
                <p>Only 2 items can be merged. Remove any extra items.</p>
              )}
              <p>
                {mergeItems.length > 0
                  ? "Merging: "
                  : "Merge mode enabled. Select 2 items for merging"}
                {mergeItems.map(row => (
                  <Label as="a" key={row.id}>
                    {row.full_name || row.name}
                    <Icon
                      name="delete"
                      onClick={() => this.removeSelectedItem(row.id)}
                    />
                  </Label>
                ))}
              </p>
            </Message>
          </div>
        )}
        {batchEdit && (
          <div className="table-merge-items">
            <Message>
              <p>
                {checkedArr.length > 0
                  ? "Batch Editing: "
                  : "Batch edit enabled. Select at least one item to edit"}
                {checkedArr.map(id => (
                  <Label as="a" key={id}>
                    {id}
                    <Icon
                      name="delete"
                      onClick={() => this.removeSelectedItem(id)}
                    />
                  </Label>
                ))}
              </p>
            </Message>
          </div>
        )}
        {this.getFilterButtons()}
        <ReactTableFixedDraggableColumns
          key={visibleColumns.join()} // force re-render on columns change
          style={style}
          columns={columns}
          className={className}
          data={rows}
          defaultPageSize={+pageSize || DEFAULT_PAGE_SIZE}
          loading={isLoading}
          LoadingComponent={CustomLoaderWithPageSize}
          manual // server side pagination
          noDataText={noDataText}
          onPageChange={this.onPageChange}
          onPageSizeChange={this.onPageSizeChange}
          page={pageIndex}
          pages={pages}
          pageSize={+pageSize || DEFAULT_PAGE_SIZE}
          totalRows={total}
          PaginationComponent={CustomPaginationComponent}
          tableName={tableName}
          sorted={sorted}
          onSortedChange={this.onSortChange}
          draggableColumns={{
            mode: "reorder",
            draggable: columns
              .filter(
                c =>
                  typeof c.Header === "string" &&
                  (!("show" in c) || c.show === true)
              )
              .map(c => c.accessor),
            onDropSuccess: debounce(this.handleDropSuccess, 300),
          }}
          onResizedChange={debounce(this.handleResizedChange, 300)}
        />
      </React.Fragment>
    );
  }

  render() {
    return this.renderTable();
  }
}

export default BaseTable;
