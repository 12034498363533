import React, { useState } from "react";
import { Dropdown, Header, Modal } from "semantic-ui-react";
import TranferContactsForm from "../forms/TransferContactsForm";

const TransferContacts = ({ checkedArr, uncheckAll, fetchData, isMerging }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    uncheckAll();
    setModalOpen(false);
    fetchData();
  };

  return (
    <>
      <Dropdown.Item
        onClick={handleOpen}
        content="Transfer Contacts"
        disabled={checkedArr.length == 0 || isMerging}
      ></Dropdown.Item>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        closeOnDimmerClick={false}
        size="small"
        closeIcon
        style={{ maxHeight: "50%" }}
      >
        <Header content="Move Contacts to Entity" />
        <Modal.Content>
          <TranferContactsForm
            contactIds={checkedArr}
            onSuccess={handleClose}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default TransferContacts;
