import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "components/RuvixxForm";

import "styles/forms.scss";

import revOppsConstants from "constants/RevenueOpportunity";
import { upperFirst } from "helpers/string";
import RecipientService from "services/Recipient";

const formReducer = (s, a) => ({ ...s, ...a });
const initializer = model => {
  return {
    id: model?.id,
    name: "",
    types: [],
  };
};

function RecipientForm(props) {
  const [formData, setFormData] = useReducer(
    formReducer,
    props.model,
    initializer
  );
  const [recipientTypeOptions, setRecipientTypeOptions] = useState([]);
  const [error, setError] = useState(null);

  const fetchModel = useCallback(async () => {
    if (!formData.id) {
      return;
    }
    const model = await RecipientService.get(formData.id);
    setFormData({
      ...formData,
      id: model.id,
      name: model.name,
      types: model.types,
    });
  }, []);

  useEffect(() => {
    prepareRecipientTypeOptions();
  }, []);

  useEffect(() => {
    if (formData.id) {
      fetchModel();
    }
  }, [formData.id]);

  const handleChange = async (_, { name, value }) => {
    if (!name) {
      return;
    }
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const prepareRecipientTypeOptions = async () => {
    const options = Object.values(revOppsConstants.recipientType).map(
      (option, index) => ({
        id: index,
        text: upperFirst(option),
        value: option,
      })
    );
    setRecipientTypeOptions(options);
  };

  const handleSubmit = async () => {
    const { id, name, types } = formData;
    const data = {
      name,
      types,
    };

    try {
      if (id) {
        await RecipientService.update(id, data);
      } else {
        await RecipientService.create(data);
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      setError(message);
      throw new Error(message);
    }
  };

  return (
    <RuvixxForm
      ready={!!formData.name}
      onSubmit={handleSubmit}
      onSuccess={props.onSuccess}
      error={error?.error}
      errorMessage={error?.errorMessage}
      errorTitle={error?.errorTitle}
    >
      <Form.Input
        required
        autoFocus
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <Form.Select
        label="Choose Recipient Type"
        required
        clearable
        name="types"
        className="fillSpace"
        multiple
        search
        selection
        onChange={handleChange}
        options={recipientTypeOptions}
        value={formData.types}
      />
    </RuvixxForm>
  );
}

export default RecipientForm;
