import React, { Component, cloneElement } from "react";
import {
  Button,
  Header,
  Icon,
  Modal,
  Popup,
  Dropdown,
  Message,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import TableButton from "../TableButton";

export default class ConfirmationModal extends Component {
  static propTypes = {
    actionDescription: PropTypes.string.isRequired,
    icon: PropTypes.string,
    circularIcon: PropTypes.bool,
    buttonColor: PropTypes.string,
    buttonText: PropTypes.string,
    style: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    dontAwait: PropTypes.bool,
    menuTrigger: PropTypes.bool,
    iconTrigger: PropTypes.bool,
    simplePopup: PropTypes.bool,
    popupPosition: PropTypes.string,
    tableButton: PropTypes.string,
    content: PropTypes.node,
    warning: PropTypes.bool,
  };

  static defaultProps = {
    simplePopup: true,
    popupPosition: "left",
    disabled: false,
    warning: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      error: null,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ error: null });
    this.setState({ modalOpen: false });
  };

  handleConfirm = async () => {
    let response = await this.props.onConfirm();
    if (response) {
      this.setState({ error: response.message });
    } else {
      this.handleClose();
    }
  };

  tableButtonTrigger = () => (
    <TableButton
      edit={this.props.tableButton === "edit"}
      delete={this.props.tableButton === "delete"}
      description={this.props.actionDescription}
      onClick={this.handleOpen}
    />
  );

  popupTrigger = () =>
    this.props.simplePopup ? (
      <Popup
        position={`top ${this.props.popupPosition}`}
        content={this.props.actionDescription}
        trigger={
          <Button
            style={this.props.style}
            color={this.props.buttonColor}
            inverted
            onClick={this.handleOpen}
            size={this.props.circularIcon ? "mini" : "tiny"}
            disabled={this.props.disabled}
            icon={!this.props.buttonText}
          >
            {this.props.icon ? (
              this.props.circularIcon ? (
                <Icon.Group
                  style={{ verticalAlign: "2.3px", marginRight: "5px" }}
                >
                  <Icon size="large" name="circle outline" />
                  <Icon
                    size="small"
                    name={this.props.icon}
                    style={{ transform: "translate(-7px, -4px)" }}
                  />
                </Icon.Group>
              ) : (
                <Icon
                  name={this.props.icon}
                  style={{ ...(!this.props.buttonText && { margin: 0 }) }}
                />
              )
            ) : null}
            <span
              style={{
                ...(this.props.circularIcon && { fontSize: "1.1em" }),
              }}
            >
              {this.props.buttonText}
            </span>
          </Button>
        }
      />
    ) : (
      <Popup
        position={this.props.popupPosition}
        content={this.props.actionDescription}
        trigger={
          <Button
            onClick={this.handleOpen}
            size="tiny"
            disabled={this.props.disabled}
          />
        }
      />
    );

  menuTrigger2 = () => (
    <Button className="action-button" onClick={this.handleOpen}>
      {this.props.actionDescription}
    </Button>
  );

  menuTrigger = () => (
    <Dropdown.Item
      onClick={this.handleOpen}
      icon={this.props.icon}
      content={this.props.actionDescription}
      disabled={this.props.disabled}
    />
  );

  iconTrigger = () => (
    <Button basic icon onClick={this.handleOpen}>
      <Icon name={this.props.icon} />
    </Button>
  );

  getTrigger = () => {
    if (this.props.trigger) {
      return cloneElement(this.props.trigger, {
        onClick: this.handleOpen,
      });
    }
    if (this.props.tableButton) {
      return this.tableButtonTrigger();
    }
    if (this.props.menuTrigger) {
      return this.menuTrigger();
    }
    if (this.props.iconTrigger) {
      return this.iconTrigger();
    }
    return this.popupTrigger();
  };

  render() {
    let { actionDescription, content, children, warning } = this.props;
    const cancelClass = `cancel ${warning ? `warning` : ""}`;
    const confirmClass = `confirm ${warning ? `warning` : ""}`;

    return (
      <Modal
        trigger={this.getTrigger()}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        size="small"
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        closeIcon
      >
        <Header
          icon={warning ? "warning sign" : null}
          content={actionDescription}
        />
        <Modal.Content>
          {content || children}
          {this.state.error ? (
            <Message
              error
              header={`Action Failed`}
              content={this.state.error}
            />
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Button className={cancelClass} onClick={this.handleClose}>
            <Icon name="remove" /> No
          </Button>
          <Button className={confirmClass} onClick={this.handleConfirm}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
