import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import { USER_STATUSES } from "constants/Constants";
import { useUserStatus } from "components/UserStatus/UserStatusContext";
import { useSocket } from "providers/SocketProvider";

const IncomingCallContext = createContext();

export const useIncomingCall = () => useContext(IncomingCallContext);

export const IncomingCallProvider = ({ children }) => {
  const audioRef = useRef(new Audio("/notification.mp3"));
  const audioIntervalRef = useRef(null);

  const { socket } = useSocket();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [isCalling, setIsCalling] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(true);

  const [callInfo, setCallInfo] = useState({});
  const callInfoRef = useRef(callInfo);

  const [acceptCallFn, setAcceptCallFn] = useState();

  const { status } = useUserStatus();

  const openDialog = (isMinimezed = false) => {
    setIsMinimized(isMinimezed);
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    stopAudio();
    setIsDialogOpen(false);
  };

  useEffect(() => {
    callInfoRef.current = callInfo;
    if (callInfo.call_id) {
      triggerNotification();
      setIsCalling(true);
    }
  }, [callInfo]);

  useEffect(() => {
    if (socket) {
      socket.on("calling", data => {
        if (!callInfoRef.current.call_id) {
          setCallInfo(data);
        }
      });

      socket.on("stop_notification", data => {
        stopNotification();
      });
    }
  }, [socket]);

  const isPowerDialer = () => {
    return window.location.pathname.startsWith("/dialer");
  };

  const isAgentOnCall = () => {
    return status === USER_STATUSES.ON_CALL.key;
  };

  const triggerNotification = async () => {
    switch (status) {
      case USER_STATUSES.ONLINE.key:
        openDialog();
        startAudio();

        // Desktop notification
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            const notification = new Notification("You're receiving a Call!");
            notification.onclick = () => window.focus();
          }
        });
        break;
      case USER_STATUSES.ON_CALL.key:
        const openWidgetMinimized = isPowerDialer();
        openDialog(openWidgetMinimized);
        break;
    }
  };

  const stopNotification = () => {
    setIsCalling(false);
    setCallInfo({});
    stopAudio();
    closeDialog();
  };

  const startAudio = () => {
    const audio = audioRef.current;
    audio.volume = 0.5;
    audio.play();
    audioIntervalRef.current = setInterval(() => {
      audio.currentTime = 0;
      audio.play();
    }, 5000);
  };

  const stopAudio = () => {
    clearInterval(audioIntervalRef.current);
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const toggleMute = () => {
    const audio = audioRef.current;
    if (isSoundOn) {
      audio.volume = 0;
    } else {
      audio.volume = 0.5;
    }
    setIsSoundOn(!isSoundOn);
  };

  return (
    <IncomingCallContext.Provider
      value={{
        isDialogOpen,
        isCalling,
        isSoundOn,
        callInfo,
        isMinimized,
        setCallInfo,
        openDialog,
        closeDialog,
        startAudio,
        stopAudio,
        toggleMute,
        stopNotification,
        setIsMinimized,
        isPowerDialer,
        isAgentOnCall,
        acceptCallFn,
      }}
    >
      {children}
    </IncomingCallContext.Provider>
  );
};
