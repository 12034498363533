import React from "react";
import { cleanString } from "../../../helpers/string";
import AddContactForm from "./AddContact";

class AppendContactForm extends AddContactForm {
  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      error: false,
    });
    const {
      fullName,
      alternateNames,
      preferredName,
      emailAddresses,
      title,
      phoneNumbers,
      custom,
    } = this.state;
    let cust = {};

    custom.forEach(item => {
      cust[item.key] = item.value;
    });

    const contactsUnique = this.contactIsUnique(emailAddresses, fullName);

    const nonEmptyEmailAddresses = emailAddresses.filter((email, i) =>
      email.email?.trim()
    );
    const nonEmptyPhoneNumbers = phoneNumbers.filter((pn, i) =>
      pn.number?.trim()
    );

    if (contactsUnique.unique) {
      this.props.addContact({
        full_name: fullName,
        alternate_names: alternateNames,
        preferred_name: preferredName,
        email_addresses: nonEmptyEmailAddresses,
        title: title,
        phone_numbers: nonEmptyPhoneNumbers,
        info: { custom: cust },
        custom_fields: cust,
      }); // maybe-promise
      this.props.handleClose();
    } else {
      this.setState({
        error: true,
        errorMessage: contactsUnique.error,
        errorTitle: "",
      });
    }
  };

  contactIsUnique = (emailAddresses, fullName) => {
    let unique = true;

    if (this.props.contacts.length === 0) {
      return {
        unique,
      };
    }

    let error = null;
    for (let c of this.props.contacts) {
      const foundAddresses = emailAddresses.some(e =>
        c.email_addresses
          .map(e2 => cleanString(e2.email))
          .includes(cleanString(e.email))
      );
      if (foundAddresses) {
        unique = false;
        error = `Email must be unique. One of ${emailAddresses.join(
          ", "
        )} is already taken.`;
        break;
      }
      if (cleanString(c.full_name) === cleanString(fullName)) {
        unique = false;
        error = `Name must be unique if email is not present. ${fullName} is already taken.`;
        break;
      }
    }

    return {
      unique,
      error,
    };
  };
}

export default AppendContactForm;
