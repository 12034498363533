import React, { useState, useRef, useEffect } from "react";
import { Form } from "semantic-ui-react";
import { trimText } from "helpers/string";

import "./FileInput.scoped.scss";

const FileInput = ({ onChange, value, ...inputProps }) => {
  const [fileName, setFileName] = useState(value);
  const inputRef = useRef();

  useEffect(() => {
    setFileName(value);
  }, [value]);

  const handleBrowse = () => {
    inputRef.current.click();
  };

  const handleChange = () => {
    const files = Array.from(inputRef.current.files);
    if (files.length === 1) {
      setFileName(files[0].name);
    }
    if (onChange) {
      onChange(files);
    }
  };

  return (
    <>
      <div className="file-input" onClick={handleBrowse}>
        <button>Browse…</button>
        <span>{fileName ? trimText(fileName, 30) : "No file selected."}</span>
      </div>
      <input
        ref={inputRef}
        type="file"
        title=""
        onChange={handleChange}
        {...inputProps}
      />
    </>
  );
};

export default FileInput;
