import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Divider, Icon, Menu } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import CopyCCEmail from "../../components/CopyCCEmail";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import RevenueOpportunityTable from "../../components/RevenueOpportunityTable";

import Sidebar from "../../components/Sidebar";
import AuditLog from "../../components/views/AuditLog";
import CampaignTable from "../../components/views/CampaignTable";
import NotesView from "../../components/views/NotesView";
import ResponsesView from "../../components/views/ResponsesView";
import CONFIG from "../../Config";
import { generateTabs } from "../../constants/Constants";
import { noTag } from "../../constants/Tag";
import { buildSidebarAddresses } from "../../helpers/address";
import setPageTitle from "../../helpers/title";
import useRouteParam from "../../hooks/params/useRouteParam";
import useTags from "../../hooks/useTags";
import AuthService from "../../services/Auth";
import { SendEmailModal as _SendEmailModal } from "../campaign/components/Modals";
import CasesTable from "../case/CasesTable";
import TaskList from "../tasks/TaskList";

import EntityService from "./../../services/Entity";
import EntityContactsTable from "./components/ContactsTable";
import ContractTable from "./components/ContractsTable";
import DepartmentTable from "./components/DepartmentsTable";

import { EditEntityModal as _EditEntityModal } from "./components/Modals";
import ActivityService from "../../services/Activity";

const EditEntityModal = withRoleCheck(_EditEntityModal, [
  ACL_RELATIONSHIPS.entity.edit,
  ACL_RELATIONSHIPS.user.read,
]);
const SendEmailModal = withRoleCheck(_SendEmailModal, [
  ACL_RELATIONSHIPS.emailTemplates.read,
  ACL_RELATIONSHIPS.entityEmail.create,
]);

let enabledTabs = [
  "activityLog",
  "notes",
  "responses",
  "contacts",
  "departments",
  "campaigns",
  "tasks",
  "contracts",
  "revenueOpportunities",
];
if (CONFIG.ENABLE_PIRACY) {
  enabledTabs.push("cases");
}

const tabs = generateTabs(...enabledTabs);

const Entity = () => {
  const [focusOnNewNote, setFocusOnNewNote] = useState(false);
  const [auditLogId, setAuditLogId] = useState(null);
  const [model, setModel] = useState({});
  const [activeTab, setActiveTab] = useRouteParam("tab", tabs[0].name);
  const [entityId] = useRouteParam("id");
  const tags = useTags("entity");
  const [hasNotes, setHasNotes] = useState(false);
  const [updateAuditLogFlag, setUpdateAuditLogFlag] = useState(0);

  const fetchNotes = useCallback(async () => {
    if (!entityId) return;
    const result = await ActivityService.hasNotes(entityId, "Entity");
    setHasNotes(result);
  }, [entityId]);

  useEffect(() => {
    if (AuthService.isLoggedIn() && entityId) {
      fetchEntity(); // don't await
      // don't await
      (async () => {
        await fetchNotes();
      })();
    }
  }, [entityId, fetchNotes]);

  const fetchEntity = async () => {
    let model = await EntityService.getEntity(entityId);

    if (model.tags.length === 0) {
      model.tags = [noTag];
    }
    setModel(model);
    setPageTitle(model.name);
    setUpdateAuditLogFlag(updateAuditLogFlag + 1);
  };

  const fetchEmailedForms = async filters => {
    filters = filters || {};
    return await EntityService.getEmails(entityId, filters);
  };

  const handleTabChange = (
    e,
    { name, focusOnNewNote = false, auditLogId = null }
  ) => {
    setFocusOnNewNote(focusOnNewNote);
    setAuditLogId(auditLogId);
    setActiveTab(name);
  };

  const renderTabView = useMemo(() => {
    if (!entityId || isEmpty(model)) {
      return null;
    }
    switch (activeTab) {
      case "activityLog":
        return (
          <AuditLog
            modelId={entityId}
            modelType="Entity"
            auditLogId={auditLogId}
            updateFlag={updateAuditLogFlag}
          />
        );
      case "notes":
        return (
          <NotesView
            modelType="Entity"
            modelId={entityId}
            focusOnNewNote={focusOnNewNote}
            handleTabChange={handleTabChange}
          />
        );
      case "responses":
        return (
          <ResponsesView
            modelType="Entity"
            modelId={entityId}
            fetchEmailedForms={fetchEmailedForms}
            displayRecipient={true}
          />
        );
      case "contacts":
        return <EntityContactsTable entityId={entityId} />;
      case "departments":
        return <DepartmentTable entityId={entityId} />;
      case "campaigns":
        return <CampaignTable entityId={entityId} />;
      case "tasks":
        return <TaskList modelType="Entity" modelId={entityId} />;
      case "contracts":
        return <ContractTable entityId={entityId} />;
      case "revenueOpportunities":
        return <RevenueOpportunityTable entityId={entityId} />;
      case "cases":
        return <CasesTable entityId={entityId} />;
      default:
        return null;
    }
  }, [
    activeTab,
    entityId,
    model,
    auditLogId,
    focusOnNewNote,
    updateAuditLogFlag,
  ]);

  const renderSidebar = () => (
    <Sidebar
      modelName="entities"
      modelId={entityId}
      fetchModel={fetchEntity}
      icon="building"
      name={model.name}
      editButton={
        <EditEntityModal
          entityId={+entityId}
          fetchEntities={fetchEntity}
          iconTrigger={true}
        />
      }
      info={[
        {
          title: "Alternate Names",
          value: model.alternate_names?.map(an => an.name),
        },
        {
          title: "Addresses",
          value: buildSidebarAddresses(model),
        },
        {
          title: "Websites",
          value: model.urls,
        },
        {
          title: "Email BCC",
          value: CONFIG.CC_EMAIL ? <CopyCCEmail /> : "Not Configured",
        },
        {
          title: "Account Manager",
          value: model.account_managers?.map(ams => ams.user_full_name),
        },
        {
          title: "External CC Emails",
          value: model.external_cc_emails,
        },
        {
          value: model.tags,
          type: "tags",
          model,
          modelType: "Entity",
          tags,
        },
      ]}
      custom={model.custom_fields}
      handleTabChange={handleTabChange}
      entity_id={entityId}
      composeEmailModal={({ trigger }) => (
        <SendEmailModal trigger={trigger} entityId={+entityId} />
      )}
      disableEmail={!model.has_emailable_contacts}
    />
  );

  const renderTabMenu = () => (
    <Menu className="navbar icon-only" pointing secondary>
      {tabs.map(({ name, icon }) => {
        const isActive = activeTab === name;
        return (
          <Menu.Item
            key={name}
            name={name}
            active={isActive}
            onClick={handleTabChange}
          >
            <Icon name={icon} size="large" /> {isActive && startCase(name)}
            {name === "notes" && hasNotes && !isActive && (
              <div className="new-notif-indicator-contact" />
            )}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Container fluid className="route divided">
      {renderSidebar()}
      <div className="main">
        {renderTabMenu()}
        {renderTabView}
        <Divider hidden />
      </div>
    </Container>
  );
};

export default Entity;
