import React, { useState, useEffect, useCallback } from "react";

import { List, Card, Icon, Button, Pagination } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import { createDateTimeFormatter, sortByKey, objToArr } from "../helpers";
import moment from "moment-timezone";

import LogItem from "../../components/LogItem";
import Filter from "../modals/Filter";
import AuditService from "../../services/Audit";
import CampaignService from "../../services/Campaign";
import UserService from "../../services/User";
import CallDispositionService from "../../services/CallDispositions";
import ActivityService from "../../services/Activity";
import useQueryParam from "../../hooks/params/useQueryParam";

import "./../../styles/tab_view.scss";
import "./../../styles/audit_log.scss";

const formatDateForGrouping = createDateTimeFormatter({ format: "YYYYMM" });

const filterOptions = [
  {
    title: "Information Changed",
    type: "checkbox",
    data: [
      { id: "contact_info_changed", name: "Contact Information Changed" },
      { id: "contact_tag_changed", name: "Contact Tag Changed" },
      { id: "entity_info_changed", name: "Entity Information Changed" },
      { id: "entity_tag_changed", name: "Entity Tag Changed" },
    ],
  },
  {
    title: "Emails",
    type: "checkbox",
    data: [
      { id: "email_delivered", name: "Delivered" },
      { id: "email_opened", name: "Opened" },
      { id: "email_clicked", name: "Clicked" },
      { id: "email_errored", name: "Errored" },
      { id: "bcc_email", name: "BCC Email" },
    ],
  },
  {
    title: "Forms",
    type: "checkbox",
    data: [
      { id: "form_submitted", name: "Response Submitted" },
      { id: "form_saved", name: "Form Saved" },
      { id: "form_loaded", name: "Form Loaded" },
    ],
  },
  {
    title: "Cases",
    type: "checkbox",
    data: [
      { id: "case_tag_changed", name: "Case Tag Changed" },
      { id: "case_information_changed", name: "Case Information Changed" },
    ],
  },
  {
    title: "Mails",
    type: "checkbox",
    data: [
      { id: "mail_mailed", name: "Mailed" },
      { id: "mail_delivered", name: "Delivered" },
    ],
  },
  {
    title: "Campaign Status",
    type: "checkbox",
    data: [
      { id: "contact_campaign_status_changed", name: "Contact Status Changed" },
      { id: "entity_campaign_status_changed", name: "Entity Status Changed" },
      { id: "case_campaign_status_changed", name: "Case Status Changed" },
    ],
  },
  {
    title: "Web Tracking",
    type: "checkbox",
    data: [{ id: "site_visits", name: "Site Visits" }],
  },
  {
    title: "Uncategorized",
    type: "checkbox",
    data: [
      { id: "attachment_created", name: "Attachment Created" },
      { id: "attachment_deleted", name: "Attachment Deleted" },
      { id: "note_created", name: "Note Created" },
      { id: "note_modified", name: "Note Modified" },
      { id: "note_deleted", name: "Note Deleted" },
    ],
  },
];

function groupLogByDate(log) {
  const groups = log.reduce((groups, item) => {
    const date = formatDateForGrouping(item.created_at);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});
  return Object.keys(groups).map(date => {
    return {
      date,
      items: groups[date],
    };
  });
}

function formatDateGroup(date) {
  return moment(date, "YYYYMM").format("MMMM YYYY");
}

function AuditLog({ modelId: modelIdStr, modelType, auditLogId, updateFlag }) {
  const modelId = Number(modelIdStr);
  const [log, setLog] = useState([]);
  const [activePage, setActivePage] = useQueryParam("page", 1);
  const [activeItem, setActiveItem] = useState({});
  const [options, setOptions] = useState([]);
  const [checked, setChecked] = useState({});
  const [filters, setFilters] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [dispositions, setDispositions] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const fetchCampaigns = useCallback(async () => {
    const campaigns = await CampaignService.getCampaignsForFilters();
    setCampaigns(campaigns);
  }, []);

  const fetchUsers = useCallback(async () => {
    const users = (await UserService.getUsers()).map(
      ({ id, full_name: name }) => ({ id, name })
    );
    setUsers(users);
  }, []);

  const fetchDispositions = useCallback(async () => {
    let dispositions = await CallDispositionService.getDispositionsForFilters({
      include_callback: true,
    });
    setDispositions(dispositions);
  }, []);

  const fetchCategories = useCallback(async () => {
    const rawCategories = await ActivityService.getCategories();
    const categories = rawCategories.map(category => ({
      key: category.id,
      text: category.name,
      value: category.id,
      name: "category",
      hidden: category.is_hidden,
    }));
    setCategories(categories);
  });

  useEffect(() => {
    (async () => {
      fetchCampaigns();
      fetchUsers();
      fetchDispositions();
      fetchCategories();
    })();
  }, [modelId]);

  useEffect(() => {
    (async () => {
      setOptions([
        ...filterOptions,
        {
          title: "Calls",
          type: "checkbox",
          data: [
            { id: "update", name: "Edited calls" },
            { id: "call_status_verified", name: "Call Status Verified" },
            { id: "call_status_unverified", name: "Call Status Unverified" },
            { id: "not_connected", name: "Not Connected" },
            { id: "callback", name: "Callback" },
            { id: "skipped", name: "Skipped" },
            { id: "voicemail", name: "Voicemail" },
            { id: "connected", name: "Connected" },
            { id: "unknown", name: "Unknown" },
          ],
        },
        { title: "Campaign", data: campaigns },
        { title: "User", data: users },
      ]);
    })();
  }, [modelId, dispositions, users, campaigns]);

  const fetchLog = useCallback(
    async (modelId, modelType) => {
      setLoaded(false);
      const { data, pages } = await AuditService.getAuditLogs({
        modelId,
        modelType,
        page: activePage,
        filters,
      });
      setTotalPages(pages);
      setLog(data);
      setLoaded(true);
    },
    [filters, activePage]
  );

  useEffect(() => {
    (async () => {
      if (!modelId || updateFlag === 0) {
        return;
      }
      await fetchLog(modelId, modelType);
    })();
  }, [modelId, modelType, filters, activePage, updateFlag]);

  useEffect(() => {
    (async () => {
      if (auditLogId) {
        const { item, page } = await AuditService.getAuditLogEntry({
          auditLogId,
          modelId,
          modelType,
          filters,
        });
        setActiveItem(item);
        setActivePage(page);
      }
    })();
  }, [auditLogId, modelId, modelType, filters]);

  function applyFilter(newChecked = null) {
    const chk = newChecked || checked;
    const filters = options.reduce(
      (accum, { title }) => ({ ...accum, [title]: objToArr(chk[title]) }),
      {}
    );
    newChecked && setChecked(newChecked);
    setFilters(filters);
    setActivePage(1);
  }

  function onPageChange(_, { activePage }) {
    setActivePage(activePage);
    setActiveItem({});
  }

  const groupedLog = sortByKey(groupLogByDate(log), "date", true);

  return (
    <List className="auditLog tabView">
      <List.Item className="filterButton">
        <Pagination
          activePage={activePage}
          totalPages={totalPages}
          disabled={totalPages === 0}
          onPageChange={onPageChange}
        />
        Filter
        <Filter
          options={options}
          checked={checked}
          updateChecked={setChecked}
          applyFilter={applyFilter}
          trigger={<Button basic size="tiny" icon="filter" />}
        />
      </List.Item>
      {!loaded || totalPages === 0 ? (
        <List.Item className="no-results">
          {loaded ? (
            <p>
              Nothing to show for this {modelType}.
              {!isEmpty(filters) && " Try adjusting the filters."}
            </p>
          ) : (
            <Icon loading name="circle notched" size="big" />
          )}
        </List.Item>
      ) : (
        groupedLog.map(({ date, items }, index) => (
          <List.Item key={index}>
            <List.Header>{formatDateGroup(date)}</List.Header>
            <List.Content>
              <Card.Group>
                {items.map((item, index) => (
                  <LogItem
                    key={index}
                    item={item}
                    modelType={modelType}
                    modelId={modelId}
                    categories={categories}
                    activeItem={
                      !isEmpty(activeItem) && activeItem.id === item.id
                    }
                    dispositions={dispositions}
                    refreshLog={async () => await fetchLog(modelId, modelType)}
                  />
                ))}
              </Card.Group>
            </List.Content>
          </List.Item>
        ))
      )}
    </List>
  );
}

export default AuditLog;
