import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Message, Modal, Segment } from "semantic-ui-react";

import "../styles/forms.scss";

class RuvixxForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    ready: PropTypes.bool,
    successHeader: PropTypes.string,
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    errorTitle: PropTypes.string,
    submitButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    submitButtonClassName: PropTypes.string,
    cancelButtonClassName: PropTypes.string,
    success: PropTypes.bool,
    error: PropTypes.bool,
    actionBarPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    confirmSubmit: PropTypes.bool,
    confirmText: PropTypes.string,
    ignoreEnterKey: PropTypes.bool,
  };

  static defaultProps = {
    ready: true,
    actionBarPadding: 0,
    errorTitle: "",
    submitButtonText: "SUBMIT",
    cancelButtonText: "CANCEL",
    submitButtonClassName: "long",
    cancelButtonClassName: "long",
    secondButton: null,
    extraButtons: null,
    confirmSubmit: false,
    confirmText: "Do you want to submit this form?",
    ignoreEnterKey: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorMessage: "",
      confirmModal: false,
      submitted: false,
      loading: false,
    };
  }

  componentDidUpdate() {
    if (this.state.submitted) {
      if (this.props.errorMessage !== this.state.errorMessage) {
        this.setState({
          errorMessage: this.props.errorMessage,
        });
      }
      if (this.props.successMessage !== this.state.successMessage) {
        this.setState({
          successMessage: this.props.successMessage,
        });
      }
      if (this.props.successHeader !== this.state.successHeader) {
        this.setState({
          successHeader: this.props.successHeader,
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.resubmitTimer) {
      clearTimeout(this.resubmitTimer);
      this.resubmitTimer = 0;
    }
  }

  handleIgnoreEnter = e => {
    if (!this.props.ignoreEnterKey) {
      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  handleDismiss = messageType => {
    this.setState({
      [`${messageType}Message`]: null,
      [`${messageType}Header`]: null,
    });
  };

  handleConfirm = e => {
    this.setState({ confirmModal: false });
    this.handleSubmit(e);
  };

  handleCancel = () => {
    this.setState({ confirmModal: false });
  };

  handleSubmit = async (e, _, submitFn = this.props.onSubmit) => {
    e.preventDefault();
    if (this.state.submitted) {
      return;
    }
    this.setState({
      success: false,
      error: false,
      errorData: null,
      errorMessage: "",
      submitted: true,
      loading: true,
    });
    try {
      if (submitFn) {
        await submitFn(e); // maybe-promise
      }
      this.setState({
        success: true,
      });
      this.resubmitTimer = setTimeout(() => {
        this.setState({
          submitted: false,
        });
      }, 1000); // allow resubmitting one second after last submission
      if (this.props.onSuccess) {
        await this.props.onSuccess(e); // maybe-promise
      }
    } catch (error) {
      const errorData =
        (error.response &&
          error.response.data &&
          error.response.data.error_data) ||
        {};

      this.setState({
        error: true,
        errorMessage: error.message,
        errorData,
        submitted: false,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  getMessageWithLink = (message, errorData) => {
    const getErrorMessage = () => {
      const { contact_id, entity_id, full_name, email, path, id, class_name } =
        errorData;
      let errorMessage = "";

      if (entity_id && contact_id && full_name) {
        errorMessage = (
          <p>
            A{" "}
            <Link target="_blank" to={`/contacts/${contact_id}`}>
              Contact{" "}
            </Link>
            already exists on{" "}
            <Link target="_blank" to={`/entities/${entity_id}`}>
              Entity{" "}
            </Link>
            with name {full_name}
          </p>
        );
      } else if (email && contact_id) {
        errorMessage = (
          <p>
            A{" "}
            <Link target="_blank" to={`/contacts/${contact_id}`}>
              Contact{" "}
            </Link>
            already exists with email {email}
          </p>
        );
      } else if (message && path && id && class_name) {
        errorMessage = (
          <p>
            {message} Go to existing{" "}
            <Link target="_blank" to={`${path}${id}`}>
              {class_name || "record"} #{id}
            </Link>
          </p>
        );
      } else if (entity_id) {
        errorMessage = (
          <p>
            {message}
            <Link target="_blank" to={`/entities/${entity_id}`}>
              View it here.
            </Link>
          </p>
        );
      } else {
        errorMessage = message;
      }

      return errorMessage;
    };

    return (
      <div>
        <p>{getErrorMessage()}</p>
      </div>
    );
  };

  render() {
    const {
      success,
      error,
      children,
      errorTitle,
      actionBarPadding,
      cancelButtonText,
      cancelButtonClassName,
      onCancel,
      onDelete,
      onSubmit,
      onSuccess,
      ready,
      submitButtonText,
      submitButtonClassName,
      secondButton,
      extraButtons,
      confirmSubmit,
      confirmText,
      ...formProps
    } = this.props;
    const { successHeader, successMessage, errorMessage } = this.state;
    const { errorData } = this.state;
    return (
      <Form
        success={this.state.success && success}
        error={this.state.error || error}
        {...formProps}
        onKeyDown={this.handleIgnoreEnter}
      >
        <Segment basic style={{ padding: 0 }}>
          {children}
          {errorMessage && (
            <Message
              error
              header={`Action Failed${errorTitle ? ": " + errorTitle : ""}`}
              onDismiss={() => this.handleDismiss("error")}
              content={
                errorData && errorData.hasOwnProperty("custom_error")
                  ? this.getMessageWithLink(errorMessage, errorData)
                  : errorMessage
              }
            />
          )}
          {(successMessage || successHeader) && (
            <Message
              success
              onDismiss={() => this.handleDismiss("success")}
              header={successHeader || ""}
              content={successMessage || ""}
            />
          )}
        </Segment>
        <Segment
          basic
          clearing
          style={{ padding: actionBarPadding }}
          className="actionBar"
        >
          <Form.Group inline>
            {extraButtons &&
              extraButtons.map(btn => <Form.Field>{btn}</Form.Field>)}
            {onCancel && (
              <Form.Field>
                <Button
                  content={cancelButtonText}
                  onClick={onCancel}
                  size="tiny"
                  className={cancelButtonClassName}
                />
              </Form.Field>
            )}
            {onDelete && (
              <Form.Button
                content="DELETE"
                color="red"
                onClick={onDelete}
                size="tiny"
              />
            )}
            {(onSubmit || onSuccess) && (
              <Form.Field>
                <Button
                  content={submitButtonText}
                  primary
                  disabled={!ready || this.state.loading}
                  loading={this.state.loading}
                  size="tiny"
                  className={submitButtonClassName}
                  onClick={
                    confirmSubmit
                      ? () => {
                          this.setState({ confirmModal: true });
                        }
                      : this.handleSubmit
                  }
                />
                {secondButton && (
                  <Button
                    content={secondButton.content}
                    size={secondButton.size}
                    color={secondButton.color}
                    disabled={secondButton.disabled}
                    onClick={e =>
                      this.handleSubmit(
                        e,
                        null,
                        this.props.secondButton.onSubmit
                      )
                    }
                  ></Button>
                )}
              </Form.Field>
            )}
          </Form.Group>
        </Segment>
        <Modal open={this.state.confirmModal} onClose={this.handleCancel}>
          <Modal.Content>{confirmText}</Modal.Content>
          <Modal.Actions>
            <Button
              className="cancel"
              content="No"
              onClick={this.handleCancel}
            />
            <Button
              className="confirm"
              content="Yes"
              onClick={this.handleConfirm}
            />
          </Modal.Actions>
        </Modal>
      </Form>
    );
  }
}

export default RuvixxForm;
